/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import "./Layout.scss";
import Header from "../header/Header.jsx";
import HeaderPublic from "../header/HeaderPublic";
import Sidebar from "../sidebar/Sidebar.jsx";
import Footer from "../footer/Footer.jsx";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { userType } from "../../configs/utility";
import ContactUsPopUp from "../contact-us-popup/Contact"

function Layout({ children }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showPublicHeader, setShowPublicHeader] = useState(true);
  const [isSticky, setSticky] = useState(false);
  const [visibility, setVisibility] = useState(false);


  const globalContext = useContext(GlobalContext);
  const location = useLocation();
  const ref = useRef();
  const _userType =
    globalContext.user && userType(globalContext.user.User.Role);

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top < 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  useEffect(() => {
    if (["/", "/about-us"].includes(location.pathname)) {
      setShowPublicHeader(true);
    } else {
      setShowPublicHeader(false);
    }
  }, [location.pathname]);

  const setShowSidebarAction = () => {
    setShowSidebar(!showSidebar);
  };

  const scrollUp = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const popupCloseHandler = () => {
    setVisibility(false);
  };

  return (
    <div ref={ref}>
      {showPublicHeader ? (
        <HeaderPublic
          isSticky={isSticky}
          isLogin={globalContext.user}
          userType={_userType}
        />
      ) : (
        <Header
          isSticky={isSticky}
          setShowSidebarAction={setShowSidebarAction}
          isLogin={globalContext.user}
          userType={_userType}
          globalContext={globalContext}
          showSidebar={showSidebar}
        />
      )}
       
      <div
        className={
          showPublicHeader
            ? "grey-bg loaded"
            : "section_dashboard grey-bg loaded"
        }
      >
         <ContactUsPopUp
        onClose={popupCloseHandler}
        show={visibility}
      >
      </ContactUsPopUp>
        {globalContext.user && (
          <Sidebar
            showSidebar={showSidebar}
            setShowSidebarAction={setShowSidebarAction}
          />
        )}
        {children}
      </div>
      <Footer globalContext={globalContext} showContactUs={setVisibility} />
      <div
        className={`scrollUp ${!isSticky && "display-none"}`}
        onClick={() => scrollUp()}
      >
        <i className="fa fa-angle-up fa-angle-up-icon" />
      </div>
    </div>
  );
}

export default Layout;
