/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  API,
  AXIOS_METHOD_TYPES,
  CAMPAIGN_STATUS,
} from "../../../configs/constants";
import { v4 as uuidv4 } from "uuid";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "../../../context/GlobalContext";
import Uploader from "../../../components/fileUploader/FileUploader";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AdminCreateCampaign.scss";
import Meta from "../../common/meta/Meta";
import moment from "moment";

const AdminCreateCampaign = (props) => {

  const globalContext = useContext(GlobalContext);
  const [requesters, setRequesters] = useState([]);
  const [resource, setResource] = useState(null);
  const [errorR, setErrorR] = useState(false);
  const [resources, setResources] = useState([]);
  const [errorResources, setErrorResources] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [campaignStatus, setCampaignStatus] = useState([]);
  const [images, setImages] = useState([]);
  const [pdf, setPdf] = useState([]);
  const [fileTitle, setFileTitle] = useState("");
  const resourceName = useRef(null);
  const resourceDescription = useRef(null);
  const resourceQuantity = useRef(null);
  // const resourceType = useState(null);
  const [resourceType, setResourceType] = useState('select');
  let history = useHistory();
  const [hasError, setHasError] = useState(false);
 
  useEffect(() => {
    if(globalContext.user.User.Role === 2 ){
      init();
    }
  }, []);

  useEffect(() => {
    if (resources.length === 0) setErrorResources(true);
    else setErrorResources(false);
  }, [resources]);

  const init = () => {
    callRequesterAPI();
    let arr = [...CAMPAIGN_STATUS];
    arr.pop();
    setCampaignStatus(arr);
  };

  const callRequesterAPI = () => {
    let serviceParams = {
      api: API.REQUESTERS,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          setRequesters(result.Data.requesters);
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result ? result.Status.Message : "Data not found",
          });
        }
      }
    });
  };

  const uploadCampaignImage = (params) => {
    globalContext.setLoading(true);
    const formData = new FormData();
    images.forEach((file) => {
      formData.append(file.name, file);
    });
    pdf.forEach((file) => {
      formData.append(file.name, file);
    });
    if (images.length === 0) {
      callSaveCampaignAPI(params);
    } else {
      let serviceParams = {
        api: API.FILE_UPLOAD,
        method: AXIOS_METHOD_TYPES.POST,
        params: formData,
      };
      globalContext.setLoading(true);
      props.commonAPIRequest(serviceParams, (result) => {
        if (result && result.Status.StatusId === 1) {
          delete params["Image"];
          params["ImageLink"] = result.Data[0].FileUrl;
          if (result.Data[1]) {
            params["OtherFiles"] = [{
              Title: fileTitle,
              FilePath: result.Data[1].FileUrl,
            },
            ];
          }
          callSaveCampaignAPI(params);
        } else {
          globalContext.setLoading(false);
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result ? result.Status.Message : "Data not found",
          });
        }
      });
    }
  };

  const callSaveCampaignAPI = (params) => {
    let serviceParams = {
      api: API.SAVE_CAMPAIGN,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        globalContext.setNotifications({
          type: "success",
          text: result
            ? result.Status.Message
            : "Campaign Created Successfully",
        });
        history.goBack();
      } else {
        globalContext.setNotifications({
          id: Math.random(),
          type: "error",
          text: result ? result.Status.Message : "Error while saving campaign",
        });
      }
    });
  };

  const handleChangeResource = (e) => {
    e.preventDefault();
    switch (e.target.name) {
      case "resourceQuantity":
        if (parseInt(e.target.value) <= 0) {
          globalContext.setNotifications({
            type: "warning",
            text: "Resource Quantity Should Greater Than 1",
          });
        } else {
          setResource({ ...resource, Quantity: e.target.value });
        }
        break;
      case "resourceType":
        setResource({ ...resource, IsCash: e.target.value });
        setResourceType(e.target.value);
        break;
      case "resourceName":
        setResource({ ...resource, Name: e.target.value });
        break;
      case "resourceDescription":
        setResource({ ...resource, Description: e.target.value });
        break;
      default:
    }
    setErrorR(false);
  };

  const addResource = () => {
    if (
      resourceName.current.value === '' ||
      resourceDescription.current.value === '' ||
      resourceQuantity.current.value <= 0 
      // || resourceType === 'select'
    ) {
      setErrorR("Missing resource data");
    } else {
      let _resource = { ...resource, Id: uuidv4(), IsCash: true };
      const _resources = [...resources, _resource];
      setResources(_resources);
      resourceName.current.value = "";
      resourceDescription.current.value = "";
      resourceQuantity.current.value = "";
      setResourceType('select');
    }
  };

  const deleteResource = (id) => {
    let _resources = [...resources];
    for (let i = 0; _resources.length; i++) {
      if (_resources[i].Id === id) {
        _resources.splice(i, 1);
        break;
      }
    }
    setResources(_resources);
  };

  const validation = (values) => {
    let _values = { ...values };
    if (errorResources) return false;
    else {
      _values["RequesterId"] = parseInt(_values["RequesterId"]);
      _values["Status"] = parseInt(_values["Status"]);
      _values["EndDate"] = convertToISODateTimeFormat(_values["EndDate"]);
      _values["Resources"] = removeResourcesId([...resources]);
      return _values;
    }
  };

  const removeResourcesId = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      delete arr[i].Id;
    }
    return arr;
  };

  const convertToISODateTimeFormat = (dateString) => {
    const d = new Date(dateString);
    return d.toISOString();
  };

  const editResourceRow = (resourceId) => {
    let _resources = JSON.parse(JSON.stringify(resources));
    _resources.map((item, index) => {
      if (item.Id === resourceId) {
        item.isEditable = true;
      } else {
        item.isEditable = false;
      }
      return null;
    });

    setResources(_resources);
  }

  const saveEditedResource = (index) => {
    let _resources = JSON.parse(JSON.stringify(resources));
    _resources[index].isEditable = false;
    setResources(_resources);
  }

  const handleResourceInputChange = (index, fieldName, newValue) => {
    let _resources = JSON.parse(JSON.stringify(resources));
    _resources[index][fieldName] = newValue;
    setResources(_resources);
  }

  const onCrossBtnTap = () => {
    setImages([])
  }
  const onFileCrossBtnTap = () => {
    setPdf([])
  }

  return (
    <div className="container">
      <Meta title={"Admin-Create-Campaign"} content={"Admin campaign create"} />
     
      { globalContext.user.User.Role === 2 ? <div className="row">
        <div className="col-12">
          <div className="form-div">
          <div className="d-flex justify-content-between mb-5 ">
              <h3 className="dash-heading my-4">Add new campaign</h3>
              <div className="row d-flex align-items-center mr-3" style={{alignSelf:'center'}}>
                
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <a
                      className="btn site-btn text-nowrap sm-mt-4 text-center"
                      onClick={ () => history.push( "/admin-create-requester" ) }
                      style={ { color : "#fff" } }
                  >
                     Add a Recipient
                  </a>
                </div>
              </div>
            </div>
            <Formik
              initialValues={{
                RequesterId: "",
                Name: "",
                Story: "",
                VideoLink: "",
                Image: null,
                Status: "",
                EndDate: "",
                IsFeatured: false,
                IsPrivacy:false,
                IsVideoAsCampaignImage:false
              }}
              validationSchema={Yup.object().shape({
                RequesterId: Yup.number().required("Required"),
                Name: Yup.string().required("Required"),
                Story: Yup.string().max(750).required("Required"),
                VideoLink: Yup.string(),
                Image: Yup.mixed(),
                Status: Yup.number().required("Required"),
                EndDate: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                const _params = validation(values);
                if (_params !== false) {
                  setIsSubmitButtonDisabled(true)
                  uploadCampaignImage(_params)
                };
                setSubmitting(true);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue,
                handleSubmit
              }) => (
                <Form>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-20">
                      <select
                        className="from-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="RequesterId"
                        name="RequesterId"
                      >
                        <option value={""}>Requester</option>
                        {requesters.map((item, index) => (
                          <option value={item.Id} key={index}>
                            {item.FirstName} {item.LastName}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.RequesterId &&
                        touched.RequesterId &&
                        errors.RequesterId}</span>
                    </div>

                    <div className="col-12 col-md-6 mb-20">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Name}
                        className="from-control"
                        type="text"
                        id="Name"
                        name="Name"
                        placeholder="Campaign Name"
                        required=""
                      />
                      <span className="text-danger">{errors.Name && touched.Name && errors.Name}</span>
                    </div>
                    <div className="col-12 col-lg-12 mb-20">
                      <textarea
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Story}
                        className="from-control"
                        rows="4"
                        id="Story"
                        name="Story"
                        placeholder="Campaign Story"
                        maxlength="751"
                      />
                      <span className="text-danger">
                        {errors.Story && touched.Story && errors.Story}
                      </span>
                    </div>
                    <div className="col-12 col-md-6 mb-20">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.VideoLink}
                        className="from-control"
                        type="text"
                        id="VideoLink"
                        name="VideoLink"
                        placeholder="video (Vimeo Id)"
                        required=""
                      />
                      <span className="text-danger">{errors.VideoLink &&
                        touched.VideoLink &&
                        errors.VideoLink}</span>
                      <div className="input-group mb-2 mt-2" >
                        <label className="check-style">
                          Video As Campaign Image
                          <input
                            type="checkbox"
                            //   onBlur={handleBlur}
                            onChange={(e) => {
                              // debugger
                              setFieldValue(
                                "IsVideoAsCampaignImage",
                                e.currentTarget.checked
                              );
                            }}
                            id="IsVideoAsCampaignImage"
                            name="IsVideoAsCampaignImage"
                            checked={values.IsVideoAsCampaignImage}
                          />
                          <span className="checkmark"></span>
                        </label>
                          </div>
                    </div>
                    
                    <div className="col-12 col-md-6">
                      <select
                        className="from-control"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="Status"
                        name="Status"
                      >
                        <option value={""}>Campaign Status</option>
                        {campaignStatus.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.text}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.Status && touched.Status && errors.Status}</span>
                    </div>
                    <div className="col-12 col-md-6 mb-20 sm-mt-15">
                      <Uploader className="drop"
                        setFiles={setPdf}
                        placeholder={"Upload file"}
                        type=".pdf ,image/jpeg, image/png"
                        icon={<i className="fa fa-upload mr-2" />}
                      />
                      {pdf ? (
                        pdf.map((file, index) => (
                          <div>
                            <li key={index} style={{ float: 'left' }}>{file.name}</li>
                            <img
                              className="normal-logo"
                              src="/assets/images/croosbtn.jpeg"
                              alt=""
                              onClick={onFileCrossBtnTap}
                              style={{ width: 20, marginLeft: 10 }}
                            />
                          </div>
                        ))
                      ) : (
                        <p>Select File</p>
                      )}
                    </div>
                    <div className="col-12 col-md-6 mb-30">
                      <input
                        onChange={(e) => setFileTitle(e.target.value)}
                        onBlur={handleBlur}
                        value={fileTitle}
                        className="from-control"
                        type="text"
                        id="FileTitle"
                        name="FileTitle"
                        placeholder="File Title"
                        required=""
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <Uploader
                        setFiles={setImages}
                        placeholder={"Upload campaign image"}
                        type="image/jpeg, image/png"
                        icon={<i className="fa fa-upload mr-2" />}
                      />
                      {images ? (
                        images.map((file, index) => (
                          <div>
                            <li key={index} style={{ float: 'left' }}>{file.name}</li>
                            <img
                              className="normal-logo"
                              src="/assets/images/croosbtn.jpeg"
                              alt=""
                              onClick={onCrossBtnTap}
                              style={{ width: 20, marginLeft: 10 }}
                            />
                          </div>
                        ))
                      ) : (
                        <p>Select File</p>
                      )}
                      <div className="input-group mb-2" >
                        <label className="check-style">
                          Privacy
                          <input
                            type="checkbox"
                            //   onBlur={handleBlur}
                            onChange={handleChange}
                            id="IsPrivacy"
                            name="IsPrivacy"
                            checked={values.IsPrivacy}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-30 sm-mt-15">
                      <DatePicker
                        value={values.EndDate}
                        onChange={(e) => {
                          setFieldValue(
                            "EndDate",
                            moment(e).format("MM/DD/YYYY"),
                            handleChange(moment(e).format("MM/DD/YYYY"))
                          );
                        }}
                        onBlur={handleBlur}
                        placeholderText="End date"
                        className="from-control"
                        id="EndDate"
                        name="EndDate"
                      />
                      <span className="text-danger">{errors.EndDate && touched.EndDate && errors.EndDate}</span>
                    </div>
                  </div>
                  <div className="row mt-20">
                    <div className="col-12 col-md-12 mb-20">
                      <div className="mb-4">
                        <div className="row">
                          <div className="col-md-3 my-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Resources Name"
                              name="resourceName"
                              ref={resourceName}
                              onChange={handleChangeResource}
                              defaultValue={resource ? resource.Name : ""}
                            />
                          </div>
                          <div className="col-md-2 my-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Description"
                              name="resourceDescription"
                              ref={resourceDescription}
                              onChange={handleChangeResource}
                              defaultValue={
                                resource ? resource.Description : ""
                              }
                            />
                          </div>
                          {/* <div className="col-md-2 my-2">
                            <select className="form-control" name="resourceType" value={resourceType} onChange={handleChangeResource}>
                              <option selected value={'select'}>Resource Type</option>
                              <option value={'true'}>Cash</option>
                              <option value={'false'}>Non cash</option>
                            </select>
                          </div> */}
                          <div className="col-md-2 my-2">
                            <input
                              type="number"
                              min="1"
                              className="form-control"
                              placeholder="Amount"
                              name="resourceQuantity"
                              ref={resourceQuantity}
                              onChange={handleChangeResource}
                              defaultValue={resource ? resource.Quantity : ""}
                            />
                          </div>
                          <div className="col-md-2 my-2">
                            <div
                              className="btn table-sitebtn no-wrap"
                              onClick={() => addResource()}
                            >
                              Add Resource
                                </div>
                          </div>
                        </div>
                        <span className="text-danger">{errorR && errorR}</span>
                      </div>
                      <div className="client-table">
                        <div className="table-responsive">
                          <table className="table custom-table">
                            <thead>
                              <tr>
                                <th>Amount</th>
                                <th>Resource Name</th>
                                <th>Description</th>
                                {/* <th>Type</th> */}
                                <th>Delete</th>
                                <th>Edit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {resources && resources.length > 0 ? (
                                resources.map((resource, index) => (
                                  <tr key={resource.Id}>
                                    <td>
                                      {resource.isEditable === true ? <div class='d-flex align-items-center'>
                                        <input
                                          onChange={(e) => { handleResourceInputChange(index, 'Quantity', e.target.value) }}
                                          className="from-control"
                                          type="text"
                                          value={resource.Quantity}
                                          placeholder="Enter here"
                                          required=""
                                        />
                                      </div> : <p>{resource.IsCash.toString() === 'true' && '$'}{resource.Quantity}</p>}
                                    </td>

                                    <td>
                                      {resource.isEditable === true ? <div class='d-flex align-items-center'>
                                        <input
                                          onChange={(e) => { handleResourceInputChange(index, 'Name', e.target.value) }}
                                          className="from-control "
                                          type="text"
                                          value={resource.Name}
                                          placeholder="Enter here"
                                          required=""
                                        />
                                      </div> : <p>{resource.Name}</p>}
                                    </td>



                                    <td>
                                      {resource.isEditable === true ? <div class='d-flex align-items-center'>
                                        <input
                                          onChange={(e) => { handleResourceInputChange(index, 'Description', e.target.value) }}
                                          className="from-control "
                                          type="text"
                                          value={resource.Description}
                                          placeholder="Enter here"
                                          required=""
                                        />
                                      </div> : <p>{resource.Description}</p>}
                                    </td>


                                    {/* <td>
                                      {resource.isEditable === true ? <div class='d-flex align-items-center'>
                                        <select className="form-control" name="resourceType"
                                          value={resource.IsCash.toString()} onChange={(e) => { handleResourceInputChange(index, 'IsCash', e.target.value) }}>
                                          <option value={'select'} disabled selected>Resource Type</option>
                                          <option value={'true'}>Cash</option>
                                          <option value={'false'}>Non cash</option>
                                        </select>
                                      </div> : <p>{resource.IsCash.toString() === 'true' ? 'Cash' : 'Non cash'}</p>}
                                    </td> */}


                                    <td>
                                      <div
                                        className="btn table-sitebtn"
                                        onClick={() =>
                                          deleteResource(resource.Id)
                                        }
                                      >
                                        Delete
                                            </div>
                                    </td>
                                    <td>
                                      <div
                                        className="btn table-sitebtn"
                                        onClick={() => {
                                          resource.isEditable ? saveEditedResource(index) : editResourceRow(resource.Id)
                                        }
                                        }
                                      >
                                        {resource.isEditable ? 'Save' : 'Edit'}
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td />
                                  <td>Resource not found</td>
                                  <td />
                                  <td />
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {errorResources}
                    </div>
                    <div className="col-12 col-md-6 mb-20">
                      <label className="check-style">
                        Feature Campaigns
                            <input
                          type="checkbox"
                          onBlur={handleBlur}
                          checked={values.IsFeatured}
                          onChange={handleChange}
                          id="IsFeatured"
                          name="IsFeatured"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="col-12 col-lg-12 mb-20">
                    {(hasError && !!Object.keys(errors).length)  &&
                        <div style={{ color: 'red' }}>Unable to submit. Please check the errors on the form above.</div>}
                      <button
                        className="btn-send max-w-200"
                        onClick={() => {
                            setHasError(errors.RequesterId || errors.Name || errors.Story || errors.Status || errors.EndDate);
                          if (resources.length === 0) {
                            setErrorR('Please add at least one resource')
                          } else {
                            setErrorR('')
                          }
                          handleSubmit();
                        }}
                        disabled={isSubmitButtonDisabled}
                      >Submit</button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
    </div>
  );
}

export default withAPIRequest(AdminCreateCampaign);