/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React , { useContext , useEffect , useState } from "react";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import Vimeo from "@u-wave/react-vimeo";
import "./CampaignDetails.scss";
import { GlobalContext } from "../../../context/GlobalContext";
import { API , AXIOS_METHOD_TYPES , RESOURCE_STATUS, TIPS } from "../../../configs/constants";
import { useParams } from "react-router";
import { FacebookShareButton , TwitterShareButton } from 'react-share';
import { getCampaignShareableURL,getLetterInitials,getRecipientName } from '../../../configs/utility';
import Meta from "../../common/meta/Meta";
import moment from 'moment';

const CampaignDetails = (props) => {

  const globalContext = useContext(GlobalContext);
  const [campaignData, setCampaignData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    getCampaignDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCampaignDetails = () => {
    let serviceParams = {
      api: `${API.PUBLIC_CAMPAIGN_DETAILS}${id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        setCampaignData(result.Data);
        return;
      }
      globalContext.setNotifications({
        type: "error",
        text: result ? result.Status.Message : "No data found",
      });
    });
  };

  return (
    <div className="container campaign-details-container">
      <Meta title={ "Campaign-details" } content={ "Campaign Detail" }/>
      {campaignData && <div className="row">
        <div className="col-12">

          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">EveryHumanNeeds Use Tips</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div dangerouslySetInnerHTML={{ __html: TIPS }}></div>
                </div>

              </div>
            </div>
          </div>
          <div className="form-div details-page-holder">
            <div className="row mb-5">

            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-3 mb-lg-0">
                <h3 className="dash-heading mb-0">{campaignData.Name} Details</h3>
              </div>

              <div className={"col-sm-12 col-md-7 col-lg-7 col-xl-7"}>
                <div className={"row ml-5"}>
                  {/* Campaign Status */}
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex  p-0 mb-4 mb-lg-0 p-0">
                    <div className='row d-flex align-items-center'>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="mr-5 no-wrap m-0"> <strong>Campaign Status:</strong> </p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0 text-right">
                        <label className={`m-0 chips-box-btn no-wrap ${campaignData && campaignData.Status === 2 ? "grn-bg" : ""}`} > {campaignData && campaignData.StatusName} </label>
                      </div>
                    </div>
                  </div>

                  {/* Campaign End Date */}
                  <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex mb-3 mb-lg-0 p-0">
                    <div className='row d-flex align-items-center'>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="mr-2 no-wrap m-0"> <strong>End date:</strong> </p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="day-text font-weight-normal m-0"> {moment(new Date(campaignData.EndDate)).format('MM/DD/YYYY')}</p>
                      </div>
                    </div>
                  </div>

                  {/* Tips */}
                  {/* <div class='col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex mb-3 mb-lg-0 p-0'>
                    <div className='row d-flex align-items-center'>
                      <p className="m-0 mr-2"><b>Tips:</b></p>
                      <button type="button" class="btn table-sitebtn no-wrap tips-btn " data-toggle="modal" data-target="#exampleModal"> <i className="fa fa-info" /> </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-3 col-md-4 text-center">
                <div className="details-img-holder">
                <div className="details-img d-flex flex-column d-md-block">
                {campaignData && campaignData.IsPrivacy ?
                  <div className="featured-image campaign-image-text">
                        <h2 class="title black-color">{getLetterInitials(campaignData.RequesterName)}</h2>
                  </div>
                  :
                   campaignData && campaignData.IsVideoAsCampaignImage ?
                        campaignData && parseInt(campaignData.VideoLink) 
                        ? (
                          <Vimeo video={campaignData.VideoLink} width="285" />
                        ) : <img
                          className="user img-profile"
                          src={
                            campaignData && campaignData.ImageLink
                              ? campaignData.ImageLink
                              : "assets/images/logo.png"
                          }
                          alt=""
                        />
                  :
                    <img
                      className="user img-profile"
                      src={
                        campaignData && campaignData.ImageLink
                        ? campaignData.ImageLink
                        : "assets/images/logo.png"
                      }
                      alt=""
                    />
                }
                    <div className="mb-20 mt-10 ">
                      <div className={"row ml-5"}>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 px-0 d-flex align-items-center">
                          <p className="mb-2 mr-4">
                            <strong> Share Story </strong>
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 px-0 d-flex align-items-center mr-3">
                          <ul className="footer_social">
                            <li>
                              <FacebookShareButton url={getCampaignShareableURL(campaignData.Id)}
                                children={<i className="fa fa-facebook" />} />
                            </li>
                            <li>
                              <TwitterShareButton url={getCampaignShareableURL(campaignData.Id)}
                                children={<i className="fa fa-twitter" />} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-9 col-md-8 details-holder">
                <div className="row">
                <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap mb-3">
                      <p className="mb-2 mr-4">
                        <h4 className="mt-4 mb-3">Recipient:</h4>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap">
                         <li className={campaignData && getRecipientName(campaignData.RequesterName,campaignData.RequesterUserName,campaignData.IsPrivacy,campaignData.IsRecipientAnonymous)[1] ? "initial-text" : ""}>
                            {campaignData&&getRecipientName(campaignData.RequesterName,campaignData.RequesterUserName,campaignData.IsPrivacy,campaignData.IsRecipientAnonymous)[0]}
                          </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap mb-3">
                      <p className="mb-2 mr-4">
                        <h4 className="mt-4 mb-3">Campaign Story:</h4>
                      </p>
                      <ul className="dash-check-list resources-list story-text">
                        <li>
                          { campaignData?.Story }
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="video-hloder">
                      {campaignData && parseInt(campaignData.VideoLink) && !campaignData.IsVideoAsCampaignImage ? (
                        <Vimeo video={campaignData.VideoLink} width="450" />
                      ) : null}
                      </div>
                    </div>
                  <div className="col-12 col-md-12 col-lg-10 col-xl-10 mb-3">
                    <hr/>
                    <h4 className="mt-4 mb-3"> Resources Needed </h4>
                    <div className="client-details-table">
                      <div className="table-responsive">
                        <table className="table custom-table">
                          <thead>
                            <tr>
                              <th>Resource Name</th>
                              <th>Resource Details </th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaignData && campaignData.Resources ? (
                              campaignData.Resources.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <p>{item.Name}</p>
                                  </td>
                                  <td>
                                    <p>{item.Description}</p>
                                  </td>
                                  <td>
                                    <p className="chips-box mb-2 text-center">
                                      { RESOURCE_STATUS.find( ( status ) => status.id === item.Status ).text || 'N/A' }
                                    </p>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td/>
                                <td>No Resources Found</td>
                                <td/>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  { campaignData?.OtherFiles?.length > 0 ? <div className="col-12 col-md-7 col-lg-10 col-xl-10 my-4">
                    <hr/>
                    <h4 className="mt-4 mb-3"> Files </h4>
                    { campaignData?.OtherFiles
                        ? campaignData?.OtherFiles.map( ( file , index ) => (
                            <div
                                key={ index }
                                className={
                                  "file-list d-flex align-items-center justify-content-between"
                                }
                            >
                              <p className="m-0">{ file ? file.Title : "" }</p>
                              <a
                                  className="btn site-btn text-white"
                                  href={ file && file.FilePath }
                                  target="_blank"
                                  rel="noreferrer"
                                  download={ file ? file.Title : "" }
                              >
                                View
                              </a>
                            </div>
                        ) )
                        : null }
                  </div> : null }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default withAPIRequest(CampaignDetails);
