import React from "react";
import "./RequesterDashboard.scss";
import Meta from "../../common/meta/Meta";

const RequesterDashboard = ( props ) => {

  return (
    <div className="defult-home">
      <Meta title={ "Requester-Dashboard" } content={ "Requester dashboard" }/>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
            <div className="form-div form-pad ">
              <div className="inner-part text-center">
                <h2 className="title my-5 py-4 my-sm-5  py-sm-5">
                  Every Human Needs
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequesterDashboard;
