import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext'
const axios = require('axios').default;

export function withAPIRequest(Component) {
  const WithAPIRequest = (props) => {
    const globalContext = useContext(GlobalContext);

    const commonAPIRequest = ({ api, method, params }, callback, hideErrorNotification) => {
      // let user = JSON.parse(localStorage.getItem('user'))
      let token = globalContext.user ? globalContext.user.AuthToken : null
      let headers = { Authorization: `Bearer ${token}` }
      let config = {
        method: method,
        url: api,
        headers: headers
      }

      params && (config.data = params)

      axios(config).then((response) => {
        if (response.data.Status.StatusId === 1) {
          callback(response.data)
        } else {
          if (api.includes('GetCampaigns') || api.includes('GetFeaturedCampaigns')) {
            let msg = response.data.Status.Message;
            hideErrorNotification !== true && globalContext.setNotifications({
              type: 'success',
              text: msg === 'No records found' ? 'No campaigns found' : msg
            });
          } else {
            hideErrorNotification !== true && globalContext.setNotifications({
              type: 'error',
              text: response.data.Status.Message
            });
          }
          callback(null);
        }
      }).catch((error) => {
        globalContext.setNotifications({
          type: 'error',
          text: error.toString()
        })
        callback(null);
      })
    }

    return (
      <Component {...props} commonAPIRequest={commonAPIRequest} />
    );
  }

  return WithAPIRequest;
}

