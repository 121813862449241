/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { API, AXIOS_METHOD_TYPES, CAMPAIGN_STATUS, } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import Uploader from "../../../components/fileUploader/FileUploader";
import { GlobalContext } from "../../../context/GlobalContext";
import { v4 as uuidv4 } from "uuid";
import { Form, Formik } from "formik";
import moment from "moment";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import "./AdminEditCampaign.scss";
import "react-datepicker/dist/react-datepicker.css";
import Meta from "../../common/meta/Meta";

const AdminEditCampaign = (props) => {

  const globalContext = useContext(GlobalContext);
  const userImage = useRef();
  const { id } = useParams();
  const [requesters, setRequesters] = useState([]);
  const [resource, setResource] = useState(null);
  const [errorR, setErrorR] = useState(false);
  const [resources, setResources] = useState([]);
  const [resourceType, setResourceType] = useState('select');
  const errorResources = false;
  // eslint-disable-next-line 
  const [files, setFiles] = useState([]);
  const [fileTitle, setFileTitle] = useState("");
  const [formValues, setFormValues] = useState({
    RequesterId: "",
    Name: "",
    Story: "",
    VideoLink: "",
    Image: null,
    Status: "",
    EndDate: "",
    Resources: [],
    IsFeatured: false,
    OtherFiles: []
  });
  let endDate = "";
  const resourceQuantity = useRef();
  const resourceName = useRef();
  const resourceDescription = useRef();

  useEffect(() => {
    if(globalContext.user.User.Role === 2 ){
      (async () => {
        await getCampaignData(id);
      })();
    }
   
  }, []);

  const getCampaignData = async (id) => {
    let serviceParams = {
      api: `${API.CAMPAIGN_DETAILS}${id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };
    await globalContext.setLoading(true);
    await props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        // debugger
        setFormValues({
          RequesterId: result.Data.RequesterId,
          Name: result.Data.Name,
          Story: result.Data.Story,
          VideoLink: result.Data.VideoLink,
          Image: result.Data.ImageLink,
          Status: result.Data.Status,
          EndDate: result.Data.EndDate,
          IsFeatured: result.Data.IsFeatured,
          IsPrivacy: result.Data.IsPrivacy,
          IsVideoAsCampaignImage: result.Data.IsVideoAsCampaignImage,
          OtherFiles: result.Data.OtherFiles ? result.Data.OtherFiles[0] : [],
          Resources: result.Data.Resources ? result.Data.Resources : [],
        });
        setFileTitle(result.Data.OtherFiles && result.Data.OtherFiles.length > 0 ? result.Data.OtherFiles[0].Title : "")
        endDate = result.Data.EndDate;
        document.getElementById("EndDate").value = moment(endDate).format("DD/MM/YYYY");
        document.getElementById("IsFeatured").checked = result.Data.IsFeatured;
        setCurrentRequester(result.Data.RequesterName);
        setResources(result.Data.Resources ? [...result.Data.Resources] : []);
      } else {
        globalContext.setNotifications({
          id: Math.random(),
          type: "error",
          text: result ? result.Status.Message : "Data not found",
        });
      }
    });
  };

  const setCurrentRequester = async (requesterName) => {
    await callRequesterAPI(requesterName);
  };

  const callRequesterAPI = async (requesterName) => {
    let serviceParams = {
      api: API.REQUESTERS,
      method: AXIOS_METHOD_TYPES.GET,
    };
    globalContext.setLoading(true);
    await props.commonAPIRequest(serviceParams, (result) => {
      if (result && result.Status.StatusId === 1) {
        let requesterArray = result.Data.requesters;
        setRequesters([...requesterArray]);
        globalContext.setLoading(false);
      } else {
        globalContext.setNotifications({
          id: Math.random(),
          type: "error",
          text: result ? result.Status.Message : "Data not found",
        });
      }
    });
  };

  const uploadCampaignImage = async (params) => {
    params.Id = id;
    if (typeof params.Image === "string") {
      globalContext.setLoading(true);
      params["ImageLink"] = params.Image;
      delete params["Image"];
      if (params.OtherFiles.length > 0) {
        const formData = new FormData();
        formData.append("OtherFiles", params.OtherFiles[0]);
        formData.append("Directory", "OtherFiles");
        uploadImage(formData, (result) => {
          params["OtherFiles"] = [{
            Title: fileTitle,
            FilePath: result,
          }]
          callSaveCampaignAPI(params);
        });
      } else {
        if (formValues['OtherFiles']?.length > 0) {
          params["OtherFiles"] = [formValues['OtherFiles']];
        }
        callSaveCampaignAPI(params);
      }
    } else {
      globalContext.setLoading(true);
      const imageFormData = new FormData();
      params.Image && imageFormData.append("ImageLink", params.Image);
      if (params.OtherFiles && params.OtherFiles.length > 0) {
        const otherFilesFormData = new FormData();
        otherFilesFormData.append("OtherFiles", params.OtherFiles[0]);
        otherFilesFormData.append("Directory", "OtherFiles");
        uploadImage(otherFilesFormData, (otherFilesUrl) => {
          params["OtherFiles"] = [{
            Title: fileTitle,
            FilePath: otherFilesUrl,
          }]
          if (params.Image) {
            uploadImage(imageFormData, (result) => {
              params["ImageLink"] = result;
              callSaveCampaignAPI(params);
            });
          } else {
            callSaveCampaignAPI(params);
          }
        });
      } else {
        if (params.Image) {
          uploadImage(imageFormData, (result) => {
            params["ImageLink"] = result;
            callSaveCampaignAPI(params);
          });
        } else {
          callSaveCampaignAPI(params);
        }
      }
    }
  };

  const uploadImage = (formData, cb) => {
    let imageUrl = "";
    let serviceParams = {
      api: API.FILE_UPLOAD,
      method: AXIOS_METHOD_TYPES.POST,
      params: formData,
    };
    props.commonAPIRequest(serviceParams, (result) => {
      if (result) {
        if (result && result.Status.StatusId === 1) {
          imageUrl = result.Data[0].FileUrl;
          cb(imageUrl);
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result ? result.Status.Message : "Data not found",
          });
        }
      } else {
        globalContext.setLoading(false);
      }
    });
    return imageUrl;
  }

  const callSaveCampaignAPI = (params) => {
    // debugger
    let serviceParams = {
      api: API.SAVE_CAMPAIGN,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };
    props.commonAPIRequest(serviceParams, (result) => {
      if (result && result.Status.StatusId === 1) {
        globalContext.setLoading(false);
        props.history.push(`/admin-campaign-details/id/${id}`);
        globalContext.setNotifications({
          id: Math.random(),
          type: "success",
          text: result ? result.Status.Message : "Campaign details Edited Successfully",
        });
      } else {
        globalContext.setLoading(false);
        globalContext.setNotifications({
          id: Math.random(),
          type: "error",
          text: result ? result.Status.Message : "Data not found",
        });
      }
    });
  };

  const handleChangeResource = (e) => {
    e.preventDefault();
    switch (e.target.name) {
      case "resourceQuantity":
        setResource({ ...resource, Quantity: e.target.value });
        break;
      case "resourceName":
        setResource({ ...resource, Name: e.target.value });
        break;
      case "resourceDescription":
        setResource({ ...resource, Description: e.target.value });
        break;
      case "resourceType":
        setResource({ ...resource, IsCash: e.target.value });
        setResourceType(e.target.value);
        break;
      default:
    }
    setErrorR(false);
  };

  const addResource = () => {
    if (
      resourceName.current.value === '' ||
      resourceDescription.current.value === '' ||
      resourceQuantity.current.value === '' 
      // || resourceType === 'select'
    ) {
      setErrorR("Missing resource data");
    } else {
      let _resource = { ...resource, Id: uuidv4(), IsCash: true };
      const _resources = [...resources, _resource];
      setResources(_resources);
      resourceQuantity.current.value = "";
      resourceDescription.current.value = "";
      resourceName.current.value = "";
      // setResourceType('select');
    }
  };

  const deleteResource = (id) => {
    let _resources = [...resources];
    for (let i = 0; _resources.length; i++) {
      if (_resources[i].Id === id) {
        _resources.splice(i, 1);
        break;
      }
    }
    setResources(_resources);
  };

  const validation = (values) => {
    let _values = { ...values };
    if (errorResources) return false;
    else {
      _values["RequesterId"] = parseInt(_values["RequesterId"]);
      _values["Status"] = parseInt(_values["Status"]);
      _values["EndDate"] = convertToISODateTimeFormat(_values["EndDate"]);
      _values["Resources"] = removeResourcesId([...resources]);
      return _values;
    }
  };

  const removeResourcesId = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      delete arr[i].Id;
    }
    return arr;
  };

  const convertToISODateTimeFormat = (dateString) => {
    const d = new Date(dateString);
    return d.toISOString();
  };

  const imageHandler = (e) => {
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        userImage.current.src = reader.result;
      },
      false
    );
    if (e) {
      reader.readAsDataURL(e);
    }
  };

  const editResourceRow = (resourceId) => {
    let _resources = JSON.parse(JSON.stringify(resources));
    _resources.map((item, index) => {
      if (item.Id === resourceId) {
        item.isEditable = true;
      } else {
        item.isEditable = false;
      }
      return null;
    });

    setResources(_resources);
  }

  const saveEditedResource = (index) => {
    let _resources = JSON.parse(JSON.stringify(resources));
    _resources[index].isEditable = false;
    setResources(_resources);
  }

  const handleResourceInputChange = (index, fieldName, newValue) => {
    let _resources = JSON.parse(JSON.stringify(resources));
    _resources[index][fieldName] = newValue;
    setResources(_resources);
  }
// debugger
  return (
    <div className="container">
      <Meta title={"Admin-Edit-Campaign"} content={"Admin edit campaign"} />
      { globalContext.user.User.Role === 2 ?  <div className="row">
        <div className="col-12">
          <div className="form-div">
            <div className="d-flex mb-5">
              <h3 className="dash-heading mb-0">Edit campaign</h3>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                RequesterId: formValues.RequesterId,
                Name: formValues.Name,
                Story: formValues.Story,
                VideoLink: formValues.VideoLink,
                Image: formValues.Image,
                Status: formValues.Status,
                EndDate: formValues.EndDate,
                IsFeatured: formValues.IsFeatured,
                IsPrivacy: formValues.IsPrivacy,
                IsVideoAsCampaignImage: formValues.IsVideoAsCampaignImage,
                OtherFiles: files
              }}
              validationSchema={Yup.object().shape({
                RequesterId: Yup.number().required("Required"),
                Name: Yup.string().required("Required"),
                Story: Yup.string().max(750).required("Required"),
                VideoLink: Yup.string(),
                Image: Yup.mixed(),
                Status: Yup.mixed().required("Required"),
                EndDate: Yup.mixed().required("Required"),
                IsPrivacy:Yup.bool(),
                IsVideoAsCampaignImage:Yup.bool()
              })}
              onSubmit={(values, { setSubmitting }) => {
                // debugger
                const _params = validation(values);
                if (_params !== false) uploadCampaignImage(_params);
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit
              }) => (
             
                <Form>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-20">
                      <select
                        className="from-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="RequesterId"
                        name="RequesterId"
                        value={values.RequesterId}
                      >
                        {requesters.map((item, index) => (
                          <option value={item.Id} key={index}>
                            {item.FirstName} {item.LastName}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.RequesterId &&
                        touched.RequesterId &&
                        errors.RequesterId}</span>
                    </div>
                    <div className="col-12 col-md-6 mb-20">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={formValues.Name}
                        className="from-control"
                        type="text"
                        id="Name"
                        name="Name"
                        placeholder="Campaign Name"
                      />
                      <span className="text-danger"> {errors.Name && touched.Name && errors.Name}</span>
                    </div>
                    <div className="col-12 col-lg-12 mb-20">
                      <textarea
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={formValues.Story}
                        className="from-control"
                        rows="4"
                        id="Story"
                        name="Story"
                        placeholder="Campaign Story"
                        maxLength="751"
                      />
                      <span className="text-danger">{errors.Story && touched.Story && errors.Story}</span>
                    </div>
                    <div className="col-12 col-md-6 mb-20">
                        <input
                        onChange={(e) => {
                          setFieldValue(
                            "VideoLink",
                            e.currentTarget.value
                          );
                        }}
                        onBlur={handleBlur}
                        defaultValue={formValues.VideoLink}
                        className="from-control"
                        type="text"
                        id="VideoLink"
                        name="VideoLink"
                        placeholder="video (Vimeo Id)"
                      />
                      
                       <div className="input-group mb-2 mt-2" >
                        <label className="check-style">
                          Video As Campaign Image
                          <input
                            type="checkbox"
                            //   onBlur={handleBlur}
                            onChange={(e) => {
                              // debugger
                              setFieldValue(
                                "IsVideoAsCampaignImage",
                                e.currentTarget.checked
                              );
                            }}
                            id="IsVideoAsCampaignImage"
                            name="IsVideoAsCampaignImage"
                            checked={values.IsVideoAsCampaignImage}
                          />
                          <span className="checkmark"></span>
                        </label>
                          </div>
                    </div>
                    <div className="col-12 col-md-6 mb-20">
                      <select
                        className="from-control"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="Status"
                        name="Status"
                        value={values.Status}
                      >
                        {CAMPAIGN_STATUS.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.text}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.Status && touched.Status && errors.Status}</span>
                    </div>
                    <div className="col-12 col-md-6 mb-20">
                      <Uploader className="drop"
                        isMultiple={true}
                        placeholder={"Upload File"}
                        onChange={(file) => {
                          setFieldValue(
                            "OtherFiles",
                            file
                          );
                        }}
                        type=".pdf ,image/jpeg, image/png"
                        icon={<i className="fa fa-upload mr-2" />}
                      />
                      {values.OtherFiles[0] && values.OtherFiles[0].name}
                    </div>
                    <div className="col-12 col-md-6 mb-30">
                      <input
                        onChange={(e) => setFileTitle(e.target.value)}
                        onBlur={handleBlur}
                        value={fileTitle || ""}
                        className="from-control"
                        type="text"
                        id="FileTitle"
                        name="FileTitle"
                        placeholder="File Title"
                        required=""
                      />
                    </div>
                    <div className="col-12 col-lg-6 mb-20">
                      <div>
                        <img className={"user-profile-img"}
                          src={
                            formValues && formValues.Image
                              ? formValues.Image
                              : "assets/images/logo.png"
                          }
                          alt="user-dp"
                          ref={userImage}
                        />
                      </div>
                      <div className="mb-20 img-upload">
                        <Uploader className="drop"
                          isMultiple={true}
                          placeholder={"Click here to update campaign image"}
                          onChange={(image) => {
                            setFieldValue(
                              "Image",
                              image[0]
                            );
                            imageHandler(image[0]);
                          }}
                          type=",image/jpeg, image/png"
                          icon={<i className="fa fa-upload mr-2" />}
                        />
                        {values.Image ? values.Image.name : ""}
                      </div>
                      <div className="input-group mb-2" >
                        <label className="check-style">
                          Privacy
                          <input
                            type="checkbox"
                            //   onBlur={handleBlur}
                            onChange={handleChange}
                            id="IsPrivacy"
                            name="IsPrivacy"
                            checked={values.IsPrivacy}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-30">
                      <DatePicker
                        value={moment(values.EndDate).format("MM/DD/YYYY")}
                        onChange={(e) => {
                          setFieldValue(
                            "EndDate",
                            moment(e).format("MM/DD/YYYY"),
                            handleChange(moment(e).format("MM/DD/YYYY"))
                          );
                        }}
                        onBlur={handleBlur}
                        placeholderText="End date"
                        className="from-control"
                        id="EndDate"
                        name="EndDate"
                      />
                      <span className="text-danger">{errors.EndDate && touched.EndDate && errors.EndDate}</span>
                    </div>
                  </div>
                  <div className="row mt-20">
                    <div className="col-12 col-md-12 mb-20">
                      <div className="mb-4">
                        <div className="row">
                          <div className="col-md-3 my-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Resource Name"
                              name="resourceName"
                              ref={resourceName}
                              onChange={handleChangeResource}
                              defaultValue={resource ? resource.Name : ""}
                            />
                          </div>
                          <div className="col-md-3 my-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Resource Description"
                              name="resourceDescription"
                              ref={resourceDescription}
                              onChange={handleChangeResource}
                              defaultValue={
                                resource ? resource.Description : ""
                              }
                            />
                          </div>
                          {/* <div className="col-md-2 my-2">
                            <select className="form-control" name="resourceType"
                              value={resourceType} onChange={handleChangeResource}>
                              <option value={'select'} disabled selected>Resource Type</option>
                              <option value={'true'}>Cash</option>
                              <option value={'false'}>Non cash</option>
                            </select>
                          </div> */}
                          <div className="col-md-2 my-2">
                            <input
                              type="number"
                              min="1"
                              className="form-control"
                              placeholder="Amount"
                              name="resourceQuantity"
                              ref={resourceQuantity}
                              onChange={handleChangeResource}
                              defaultValue={resource ? resource.Quantity : ""}
                            />
                          </div>
                         
                          <div className="col-md-2 my-2">
                            <div
                              className="btn table-sitebtn no-wrap"
                              onClick={() => addResource()}
                            >
                              Add Resource
                                </div>
                          </div>
                        </div>
                        <span className="text-danger">{errorR && errorR}</span>
                      </div>
                      <div className="client-table">
                        <div className="table-responsive">
                          <table className="table custom-table">
                            <thead>
                              <tr>
                                <th>Amount</th>
                                <th>Resource Name</th>
                                <th>Description</th>
                                {/* <th>Type</th> */}
                                <th>Delete</th>
                                <th>Edit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {resources && resources.length > 0 ? (
                                resources.map((resource, index) => (
                                  <tr key={resource.Id}>
                                    <td>
                                      {resource.isEditable === true ? <div class='d-flex align-items-center'>
                                        <input
                                          onChange={(e) => { handleResourceInputChange(index, 'Quantity', e.target.value) }}
                                          className="from-control"
                                          type="text"
                                          value={resource.Quantity}
                                          placeholder="Enter here"
                                          required=""
                                        />
                                      </div> : '$'+resource.Quantity } {/* <p>{resource.IsCash.toString() === 'true' && '$'}{resource.Quantity}</p>} */}
                                    </td>

                                    <td>
                                      {resource.isEditable === true ? <div class='d-flex align-items-center'>
                                        <input
                                          onChange={(e) => { handleResourceInputChange(index, 'Name', e.target.value) }}
                                          className="from-control "
                                          type="text"
                                          value={resource.Name}
                                          placeholder="Enter here"
                                          required=""
                                        />
                                      </div> : <p>{resource.Name}</p>}
                                    </td>



                                    <td>
                                      {resource.isEditable === true ? <div class='d-flex align-items-center'>
                                        <input
                                          onChange={(e) => { handleResourceInputChange(index, 'Description', e.target.value) }}
                                          className="from-control "
                                          type="text"
                                          value={resource.Description}
                                          placeholder="Enter here"
                                          required=""
                                        />
                                      </div> : <p>{resource.Description}</p>}
                                    </td>


                                    {/* <td>
                                      {resource.isEditable === true ? <div class='d-flex align-items-center'>
                                        <select className="form-control" name="resourceType"
                                          value={resource.IsCash.toString()} onChange={(e) => { handleResourceInputChange(index, 'IsCash', e.target.value) }}>
                                          <option value={'select'} disabled selected>Resource Type</option>
                                          <option value={'true'}>Cash</option>
                                          <option value={'false'}>Non cash</option>
                                        </select>
                                      </div> : <p>{resource.IsCash.toString() === 'true' ? 'Cash' : 'Non cash'}</p>}
                                    </td> */}


                                    <td>
                                      <div
                                        className="btn table-sitebtn"
                                        onClick={() =>
                                          deleteResource(resource.Id)
                                        }
                                      >
                                        Delete
                                            </div>
                                    </td>
                                    <td>
                                      <div
                                        className="btn table-sitebtn"
                                        onClick={() => {
                                          resource.isEditable ? saveEditedResource(index) : editResourceRow(resource.Id)
                                        }
                                        }
                                      >
                                        {resource.isEditable ? 'Save' : 'Edit'}
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td />
                                  <td>Resource not found</td>
                                  <td />
                                  <td />
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <span className="text-danger">{errorResources ? "No resource" : null}</span>
                    </div>
                    <div className="col-12 col-md-6 mb-20">
                      <label className="check-style">
                        Feature Campaigns
                            <input
                          type="checkbox"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="IsFeatured"
                          name="IsFeatured"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="col-12 col-lg-12 mb-20">
                      <input
                        className="btn-send max-w-200 text-center cursor-pointer"
                        onClick={() => {
                          if (resources.length === 0) {
                            setErrorR('Please add at least one resource');
                          } else {
                            setErrorR('');
                            handleSubmit();
                          }
                        }}
                        value="Submit"
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
    </div>
  );
}

export default withAPIRequest(AdminEditCampaign);
