import React from "react";
import "./DonorRegisterConfirmed.scss";
import { useHistory } from "react-router-dom";
import Meta from "../../common/meta/Meta";

const DonorRegisterConfirmed = ( props ) => {

  let history = useHistory();
  return (
    <div className="container">
      <Meta title={ "Donor-Register-Confirmed" } content={ "Donor register confirmed" }/>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
          <div className="form-div form-pad">
            <div className="inner-part text-center">
              <h2 className="title mb-5">Profile Confirmation</h2>
            </div>
            <div id="form-messages"></div>
            <form id="contact-form" method="post" action="#">
              <fieldset>
                <div className="row mx-10-row">
                  <div className="col-12 mb-5 text-center">
                    <h2 className="title mb-3 form-label-green">
                      Congratulation
                    </h2>
                    <h4>
                      Email sent for verification. Please verify your account.
                    </h4>
                  </div>
                  <div className="col-8 mx-auto">
                    <div className="form-group mb-20">
                      <input
                        className="btn-send"
                        type="submit"
                        value="Login"
                        onClick={() => history.push("/Login")}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DonorRegisterConfirmed;
