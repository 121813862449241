/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import "./DonorRegister.scss";
import { useHistory, Link } from "react-router-dom";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";
import POLICY_CONTENT from '../../../configs/policies';

const DonorRegister = ( props ) => {

  const globalContext = useContext(GlobalContext);
  let history = useHistory();

  const callRegisterAPI = (email, password, firstname, lastname, phone) => {
    let serviceParams = {
      api: API.REGISTER_DONOR,
      method: AXIOS_METHOD_TYPES.POST,
      params: {
        FirstName: firstname,
        LastName: lastname,
        Email: email,
        Password: password,
        Role: 3,
      },
    };
    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          history.push("/donor-register-confirmed");
        } else {
          history.push("/");
        }
      }
    });
  };
  return (
    <div className="container">
      <Meta title={ "Donor register" } content={ "Donor register" }/>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
          <div className="form-div form-pad my-0">
            <div className="inner-part text-center">
              <h2 className="title mb-5">Donor Registration</h2>
            </div>
            <div id="form-messages"></div>
            <Formik
              initialValues={{
                firstname: "",
                lastname: "",
                phone: "",
                email: "",
                password: "",
                IsChecked: false,
              }}
              validationSchema={Yup.object().shape({
                firstname: Yup.string().required("Required"),
                lastname: Yup.string().required("Required"),
                email: Yup.string().email("Invalid email").required("Required"),
                password: Yup.string()
                  .min(6, "Must be 6 characters or more")
                  .required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                if (values.IsChecked) {
                  callRegisterAPI(
                    values.email,
                    values.password,
                    values.firstname,
                    values.lastname,
                    values.phone
                  );
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                isSubmitting,
              }) => (
                <Form>
                  <fieldset>
                    {!values.IsChecked && touched.IsChecked && (
                      <div
                        style={{
                          color: "#721c24",
                          backgroundColor: "#f8d7d9",
                          height: 50,
                          paddingTop: 15,
                          paddingLeft: 10,
                          marginBottom: 10,
                        }}
                      >
                        Please accept Terms & Privacy Policy
                      </div>
                    )}
                    <div className="row mx-10-row">
                      <div className="col-12 col-lg-6 mb-20">
                        <input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="from-control"
                          value={values.firstname}
                          type="text"
                          id="firstname"
                          name="firstname"
                          placeholder="First Name"
                        />
                        {errors.firstname && touched.firstname && (
                          <span style={{ color: "red" }}>
                            {errors.firstname}
                          </span>
                        )}
                      </div>
                      <div className="col-12 col-lg-6 mb-20">
                        <input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="from-control"
                          value={values.lastname}
                          type="text"
                          id="lastname"
                          name="lastname"
                          placeholder="Last Name"
                        />
                        {errors.lastname && touched.lastname && (
                          <span style={{ color: "red" }}>
                            {errors.lastname}
                          </span>
                        )}
                      </div>
                      <div className="col-12 mb-20">
                        <input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="from-control"
                          value={values.email}
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Email"
                        />
                        {errors.email && touched.email && (
                          <span style={{ color: "red" }}>{errors.email}</span>
                        )}
                      </div>
                      <div className="col-12 mb-35">
                        <input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="from-control"
                          value={values.password}
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Password"
                        />
                        {errors.password && touched.email && (
                          <span style={{ color: "red" }}>
                            {errors.password}
                          </span>
                        )}
                      </div>
                      <div className="col-12 mb-35">
                          <label className="check-style">
                          I have read and accept the terms and conditions, privacy policy, cookie policy, disclaimer, DMCA policy and acceptable use policy.<a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"> Click to view </a>
                            <input
                              type="checkbox"
                              checked={values.IsChecked}
                              onChange={handleChange}
                              id="IsChecked"
                              name="IsChecked"
                            />
                            <span className="checkmark"></span>
                          </label>

                          <div class="collapse mt-3" id="collapseExample">
                            <div class='card card-body login-text'>
                              <ul>
                                <li className="mb-2"><Link to={'/policies/privacy'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.privacy.title}</Link></li>
                                <li className="mb-2"><Link to={'/policies/terms'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.terms.title}</Link></li>
                                <li className="mb-2"><Link to={'/policies/cookie'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.cookie.title}</Link></li>
                                <li className="mb-2"><Link to={'/policies/disclaimer'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.disclaimer.title}</Link></li>
                                <li className="mb-2"><Link to={'/policies/dmca'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.dmca.title}</Link></li>
                                <li><Link to={'/policies/acceptableUse'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.acceptableUse.title}</Link></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div className="form-group mb-20">
                      <input
                        className="btn-send"
                        type="submit"
                        value="Signup"
                      />
                    </div>
                    <p class="text-center">
                      <strong>
                        {" "}
                        Check your inbox for you profile confirmation link
                      </strong>
                    </p>
                    <p class="text-center mb-0 login-text">
                      {" "}
                      Already have an account? <Link to="/login">
                        Login
                      </Link>{" "}
                    </p>
                  </fieldset>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAPIRequest(DonorRegister);
