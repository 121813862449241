import React, { useState } from 'react';
import Loading from './components/loading/Loading';
import { GlobalContext, GlobalContextProvider } from './context/GlobalContext';
import { ToastProvider } from 'react-toast-notifications'
import { HashRouter as Router } from "react-router-dom";
import { AppRoutes } from "./App.routes";
import InitialOperations from './components/initialOperations/InitialOperations';
import Layout from './components/layout/Layout';

export default function App() {

  console.log("HIMANSHU", process.env);

  const [appLoaded, setAppLoaded] = useState(false);

  return (
    <ToastProvider>
      <GlobalContextProvider>
        <Router basename='/'>
          <InitialOperations setAppLoaded={setAppLoaded} />
          {appLoaded && <GlobalContext.Consumer>
            {globalContext => (
              <>
                {globalContext.loading && <Loading />}
                <Layout><AppRoutes globalContext={globalContext} /></Layout>
              </>
            )}
          </GlobalContext.Consumer>}
        </Router>
      </GlobalContextProvider>
    </ToastProvider >
  );
}