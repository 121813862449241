/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import {
  API,
  AXIOS_METHOD_TYPES,
  USER_STATUS,
} from "../../../configs/constants";
import { GlobalContext } from "../../../context/GlobalContext";
import moment from "moment";
import Meta from "../../common/meta/Meta";
import "./AdminRequesterDetails.scss";

const AdminRequesterDetails = (props) => {
  const globalContext = useContext(GlobalContext);
  const { id } = useParams();
  const [requesterDetails, setRequesterDetails] = useState({});
  const [requesterStatus, setRequesterStatus] = useState("");
  const [campaignList, setCampaignList] = useState([]);

  useEffect(() => {
    if(globalContext.user.User.Role === 2 ){
    (async () => {
      await getRequesterDetails(id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
    
  }, []);

  const getRequesterDetails = async (id) => {
    let serviceParams = {
      api: `${API.REQUESTER_DETAILS}${id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };
    await globalContext.setLoading(true);
    await props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        setRequesterDetails(result.Data);
        setRequesterStatus(result.Data.StatusName);
        setCampaignList(result.Data.CampaignList);
      } else {
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "Details not found",
        });
      }
    });
  };

  const updateStatus = async (statusId) => {
    const status = {
      UserId: id,
      Status: statusId,
    };
    let serviceParams = {
      api: `${API.UPDATE_USER_STATUS}`,
      method: AXIOS_METHOD_TYPES.POST,
      params: status,
    };
    await globalContext.setLoading(true);
    await props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        USER_STATUS.forEach(({ id, text }) => {
          if (id === statusId) {
            setRequesterStatus(text);
            globalContext.setNotifications({
              type: "success",
              text: "Status Updated Successfully",
            });
            return;
          }
        });
      } else {
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "Error While Updating Status",
        });
      }
    });
  };

  return (
    <div className="container">
      <Meta title={ "Admin-Requester-Details" } content={ "Admin requester details " }/>
      { globalContext.user.User.Role === 2 ?  <div className="row">
        <div className="col-12">
          <div className="form-div details-page-holder">
            <div className="d-flex justify-content-between">
              <h3 className="dash-heading mb-0"> { `${ requesterDetails.FirstName } ${ requesterDetails.LastName }` }</h3>
            </div>
            <div className="row">
              <div className="col-12 col-lg-3 col-md-4 text-center">
                <div className="details-img-holder">
                  <div className="details-img mb-10">
                    <img
                      className="user"
                      src={ requesterDetails.ProfilePic }
                      alt="requester profile"
                    />
                  </div>
                  <Link to={{ pathname: '/change-personal-details', state: { profilePic: requesterDetails.ProfilePic, userId: requesterDetails.Id } }}>Update profile image</Link>
                </div>
              </div>
              <div className="col-12 col-lg-9 col-md-8 details-holder">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap">
                      <p className="mr-4 mb-2">
                        <strong> Status: </strong>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap ml-6">
                        <li>
                          <label>
                            <div className="dropdown">
                              <button
                                className="btn chips-box grn-bg mr-3 mr-sm-5 dropdown-toggle"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {requesterStatus ? requesterStatus : ""}
                              </button>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton"
                              >
                                {USER_STATUS.map(({ text, id }, index) => {
                                  if (
                                    requesterStatus !== text &&
                                    text !== "Featured"
                                  ) {
                                    return (
                                      <button
                                        key={index}
                                        className="dropdown-item"
                                        onClick={() => updateStatus(id)}
                                      >
                                        {text}
                                      </button>
                                    );
                                  } else {
                                    return false;
                                  }
                                })}
                              </div>
                            </div>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      <p className="mb-2">
                        <strong> Added on: </strong>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap ml-11">
                        <li>
                          <label>
                            {requesterDetails
                              ? moment(requesterDetails.AddedOn).format(
                                "MM/DD/YYYY"
                              )
                              : ""}
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      <p className="mb-2">
                        <strong> User Name: </strong>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap ml-11">
                        <li>
                          <label>
                            {requesterDetails?
                             requesterDetails.UserName:null}
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      <p className="mb-2">
                        <strong> Anonymous: </strong>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap ml-11">
                        <li>
                          <label>
                            {requesterDetails?
                             requesterDetails.IsAnonymous?'Yes':'No':null}
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      <p className="mb-2 mr-3">
                        <strong> Email: </strong>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap ml-4">
                        <li>
                          <label>
                            {requesterDetails ? requesterDetails.Email : "N/A"}
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      <p className="mb-2 mr-4">
                        <strong> Phone: </strong>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap ml-8">
                        <li>
                          <label>
                            {requesterDetails.Phone ? requesterDetails.Phone : "N/A"}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                    <hr/>
                    <h4 className="mt-4 mb-3"> Campaign List </h4>
                    <div className="client-details-table">
                      <div className="table-responsive">
                        <table className="table custom-table">
                          <thead>
                            <tr>
                              <th>Campaign Name</th>
                              <th> Recipient Name</th>
                              <th>Added on</th>
                              <th>Ends On</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaignList ? (
                              campaignList.map((campaign, index) => (
                                <tr
                                  key={index}
                                  className="campaign-item"
                                >
                                  <td>
                                    <p>{campaign.Name}</p>
                                  </td>
                                  <td>
                                    <p>{campaign.RequesterName}</p>
                                  </td>
                                  <td>
                                    <p>
                                      {moment(campaign.AddedOn).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      {moment(campaign.EndDate).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                  <p>{campaign.StatusName}</p>
                                  </td>
                                  <td>
                                    <span onClick={() =>
                                      props.history.push(
                                        `/admin-campaign-details/id/${campaign.Id}`
                                      )
                                    } className="btn col-12 no-wrap table-sitebtn mb-2">
                                      {'View'}
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td>No Campaigns Found</td>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
    </div>
  );
};

export default withAPIRequest(AdminRequesterDetails);
