import React, { useContext } from "react";
import "./ChangePassword.scss";
import { useHistory } from "react-router-dom";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const ChangePassword = (props) => {
  const globalContext = useContext(GlobalContext);
  let history = useHistory();

  const changePasswordHandler = async (params) => {
    let serviceParams = {
      api: API.CHANGE_PASSWORD,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    await globalContext.setLoading(true);
    await props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        localStorage.setItem("user", null);
        globalContext.setLoggedInUser(null);
        globalContext.setNotifications({
          id: Math.random(),
          type: "success",
          text: "Your password is updated.",
        });
        history.push('/login')
      } else {
        globalContext.setNotifications({
          id: Math.random(),
          type: "error",
          text: result
            ? result.Status.Message
            : "Error while updating password.",
        });
      }
    });
  };

  return (
    <div className="container">
      <Meta title={ "Change-Password" } content={ "Change Password" }/>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
          <div className="form-div ">
            <div className="d-flex mb-5">
              <h2 className="title mb-mb-15">Change Password</h2>
            </div>
            <Formik
              initialValues={{
                OldPassword: "",
                NewPassword: "",
                ConfirmPassword: "",
              }}
              validationSchema={Yup.object().shape({
                OldPassword: Yup.string().required("Required"),
                NewPassword: Yup.string().required("Required").min(6, "Must be 6 characters or more"),
                ConfirmPassword: Yup.string().oneOf(
                  [Yup.ref("NewPassword"), null],
                  "Passwords must match"
                ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                delete values["ConfirmPassword"];
                changePasswordHandler(values);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <div className="row">
                    <div className="col-12 mb-20">
                      <input
                        className="from-control"
                        type="password"
                        placeholder="Current Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.OldPassword}
                        id="OldPassword"
                        name="OldPassword"
                        required=""
                      />
                      {errors.OldPassword &&
                        touched.OldPassword &&
                        errors.OldPassword}
                    </div>
                    <div className="col-12 col-lg-12 mb-20">
                      <input
                        className="from-control"
                        type="password"
                        placeholder="New Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.NewPassword}
                        id="NewPassword"
                        name="NewPassword"
                        required=""
                      />
                       <span style={{color:'red'}}>{errors.NewPassword &&
                        touched.NewPassword &&
                        errors.NewPassword}
                        </span>
                    </div>
                    <div className="col-12 col-lg-12 mb-35">
                      <input
                        className="from-control"
                        type="password"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.ConfirmPassword}
                        id="ConfirmPassword"
                        name="ConfirmPassword"
                        required=""
                      />
                      <span style={{color:'red'}}>{errors.ConfirmPassword &&
                        touched.ConfirmPassword &&
                        errors.ConfirmPassword}
                        </span>
                    </div>
                    <div className="col-12 col-lg-12 mb-20">
                      <input
                        className="btn-send"
                        type="submit"
                        value="Submit"
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAPIRequest(ChangePassword);
