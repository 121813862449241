import React , { useContext } from "react";
import { useHistory } from "react-router-dom";
import { API , AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { GlobalContext } from "../../../context/GlobalContext";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { Form , Formik } from "formik";
import * as Yup from "yup";
import "./SuperadminCreateUser.scss";
import Meta from "../../common/meta/Meta";

const SuperadminCreateUser = ( props ) => {

    const globalContext = useContext( GlobalContext );
    let history = useHistory();
    const registerUser = ( params ) => {
        let serviceParams = {
            api : API.REGISTER_ADMIN_V1 ,
            method : AXIOS_METHOD_TYPES.POST ,
            params : params ,
        };

        globalContext.setLoading( true );
        props.commonAPIRequest( serviceParams , ( result ) => {
            globalContext.setLoading( false );
            if ( result ) {
                if ( result.Status.StatusId === 1 ) {
                    globalContext.setNotifications( {
                        id : Math.random() ,
                        type : "success" ,
                        text : result.Status.Message ? result.Status.Message : "User created successfully!" ,
                    } );
                    history.goBack();
                } else {
                    globalContext.setNotifications( {
                        id : Math.random() ,
                        type : "error" ,
                        text : result.Status.Message ,
                    } );
                }
            }
        } );
    };

    return (
        <div className="container">
            <Meta title={ "Super-Admin-Create-User" } content={ "Super admin Create User" }/>
            <div className="row">
                <div className="col-12">
                    <div className="form-div">
                        <div className="d-flex mb-5">
                            <h3 className="dash-heading mb-0">Add New User</h3>
                        </div>
                        <Formik
                            initialValues={ {
                                FirstName : "" ,
                                LastName : "" ,
                                Email : "" ,
                                Phone : "" ,
                                Role : 1 ,
                                Status : 2
                            } }
                            validationSchema={ Yup.object().shape( {
                                FirstName : Yup.string().required( "Required" ) ,
                                LastName : Yup.string().required( "Required" ) ,
                                Email : Yup.string().required( "Required" ) ,
                                Phone : Yup.number().required( "Required" ) ,
                                Role : Yup.number().required( "Required" ) 
                            } ) }
                            onSubmit={ ( values , { setSubmitting } ) => {
                                values[ "Phone" ] = values[ "Phone" ].toString();
                                values[ "Role" ] = parseInt( values[ "Role" ] );
                                registerUser( values );
                                setSubmitting( false );
                            } }
                        >
                            { ( { values , errors , touched , handleChange , handleBlur } ) => (
                                <Form>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-xl-5 mb-20">
                                            <input
                                                className="from-control"
                                                type="text"
                                                placeholder="First Name"
                                                id="FirstName"
                                                name="FirstName"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                                value={ values.FirstName }
                                                required=""
                                            />
                                            <span className="text-danger"> { errors.FirstName &&
                                            touched.FirstName &&
                                            errors.FirstName } </span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-5 mb-20">
                                            <input
                                                className="from-control"
                                                type="text"
                                                placeholder="Last Name"
                                                id="LastName"
                                                name="LastName"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                                value={ values.LastName }
                                                required=""
                                            />
                                            <span className="text-danger"> { errors.LastName &&
                                            touched.LastName &&
                                            errors.LastName }</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-5 mb-20">
                                            <input
                                                className="from-control"
                                                type="email"
                                                placeholder="Email"
                                                id="Email"
                                                name="Email"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                                value={ values.Email }
                                                required=""
                                            />
                                            <span className="text-danger">{ errors.Email &&
                                            touched.Email &&
                                            errors.Email }</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-5 mb-20">
                                            <input
                                                className="from-control"
                                                type="number"
                                                placeholder="Phone number"
                                                id="Phone"
                                                name="Phone"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                                value={ values.Phone }
                                                required=""
                                            />
                                            <span className="text-danger">{ errors.Phone &&
                                            touched.Phone &&
                                            errors.Phone }</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-5 mb-20">
                                            <select
                                                className="from-control"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                                id="Role"
                                                name="Role"
                                                value={ values.Role }
                                            >
                                                <option value={ 1 }>Super-admin</option>
                                                <option value={ 2 }>Church-admin</option>
                                                <option value={ 3 }>Donor</option>
                                                <option value={ 4 }>Requester</option>
                                            </select>
                                            <span className="text-danger">{ errors.Role &&
                                            touched.Role &&
                                            errors.Role }</span>
                                        </div>
                                        <div className="col-12 col-lg-12 mt-4 mb-20">
                                            <input
                                                className="btn-send max-w-200"
                                                type="submit"
                                                value="Submit"
                                            />
                                        </div>
                                    </div>
                                </Form>
                            ) }
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withAPIRequest( SuperadminCreateUser );