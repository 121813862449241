import React , { useContext , useEffect , useState } from "react";
import { useHistory , useParams } from "react-router-dom";
import { API , AXIOS_METHOD_TYPES , USER_STATUS } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import { Form , Formik } from "formik";
import * as Yup from "yup";
import Meta from "../../common/meta/Meta";
import "./SuperadminEditUser.scss";

const SuperadminEditUser = ( props ) => {

    const globalContext = useContext( GlobalContext );
    const [ user , setUser ] = useState( null );
    let history = useHistory();

    const { id } = useParams();
    useEffect( () => {
        getUser( id );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [] );

    const getUser = ( id ) => {
        let serviceParams = {
            api : API.USER_BY_ID + id ,
            method : AXIOS_METHOD_TYPES.GET ,
        };
        globalContext.setLoading( true );
        props.commonAPIRequest( serviceParams , ( result ) => {
            globalContext.setLoading( false );
            if ( result ) {
                if ( result.Status.StatusId === 1 ) {
                    setUser( result.Data );
                } else {
                    globalContext.setNotifications( {
                        id : Math.random() ,
                        type : "error" ,
                        text : result.Status.Message ,
                    } );
                }
            }
        } );
    }
    const updateUser = ( params ) => {
        let serviceParams = {
            api : API.UPDATE_ADMIN ,
            method : AXIOS_METHOD_TYPES.POST ,
            params : params ,
        };

        globalContext.setLoading( true );
        props.commonAPIRequest( serviceParams , ( result ) => {
            globalContext.setLoading( false );
            if ( result ) {
                if ( result.Status.StatusId === 1 ) {
                    globalContext.setNotifications( {
                        id : Math.random() ,
                        type : "success" ,
                        text : result.Status.Message ,
                    } );
                    history.goBack();
                } else {
                    globalContext.setNotifications( {
                        id : Math.random() ,
                        type : "error" ,
                        text : result.Status.Message ,
                    } );
                }
            }
        } );
    };

    return (
        <div className="container">
            <Meta title={ "Super-Admin-Edit-User" } content={ "Super admin edit user" }/>
            { user && <div className="row">
                <div className="col-12">
                    <div className="form-div">
                        <div className="d-flex mb-5">
                            <h3 className="dash-heading mb-0">Edit User</h3>
                        </div>
                        <Formik
                            enableReinitialize
                            initialValues={ {
                                Id : user.Id || "" ,
                                FirstName : user.FirstName || "" ,
                                LastName : user.LastName || "" ,
                                Email : user.Email || "" ,
                                Phone : user.Phone || "" ,
                                Status : user.Status || "" ,
                            } }
                            validationSchema={ Yup.object().shape( {
                                FirstName : Yup.string().required( "Required" ) ,
                                LastName : Yup.string().required( "Required" ) ,
                                Email : Yup.string().required( "Required" ) ,
                                Phone : Yup.number().required( "Required" ) ,
                            } ) }
                            onSubmit={ ( values , { setSubmitting } ) => {
                                values[ "Phone" ] = values[ "Phone" ].toString();
                                values[ "Status" ] = parseInt( values[ "Status" ] );
                                updateUser( values );
                                setSubmitting( false );
                            } }
                        >
                            { ( { values , errors , touched , handleChange , handleBlur } ) => (
                                <Form>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-xl-5 mb-20">
                                            <input
                                                className="from-control"
                                                type="text"
                                                placeholder="First Name"
                                                id="FirstName"
                                                name="FirstName"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                                defaultValue={ values.FirstName }
                                                required=""
                                            />
                                            <span className="text-danger"> { errors.FirstName &&
                                            touched.FirstName &&
                                            errors.FirstName } </span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-5 mb-20">
                                            <input
                                                className="from-control"
                                                type="text"
                                                placeholder="Last Name"
                                                id="LastName"
                                                name="LastName"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                                defaultValue={ values.LastName }
                                                required=""
                                            />
                                            <span className="text-danger"> { errors.LastName &&
                                            touched.LastName &&
                                            errors.LastName }</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-5 mb-20">
                                            <input
                                                className="from-control"
                                                type="email"
                                                placeholder="Email"
                                                id="Email"
                                                name="Email"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                                defaultValue={ values.Email }
                                                required=""
                                            />
                                            <span className="text-danger">{ errors.Email &&
                                            touched.Email &&
                                            errors.Email }</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-5 mb-20">
                                            <input
                                                className="from-control"
                                                type="number"
                                                placeholder="Phone number"
                                                id="Phone"
                                                name="Phone"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                                defaultValue={ values.Phone }
                                                required=""
                                            />
                                            <span className="text-danger">{ errors.PhoneNumber &&
                                            touched.PhoneNumber &&
                                            errors.PhoneNumber }</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-5 mb-20">
                                            <select
                                                className="from-control"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                                id="Status"
                                                name="Status"
                                            >
                                            {values.Email === 'ematthews@zionchurch.org' ?
                                                <option>N/A</option> :
                                                <option>
                                                    { USER_STATUS.find( ( { id } ) => id === user.Status )?.text }
                                                </option>
                                            }
                                            { values.Email !== 'ematthews@zionchurch.org' &&
                                                USER_STATUS.map( ( { id , text } , index ) => (
                                                    id !== user.Status &&
                                                    <option value={ id } key={ index }>{ text }</option>
                                                ) )
                                            }
                                            </select>
                                            <span className="text-danger">{ errors.Role &&
                                            touched.Role &&
                                            errors.Role }</span>
                                        </div>
                                        <div className="col-12 col-lg-12 mt-4 mb-20">
                                            <input
                                                className="btn-send max-w-200"
                                                type="submit"
                                                value="Submit"
                                            />
                                        </div>
                                    </div>
                                </Form>
                            ) }
                        </Formik>
                    </div>
                </div>
            </div> }
        </div>
    )
}

export default withAPIRequest( SuperadminEditUser );
