const POLICY_CONTENT = {
    privacy: {
        title: 'Privacy policy',
        browserTitle: 'Privacy policy',
        content: `<div class=WordSection1>

        <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>This privacy policy
        (&quot;Policy&quot;) describes how the personally identifiable information
        (&quot;Personal Information&quot;) you may provide on the </span><span lang=EN><a
        href="https://www.everyhumaneeds.com/"><span style='font-size:12.0pt;
        line-height:115%;color:#111111;text-decoration:none'>everyhumaneeds.com</span></a></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>
        website (&quot;Website&quot; or &quot;Service&quot;) and any of its related
        products and services (collectively, &quot;Services&quot;) is collected,
        protected and used. It also describes the choices available to you regarding
        our use of your Personal Information and how you can access and update this
        information. This Policy is a legally binding agreement between you
        (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;) and Zion Church
        (&quot;Zion Church&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;).
        By accessing and using the Website and Services, you acknowledge that you have
        read, understood, and agree to be bound by the terms of this Policy. This
        Policy does not apply to the practices of companies that we do not own or
        control, or to individuals that we do not employ or manage.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_4tj508kraiuf"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Automatic
        collection of information</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>When you open the
        Website, our servers automatically record information that your browser sends.
        This data may include information such as your device's IP address, browser
        type and version, operating system type and version, language preferences or
        the webpage you were visiting before you came to the Website and Services,
        pages of the Website and Services that you visit, the time spent on those
        pages, information you search for on the Website, access times and dates, and
        other statistics.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Information collected
        automatically is used only to identify potential cases of abuse and establish
        statistical information regarding the usage and traffic of the Website and
        Services. This statistical information is not otherwise aggregated in such a
        way that would identify any particular user of the system.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_c6xuynxt7tlq"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Collection
        of personal information</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You can access and
        use the Website and Services without telling us who you are or revealing any
        information by which someone could identify you as a specific, identifiable
        individual. If, however, you wish to use some of the features on the Website,
        you may be asked to provide certain Personal Information (for example, your
        name and e-mail address). We receive and store any information you knowingly
        provide to us when you create an account, publish content, or fill any online
        forms on the Website. When required, this information may include the
        following:</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Personal
        details such as name, country of residence, etc.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Contact
        information such as email address, address, etc.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Payment
        information such as credit card details, bank details, etc.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Geolocation
        data such as latitude and longitude.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Information
        about other individuals such as your family members, friends, etc.</span></p>
        
        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:22.0pt;
        margin-left:47.0pt;text-indent:-.25in'><span lang=EN style='font-size:12.0pt;
        line-height:115%;color:#111111'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>Any other materials you willingly submit to
        us such as articles, images, feedback, etc.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Some of the
        information we collect is directly from you via the Website and Services.
        However, we may also collect Personal Information about you from other sources
        such as public databases, social media platforms, third-party data providers,
        and our joint marketing partners. Personal Information we collect from other sources
        may include demographic information, such as age and gender, device
        information, such as IP addresses, location, such as city and state, and online
        behavioral data, such as information about your use of social media websites,
        page view information and search results and links. You can choose not to
        provide us with your Personal Information, but then you may not be able to take
        advantage of some of the features on the Website. Users who are uncertain about
        what information is mandatory are welcome to contact us.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_wjxmlwjwqxvu"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Use
        and processing of collected information</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>In order to make the
        Website and Services available to you, or to meet a legal obligation, we may
        need to collect and use certain Personal Information. If you do not provide the
        information that we request, we may not be able to provide you with the
        requested products or services. Any of the information we collect from you may
        be used for the following purposes:</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Improve
        products and services</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Respond
        to inquiries and offer support</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Request
        user feedback</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Improve
        user experience</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Post
        customer testimonials</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Enforce
        terms and conditions and policies</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Protect
        from abuse and malicious users</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Respond
        to legal requests and prevent harm</span></p>
        
        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:22.0pt;
        margin-left:47.0pt;text-indent:-.25in'><span lang=EN style='font-size:12.0pt;
        line-height:115%;color:#111111'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>Run and operate the Website and Services</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Processing your
        Personal Information depends on how you interact with the Website and Services,
        where you are located in the world and if one of the following applies: (i) you
        have given your consent for one or more specific purposes; this, however, does
        not apply, whenever the processing of Personal Information is subject to
        European data protection law; (ii) provision of information is necessary for
        the performance of an agreement with you and/or for any pre-contractual
        obligations thereof; (iii) processing is necessary for compliance with a legal
        obligation to which you are subject; (iv) processing is related to a task that
        is carried out in the public interest or in the exercise of official authority
        vested in us; (v) processing is necessary for the purposes of the legitimate
        interests pursued by us or by a third party.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Note that under some
        legislations we may be allowed to process information until you object to such
        processing (by opting out), without having to rely on consent or any other of
        the following legal bases below. In any case, we will be happy to clarify the
        specific legal basis that applies to the processing, and in particular whether
        the provision of Personal Information is a statutory or contractual
        requirement, or a requirement necessary to enter into a contract.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_cc5hp43g9jy3"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Managing
        information</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You are able to
        delete certain Personal Information we have about you. The Personal Information
        you can delete may change as the Website and Services change. When you delete
        Personal Information, however, we may maintain a copy of the unrevised Personal
        Information in our records for the duration necessary to comply with our
        obligations to our affiliates and partners, and for the purposes described
        below. If you would like to delete your Personal Information or permanently
        delete your account, you can do so by contacting us.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_8j18bm2o71qm"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Disclosure
        of information</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Depending on the
        requested Services or as necessary to complete any transaction or provide any
        service you have requested, we may share your information with your consent
        with our trusted third parties that work with us, any other affiliates and
        subsidiaries we rely upon to assist in the operation of the Website and
        Services available to you. We do not share Personal Information with
        unaffiliated third parties. These service providers are not authorized to use
        or disclose your information except as necessary to perform services on our
        behalf or comply with legal requirements. We may share your Personal
        Information for these purposes only with third parties whose privacy policies
        are consistent with ours or who agree to abide by our policies with respect to
        Personal Information. These third parties are given Personal Information they
        need only in order to perform their designated functions, and we do not
        authorize them to use or disclose Personal Information for their own marketing
        or other purposes.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We will disclose any
        Personal Information we collect, use or receive if required or permitted by
        law, such as to comply with a subpoena, or similar legal process, and when we
        believe in good faith that disclosure is necessary to protect our rights,
        protect your safety or the safety of others, investigate fraud, or respond to a
        government request.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_gl2kp266hofk"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Retention
        of information</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We will retain and
        use your Personal Information for the period necessary to comply with our legal
        obligations, resolve disputes, and enforce our agreements unless a longer
        retention period is required or permitted by law. We may use any aggregated
        data derived from or incorporating your Personal Information after you update
        or delete it, but not in a manner that would identify you personally. Once the
        retention period expires, Personal Information shall be deleted. Therefore, the
        right to access, the right to erasure, the right to rectification and the right
        to data portability cannot be enforced after the expiration of the retention
        period.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_8cohvcdnaiff"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>The
        rights of users</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You may exercise
        certain rights regarding your information processed by us. In particular, you
        have the right to do the following: (i) you have the right to withdraw consent
        where you have previously given your consent to the processing of your
        information; (ii) you have the right to object to the processing of your
        information if the processing is carried out on a legal basis other than
        consent; (iii) you have the right to learn if information is being processed by
        us, obtain disclosure regarding certain aspects of the processing and obtain a
        copy of the information undergoing processing; (iv) you have the right to
        verify the accuracy of your information and ask for it to be updated or
        corrected; (v) you have the right, under certain circumstances, to restrict the
        processing of your information, in which case, we will not process your
        information for any purpose other than storing it; (vi) you have the right,
        under certain circumstances, to obtain the erasure of your Personal Information
        from us; (vii) you have the right to receive your information in a structured,
        commonly used and machine readable format and, if technically feasible, to have
        it transmitted to another controller without any hindrance. This provision is
        applicable provided that your information is processed by automated means and
        that the processing is based on your consent, on a contract which you are part
        of or on pre-contractual obligations thereof.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_bcbi8gqmvvhy"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Privacy
        of children</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We do not knowingly
        collect any Personal Information from children under the age of 18. If you are
        under the age of 18, please do not submit any Personal Information through the
        Website and Services. We encourage parents and legal guardians to monitor their
        children's Internet usage and to help enforce this Policy by instructing their
        children never to provide Personal Information through the Website and Services
        without their permission. If you have reason to believe that a child under the
        age of 18 has provided Personal Information to us through the Website and
        Services, please contact us. You must also be old enough to consent to the
        processing of your Personal Information in your country (in some countries we
        may allow your parent or guardian to do so on your behalf).</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_5mhvq13zz12p"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Cookies</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>The Website and
        Services use &quot;cookies&quot; to help personalize your online experience. A
        cookie is a text file that is placed on your hard disk by a web page server.
        Cookies cannot be used to run programs or deliver viruses to your computer.
        Cookies are uniquely assigned to you, and can only be read by a web server in
        the domain that issued the cookie to you.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We may use cookies to
        collect, store, and track information for statistical purposes to operate the Website
        and Services. You have the ability to accept or decline cookies. Most web
        browsers automatically accept cookies, but you can usually modify your browser
        setting to decline cookies if you prefer. If you choose to decline cookies, you
        may not be able to fully experience the features of the Website and Services. </span><span
        lang=EN><a href="https://www.websitepolicies.com/blog/cookies"><span
        style='font-size:12.0pt;line-height:115%;color:#111111;
        text-decoration:none'>Click here</span></a></span><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'> to
        learn more about cookies and how they work.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_74wu8oatduc9"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Do
        Not Track signals</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Some browsers
        incorporate a Do Not Track feature that signals to websites you visit that you
        do not want to have your online activity tracked. Tracking is not the same as
        using or collecting information in connection with a website. For these
        purposes, tracking refers to collecting personally identifiable information
        from consumers who use or visit a website or online service as they move across
        different websites over time. How browsers communicate the Do Not Track signal
        is not yet uniform. As a result, the Website and Services are not yet set up to
        interpret or respond to Do Not Track signals communicated by your browser. Even
        so, as described in more detail throughout this Policy, we limit our use and
        collection of your personal information.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_3qsc57dowz9l"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Email
        marketing</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We offer electronic
        newsletters to which you may voluntarily subscribe at any time. We are
        committed to keeping your e-mail address confidential and will not disclose
        your email address to any third parties except as allowed in the information
        use and processing section. We will maintain the information sent via e-mail in
        accordance with applicable laws and regulations.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>In compliance with
        the CAN-SPAM Act, all e-mails sent from us will clearly state who the e-mail is
        from and provide clear information on how to contact the sender. You may choose
        to stop receiving our newsletter or marketing emails by following the
        unsubscribe instructions included in these emails or by contacting us. However,
        you will continue to receive essential transactional emails.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_bvyhwyh6zi7l"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Links
        to other resources</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>The Website and
        Services contain links to other resources that are not owned or controlled by
        us. Please be aware that we are not responsible for the privacy practices of
        such other resources or third parties. We encourage you to be aware when you
        leave the Website and Services and to read the privacy statements of each and
        every resource that may collect Personal Information.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_22sxfjj92536"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Information
        security</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We secure information
        you provide on computer servers in a controlled, secure environment, protected
        from unauthorized access, use, or disclosure. We maintain reasonable
        administrative, technical, and physical safeguards in an effort to protect
        against unauthorized access, use, modification, and disclosure of Personal
        Information in its control and custody. However, no data transmission over the
        Internet or wireless network can be guaranteed. Therefore, while we strive to
        protect your Personal Information, you acknowledge that (i) there are security
        and privacy limitations of the Internet which are beyond our control; (ii) the
        security, integrity, and privacy of any and all information and data exchanged
        between you and the Website and Services cannot be guaranteed; and (iii) any
        such information and data may be viewed or tampered with in transit by a third
        party, despite best efforts.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_46p75xgsrvpv"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Data
        breach</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>In the event we
        become aware that the security of the Website and Services has been compromised
        or users Personal Information has been disclosed to unrelated third parties as
        a result of external activity, including, but not limited to, security attacks
        or fraud, we reserve the right to take reasonably appropriate measures,
        including, but not limited to, investigation and reporting, as well as
        notification to and cooperation with law enforcement authorities. In the event
        of a data breach, we will make reasonable efforts to notify affected
        individuals if we believe that there is a reasonable risk of harm to the user
        as a result of the breach or if notice is otherwise required by law. When we
        do, we will post a notice on the Website, send you an email.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_95u2be1rloqx"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Changes
        and amendments</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We reserve the right
        to modify this Policy or its terms relating to the Website and Services from
        time to time in our discretion and will notify you of any material changes to
        the way in which we treat Personal Information. When we do, we will revise the
        updated date at the bottom of this page. We may also provide notice to you in
        other ways in our discretion, such as through contact information you have
        provided. Any updated version of this Policy will be effective immediately upon
        the posting of the revised Policy unless otherwise specified. Your continued
        use of the Website and Services after the effective date of the revised Policy
        (or such other act specified at that time) will constitute your consent to
        those changes. However, we will not, without your consent, use your Personal
        Information in a manner materially different than what was stated at the time
        your Personal Information was collected.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_c593ba4c3uuz"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Acceptance
        of this policy</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You acknowledge that
        you have read this Policy and agree to all its terms and conditions. By
        accessing and using the Website and Services you agree to be bound by this
        Policy. If you do not agree to abide by the terms of this Policy, you are not
        authorized to access or use the Website and Services.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_bpknwjiim2y9"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Contacting
        us</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>If you would like to
        contact us to understand more about this Policy or wish to contact us
        concerning any matter relating to individual rights and your Personal
        Information, you may send an email to support@everyhumanneeds.com.</span></p>
        
        <p class=MsoNormal><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>This document was last updated on April 26,
        2021</span></p>
        
        <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
        
        </div>`
    },
    terms: {
        title: 'Terms and conditions',
        browserTitle: 'Terms and conditions',
        content: `<div class=WordSection1>

        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>These terms and
        conditions (&quot;Agreement&quot;) set forth the general terms and conditions
        of your use of the </span><span lang=EN><a href="http://everyhumaneeds.com/"><span
        style='font-size:12.0pt;line-height:115%;color:#111111;
        text-decoration:none'>everyhumaneeds.com</span></a></span><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>
        website (&quot;Website&quot;), &quot;EveryHumanNeeds&quot; mobile application
        (&quot;Mobile Application&quot;) and any of their related products and services
        (collectively, &quot;Services&quot;). This Agreement is legally binding between
        you (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;) and Zion Church
        (&quot;Zion Church&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;).
        By accessing and using the Services, you acknowledge that you have read,
        understood, and agree to be bound by the terms of this Agreement. If you are
        entering into this Agreement on behalf of a business or other legal entity, you
        represent that you have the authority to bind such entity to this Agreement, in
        which case the terms &quot;User&quot;, &quot;you&quot; or &quot;your&quot;
        shall refer to such entity. If you do not have such authority, or if you do not
        agree with the terms of this Agreement, you must not accept this Agreement and
        may not access and use the Services. You acknowledge that this Agreement is a
        contract between you and Zion Church, even though it is electronic and is not
        physically signed by you, and it governs your use of the Services.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_hukakpi2c64w"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Accounts
        and membership</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You must be at least
        18 years of age to use the Services. By using the Services and by agreeing to
        this Agreement you warrant and represent that you are at least 18 years of age.
        If you create an account on the Services, you are responsible for maintaining
        the security of your account and you are fully responsible for all activities
        that occur under the account and any other actions taken in connection with it.
        We may, but have no obligation to, monitor and review new accounts before you
        may sign in and start using the Services. Providing false contact information
        of any kind may result in the termination of your account. You must immediately
        notify us of any unauthorized uses of your account or any other breaches of
        security. We will not be liable for any acts or omissions by you, including any
        damages of any kind incurred as a result of such acts or omissions. We may
        suspend, disable, or delete your account (or any part thereof) if we determine
        that you have violated any provision of this Agreement or that your conduct or
        content would tend to damage our reputation and goodwill. If we delete your
        account for the foregoing reasons, you may not re-register for our Services. We
        may block your email address and Internet protocol address to prevent further
        registration.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_gvf4o4hxa8yp"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>User
        content</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We do not own any
        data, information or material (collectively, &quot;Content&quot;) that you
        submit on the Services in the course of using the Service. You shall have sole
        responsibility for the accuracy, quality, integrity, legality, reliability,
        appropriateness, and intellectual property ownership or right to use of all
        submitted Content. We may, but have no obligation to, monitor and review the
        Content on the Services submitted or created using our Services by you. You grant
        us permission to access, copy, distribute, store, transmit, reformat, display
        and perform the Content of your user account solely as required for the purpose
        of providing the Services to you. Without limiting any of those representations
        or warranties, we have the right, though not the obligation, to, in our own
        sole discretion, refuse or remove any Content that, in our reasonable opinion,
        violates any of our policies or is in any way harmful or objectionable. You
        also grant us the license to use, reproduce, adapt, modify, publish or
        distribute the Content created by you or stored in your user account for
        commercial, marketing or any similar purpose.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_d1ewmy8zxfyf"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Backups</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We are not
        responsible for the Content residing on the Services. In no event shall we be
        held liable for any loss of any Content. It is your sole responsibility to
        maintain appropriate backup of your Content. Notwithstanding the foregoing, on
        some occasions and in certain circumstances, with absolutely no obligation, we
        may be able to restore some or all of your data that has been deleted as of a
        certain date and time when we may have backed up data for our own purposes. We
        make no guarantee that the data you need will be available.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_dl009vwrrzv5"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Links
        to other resources</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Although the Services
        may link to other resources (such as websites, mobile applications, etc.), we
        are not, directly or indirectly, implying any approval, association,
        sponsorship, endorsement, or affiliation with any linked resource, unless
        specifically stated herein. We are not responsible for examining or evaluating,
        and we do not warrant the offerings of, any businesses or individuals or the
        content of their resources. We do not assume any responsibility or liability
        for the actions, products, services, and content of any other third parties.
        You should carefully review the legal statements and other conditions of use of
        any resource which you access through a link on the Services. Your linking to
        any other off-site resources is at your own risk.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_ihszrrjklqih"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Prohibited
        uses</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>In addition to other
        terms as set forth in the Agreement, you are prohibited from using the Services
        or Content: (a) for any unlawful purpose; (b) to solicit others to perform or
        participate in any unlawful acts; (c) to violate any international, federal,
        provincial or state regulations, rules, laws, or local ordinances; (d) to
        infringe upon or violate our intellectual property rights or the intellectual
        property rights of others; (e) to harass, abuse, insult, harm, defame, slander,
        disparage, intimidate, or discriminate based on gender, sexual orientation,
        religion, ethnicity, race, age, national origin, or disability; (f) to submit
        false or misleading information; (g) to upload or transmit viruses or any other
        type of malicious code that will or may be used in any way that will affect the
        functionality or operation of the Services, third party products and services,
        or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape;
        (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent
        the security features of the Services, third party products and services, or
        the Internet. We reserve the right to terminate your use of the Services for
        violating any of the prohibited uses.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_yxya7jqxez4c"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Disclaimer
        of warranty</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You agree that such
        Service is provided on an &quot;as is&quot; and &quot;as available&quot; basis
        and that your use of the Services is solely at your own risk. We expressly
        disclaim all warranties of any kind, whether express or implied, including but
        not limited to the implied warranties of merchantability, fitness for a particular
        purpose and non-infringement. We make no warranty that the Services will meet
        your requirements, or that the Service will be uninterrupted, timely, secure,
        or error-free; nor do we make any warranty as to the results that may be
        obtained from the use of the Service or as to the accuracy or reliability of
        any information obtained through the Service or that defects in the Service
        will be corrected. You understand and agree that any material and/or data
        downloaded or otherwise obtained through the use of Service is done at your own
        discretion and risk and that you will be solely responsible for any damage or
        loss of data that results from the download of such material and/or data. We
        make no warranty regarding any goods or services purchased or obtained through
        the Service or any transactions entered into through the Service unless stated
        otherwise. No advice or information, whether oral or written, obtained by you
        from us or through the Service shall create any warranty not expressly made
        herein.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_47s0fsdwpmw6"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Limitation
        of liability</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>To the fullest extent
        permitted by applicable law, in no event will Zion Church, its affiliates,
        directors, officers, employees, agents, suppliers or licensors be liable to any
        person for any indirect, incidental, special, punitive, cover or consequential
        damages (including, without limitation, damages for lost profits, revenue,
        sales, goodwill, use of content, impact on business, business interruption,
        loss of anticipated savings, loss of business opportunity) however caused,
        under any theory of liability, including, without limitation, contract, tort,
        warranty, breach of statutory duty, negligence or otherwise, even if the liable
        party has been advised as to the possibility of such damages or could have
        foreseen such damages. To the maximum extent permitted by applicable law, the
        aggregate liability of Zion Church and its affiliates, officers, employees,
        agents, suppliers and licensors relating to the services will be limited to an
        amount greater of one dollar or any amounts actually paid in cash by you to
        Zion Church for the prior one month period prior to the first event or
        occurrence giving rise to such liability. The limitations and exclusions also
        apply if this remedy does not fully compensate you for any losses or fails of
        its essential purpose.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_fhsd8qajz4yg"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Indemnification</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You agree to
        indemnify and hold Zion Church and its affiliates, directors, officers,
        employees, agents, suppliers and licensors harmless from and against any
        liabilities, losses, damages or costs, including reasonable attorneys' fees,
        incurred in connection with or arising from any third party allegations,
        claims, actions, disputes, or demands asserted against any of them as a result
        of or relating to your Content, your use of the Services or any willful
        misconduct on your part.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_lr36w0ygdqk7"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Dispute
        resolution</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>The formation,
        interpretation, and performance of this Agreement and any disputes arising out
        of it shall be governed by the substantive and procedural laws of Maryland,
        United States without regard to its rules on conflicts or choice of law and, to
        the extent applicable, the laws of United States. The exclusive jurisdiction
        and venue for actions related to the subject matter hereof shall be the courts
        located in Maryland, United States, and you hereby submit to the personal
        jurisdiction of such courts. You hereby waive any right to a jury trial in any
        proceeding arising out of or related to this Agreement. The United Nations
        Convention on Contracts for the International Sale of Goods does not apply to
        this Agreement.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_dm3o61v81v4r"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Changes
        and amendments</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We reserve the right
        to modify this Agreement or its terms relating to the Services at any time,
        effective upon posting of an updated version of this Agreement on the Services.
        When we do, we will post a notification within the Services. Continued use of
        the Services after any such changes shall constitute your consent to such
        changes.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_8zasbgnard0"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Acceptance
        of these terms</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You acknowledge that
        you have read this Agreement and agree to all its terms and conditions. By
        accessing and using the Services you agree to be bound by this Agreement. If
        you do not agree to abide by the terms of this Agreement, you are not
        authorized to access or use the Services.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_6qjre3wfurun"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Contacting
        us</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>If you would like to
        contact us to understand more about this Agreement or wish to contact us
        concerning any matter relating to it, you may send an email to
        support@everyhumanneeds.com.</span></p>
        
        <p class=MsoNormal><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>This document was last updated on April 26,
        2021</span></p>
        
        <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
        
        </div>`
    },
    cookie: {
        title: 'Cookie policy',
        browserTitle: 'Cookie policy',
        content: `<div class=WordSection1>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>This cookie policy
        (&quot;Policy&quot;) describes what cookies are and how and they're being used
        by the </span><span lang=EN><a href="https://www.everyhumaneeds.com/"><span
        style='font-size:12.0pt;line-height:115%;color:#111111;
        text-decoration:none'>everyhumaneeds.com</span></a></span><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>
        website (&quot;Website&quot; or &quot;Service&quot;) and any of its related
        products and services (collectively, &quot;Services&quot;). This Policy is a
        legally binding agreement between you (&quot;User&quot;, &quot;you&quot; or
        &quot;your&quot;) and Zion Church (&quot;Zion Church&quot;, &quot;we&quot;,
        &quot;us&quot; or &quot;our&quot;). You should read this Policy so you can
        understand the types of cookies we use, the information we collect using
        cookies and how that information is used. It also describes the choices
        available to you regarding accepting or declining the use of cookies. For
        further information on how we use, store and keep your personal data secure,
        see our </span><span lang=EN><a href="https://www.everyhumanneeds.com/privacy"><span
        style='font-size:12.0pt;line-height:115%;color:#111111;
        text-decoration:none'>privacy policy</span></a></span><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_tuu38cspr67h"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>What
        are cookies?</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Cookies are small
        pieces of data stored in text files that are saved on your computer or other
        devices when websites are loaded in a browser. They are widely used to remember
        you and your preferences, either for a single visit (through a &quot;session
        cookie&quot;) or for multiple repeat visits (using a &quot;persistent
        cookie&quot;).</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Session cookies are
        temporary cookies that are used during the course of your visit to the Website,
        and they expire when you close the web browser.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Persistent cookies
        are used to remember your preferences within our Website and remain on your
        desktop or mobile device even after you close your browser or restart your
        computer. They ensure a consistent and efficient experience for you while
        visiting the Website and Services.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Cookies may be set by
        the Website (&quot;first-party cookies&quot;), or by third parties, such as
        those who serve content or provide advertising or analytics services on the
        Website (&quot;third party cookies&quot;). These third parties can recognize
        you when you visit our website and also when you visit certain other websites. </span><span
        lang=EN><a href="https://www.websitepolicies.com/blog/cookies"><span
        style='font-size:12.0pt;line-height:115%;color:#111111;
        text-decoration:none'>Click here</span></a></span><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'> to
        learn more about cookies and how they work.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_71fkms2696sw"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>What
        type of cookies do we use?</span></b></h2>
        
        <h3 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_bltab59caam"></a><b><span lang=EN
        style='font-size:13.0pt;line-height:115%;color:#111111'>Necessary
        cookies</span></b></h3>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Necessary cookies
        allow us to offer you the best possible experience when accessing and
        navigating through our Website and using its features. For example, these
        cookies let us recognize that you have created an account and have logged into
        that account to access the content.</span></p>
        
        <h3 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_u9nafr1p7iqb"></a><b><span lang=EN
        style='font-size:13.0pt;line-height:115%;color:#111111'>Functionality
        cookies</span></b></h3>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Functionality cookies
        let us operate the Website and Services in accordance with the choices you
        make. For example, we will recognize your username and remember how you
        customized the Website and Services during future visits.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_r65ki2w1trho"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Do
        we use web beacons or tracking pixels?</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Our emails may
        contain a &quot;web beacon&quot; (or &quot;tracking pixel&quot;) to tell us
        whether our emails are opened and verify any clicks through to links or
        advertisements within the email.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We may use this
        information for purposes including determining which of our emails are more
        interesting to users and to query whether users who do not open our emails wish
        to continue receiving them.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>The pixel will be
        deleted when you delete the email. If you do not wish the pixel to be
        downloaded to your device, you should read the email in plain text view or with
        images disabled.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_r9xhbz9xet5t"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>What
        are your cookie options?</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>If you don't like the
        idea of cookies or certain types of cookies, you can change your browser's
        settings to delete cookies that have already been set and to not accept new
        cookies. Visit </span><span lang=EN><a href="https://www.internetcookies.com/"><span
        style='font-size:12.0pt;line-height:115%;color:#111111;
        text-decoration:none'>internetcookies.com</span></a></span><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'> to
        learn more about how to do this.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_ckhx3annqltd"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Changes
        and amendments</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We reserve the right
        to modify this Policy or its terms relating to the Website and Services at any
        time, effective upon posting of an updated version of this Policy on the
        Website. When we do, we will revise the updated date at the bottom of this
        page. Continued use of the Website and Services after any such changes shall
        constitute your consent to such changes.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_baz4xq2gs2v4"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Acceptance
        of this policy</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You acknowledge that
        you have read this Policy and agree to all its terms and conditions. By
        accessing and using the Website and Services you agree to be bound by this
        Policy. If you do not agree to abide by the terms of this Policy, you are not
        authorized to access or use the Website and Services.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_fizzs6inmqpr"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Contacting
        us</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>If you would like to
        contact us to understand more about this Policy or wish to contact us
        concerning any matter relating to our use of cookies, you may send an email to
        support@everyhumanneeds.com.</span></p>
        
        <p class=MsoNormal><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>This document was last updated on April 26,
        2021</span></p>
        
        <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
        
        </div>`
    },
    disclaimer: {
        title: 'Disclaimer',
        browserTitle: 'Disclaimer',
        content: `<div class=WordSection1>

        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>This disclaimer
        (&quot;Disclaimer&quot;) sets forth the general guidelines, disclosures, and
        terms of your use of the </span><span lang=EN><a
        href="https://www.everyhumaneeds.com/"><span style='font-size:12.0pt;
        line-height:115%;color:#111111;text-decoration:none'>everyhumaneeds.com</span></a></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>
        website (&quot;Website&quot; or &quot;Service&quot;) and any of its related
        products and services (collectively, &quot;Services&quot;). This Disclaimer is
        a legally binding agreement between you (&quot;User&quot;, &quot;you&quot; or
        &quot;your&quot;) and Zion Church (&quot;Zion Church&quot;, &quot;we&quot;,
        &quot;us&quot; or &quot;our&quot;). By accessing and using the Website and
        Services, you acknowledge that you have read, understood, and agree to be bound
        by the terms of this Disclaimer. If you are entering into this Disclaimer on
        behalf of a business or other legal entity, you represent that you have the
        authority to bind such entity to this Disclaimer, in which case the terms
        &quot;User&quot;, &quot;you&quot; or &quot;your&quot; shall refer to such
        entity. If you do not have such authority, or if you do not agree with the
        terms of this Disclaimer, you must not accept this Disclaimer and may not
        access and use the Website and Services. You acknowledge that this Disclaimer
        is a contract between you and Zion Church, even though it is electronic and is
        not physically signed by you, and it governs your use of the Website and
        Services.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_ciui1rgegm2n"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Representation</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Any views or opinions
        represented on the Website belong solely to Zion Church, its content creators
        and employees, and do not represent those of people, institutions or
        organizations that Zion Church may or may not be associated with in professional
        or personal capacity unless explicitly stated. Any views or opinions are not
        intended to malign any religion, ethnic group, club, organization, company, or
        individual.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_64vti73yh544"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Content
        and postings</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You may not modify,
        print or copy any part of the Website and Services. Inclusion of any part of
        the Website and Services in another work, whether in printed or electronic or
        another form or inclusion of any part of the Website and Services on another
        resource by embedding, framing or otherwise without the express permission of
        Zion Church is prohibited.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You may submit new
        content and comment on the existing content on the Website. By uploading or
        otherwise making available any information to Zion Church, you grant Zion
        Church the unlimited, perpetual right to distribute, display, publish,
        reproduce, reuse and copy the information contained therein. You may not
        impersonate any other person through the Website and Services. You may not post
        content that is defamatory, fraudulent, obscene, threatening, invasive of another
        person's privacy rights or that is otherwise unlawful. You may not post content
        that infringes on the intellectual property rights of any other person or
        entity. You may not post any content that includes any computer virus or other
        code designed to disrupt, damage, or limit the functioning of any computer
        software or hardware. By submitting or posting content on the Website, you
        grant Zion Church the right to edit and, if necessary, remove any content at
        any time and for any reason.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_s2j5ydzb3l1n"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Compensation
        and sponsorship</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Some of the links on
        the Website may be &quot;affiliate links&quot;. This means if you click on the
        link and purchase an item, Zion Church will receive an affiliate commission.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_othoyhde0mrz"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Not
        legal advice</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>The information
        provided on the Website is for general information purposes only and is not an
        alternative to legal advice from your lawyer, other professional services
        provider, or expert. It is not intended to provide legal advice or opinions of
        any kind. You should not act, or refrain from acting, based solely upon the
        information provided on the Website without first seeking appropriate legal or
        other professional advice. If you have any specific questions about any legal
        matter, you should consult your lawyer, other professional services provider,
        or expert. You should never delay seeking legal advice, disregard legal advice,
        or commence or discontinue any legal action because of the information on the
        Website.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>The information on
        the Website is provided for your convenience only. This information may have no
        evidentiary value and should be checked against official sources before it is
        used for any purposes. It is your responsibility to determine whether this
        information is admissible in a given judicial or administrative proceeding and
        whether there are any other evidentiary or filing requirements. Your use of
        this information is at your own risk.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_38fvtmqcmn5a"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Not
        financial advice</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>The information on
        the Website is provided for your convenience only and is not intended to be
        treated as financial, investment, tax, or other advice. Nothing contained on
        the Website constitutes a solicitation, recommendation, endorsement, or offer
        by Zion Church, its agents, employees, contractors, and any affiliated
        companies to buy or sell any securities or other financial instruments.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>All content on this
        site is the information of a general nature and does not address the
        circumstances of any particular individual or entity. Nothing on the Website
        constitutes professional and/or financial advice, nor does any information on
        the Website constitute a comprehensive or complete statement of the matters
        discussed or the law relating thereto. You alone assume the sole responsibility
        of evaluating the merits and risks associated with the use of any information
        or other content on the Website before making any decisions based on such
        information. You agree not to hold Zion Church, its agents, employees,
        contractors, and any affiliated companies liable for any possible claim for
        damages arising from any decision you make based on the information made available
        to you through the Website.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_1y4drvhaod1"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Reviews
        and testimonials</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Testimonials are
        received in various forms through a variety of submission methods. The
        testimonials are not necessarily representative of all of those who will use
        Website and Services, and Zion Church is not responsible for the opinions or
        comments available on the Website, and does not necessarily share them. All
        opinions expressed are strictly the views of the reviewers.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Some testimonials may
        have been edited for clarity, or shortened in cases where the original
        testimonial included extraneous information of no relevance to the general
        public. Testimonials may be reviewed for authenticity before they are available
        for public viewing.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_p6vt0nji3qsc"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Indemnification
        and warranties</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>While we have made
        every attempt to ensure that the information contained on the Website is
        correct, Zion Church is not responsible for any errors or omissions, or for the
        results obtained from the use of this information. All information on the
        Website is provided &quot;as is&quot;, with no guarantee of completeness,
        accuracy, timeliness or of the results obtained from the use of this
        information, and without warranty of any kind, express or implied. In no event
        will Zion Church, or its partners, employees or agents, be liable to you or
        anyone else for any decision made or action taken in reliance on the
        information on the Website, or for any consequential, special or similar
        damages, even if advised of the possibility of such damages.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Furthermore, as with
        any business, your results may vary and will be based on your individual
        capacity, experience, expertise, and level of desire. There are no guarantees
        concerning the level of success you may experience. There is no guarantee that
        you will make any income at all and you accept the risk that the earnings and
        income statements differ by individual. Each individual’s success depends on
        his or her background, dedication, desire and motivation. The use of the
        information on the Website and Services should be based on your own due
        diligence and you agree that Zion Church is not liable for any success or
        failure of your business that is directly or indirectly related to the purchase
        and use of our information, products, and services reviewed or advertised on
        the Website. Information contained on the Website are subject to change at any
        time and without warning.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_11wco1pnt2l1"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Changes
        and amendments</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We reserve the right
        to modify this Disclaimer or its terms relating to the Website and Services at
        any time, effective upon posting of an updated version of this Disclaimer on the
        Website. When we do, we will revise the updated date at the bottom of this
        page. Continued use of the Website and Services after any such changes shall
        constitute your consent to such changes.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_yga8uwdeqpbv"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Acceptance
        of this disclaimer</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You acknowledge that
        you have read this Disclaimer and agree to all its terms and conditions. By
        accessing and using the Website and Services you agree to be bound by this
        Disclaimer. If you do not agree to abide by the terms of this Disclaimer, you
        are not authorized to access or use the Website and Services.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_6eie0q3f10zi"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Contacting
        us</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>If you would like to
        contact us to understand more about this Disclaimer or wish to contact us
        concerning any matter relating to it, you may send an email to
        support@everyhumanneeds.com.</span></p>
        
        <p class=MsoNormal><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>This document was last updated on April 26,
        2021</span></p>
        
        <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
        
        </div>`
    },
    dmca: {
        title: 'DMCA policy',
        browserTitle: 'DMCA policy',
        content: `<div class=WordSection1>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>This Digital
        Millennium Copyright Act policy (&quot;Policy&quot;) applies to the </span><span
        lang=EN><a href="https://www.everyhumaneeds.com/"><span style='font-size:12.0pt;
        line-height:115%;color:#111111;text-decoration:none'>everyhumaneeds.com</span></a></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>
        website (&quot;Website&quot; or &quot;Service&quot;) and any of its related
        products and services (collectively, &quot;Services&quot;) and outlines how
        Zion Church (&quot;Zion Church&quot;, &quot;we&quot;, &quot;us&quot; or
        &quot;our&quot;) addresses copyright infringement notifications and how you
        (&quot;you&quot; or &quot;your&quot;) may submit a copyright infringement
        complaint.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Protection of
        intellectual property is of utmost importance to us and we ask our users and
        their authorized agents to do the same. It is our policy to expeditiously
        respond to clear notifications of alleged copyright infringement that comply
        with the United States Digital Millennium Copyright Act (&quot;DMCA&quot;) of
        1998, the text of which can be found at the U.S. Copyright Office </span><span
        lang=EN><a href="https://www.copyright.gov/"><span style='font-size:12.0pt;
        line-height:115%;color:#111111;text-decoration:none'>website</span></a></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_xs7xbv8k8jht"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>What
        to consider before submitting a copyright complaint</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Before submitting a
        copyright complaint to us, consider whether the use could be considered fair
        use. Fair use states that brief excerpts of copyrighted material may, under
        certain circumstances, be quoted verbatim for purposes such as criticism, news
        reporting, teaching, and research, without the need for permission from or
        payment to the copyright holder.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Please note that if
        you are unsure whether the material you are reporting is in fact infringing,
        you may wish to contact an attorney before filing a notification with us.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>The DMCA requires you
        to provide your personal information in the copyright infringement
        notification. If you are concerned about the privacy of your personal
        information, you may wish to </span><span lang=EN><a
        href="https://www.copyrighted.com/professional-takedowns"><span
        style='font-size:12.0pt;line-height:115%;color:#111111;
        text-decoration:none'>hire an agent</span></a></span><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'> to
        report infringing material for you.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_ij8504a7k7dt"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Notifications
        of infringement</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>If you are a
        copyright owner or an agent thereof, and you believe that any material
        available on our Services infringes your copyrights, then you may submit a
        written copyright infringement notification (&quot;Notification&quot;) using
        the contact details below pursuant to the DMCA by providing us with the
        following information:</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Identification
        of the copyrighted work that you claim has been infringed, or, if multiple
        copyrighted works are covered by this Notification, you may provide a
        representative list of the copyrighted works that you claim have been
        infringed.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Identification
        of the infringing material and information you claim is infringing (or the
        subject of infringing activity), including at a minimum, if applicable, the URL
        or URLs of the web pages where the allegedly infringing material may be found.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Information
        reasonably sufficient to permit us to contact you, such as an address,
        telephone number, and, if available, an e-mail address.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>A
        statement that you have a good faith belief that use of the material in the
        manner complained of is not authorized by the copyright owner, the copyright
        owner's agent, or the law.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>A
        statement that the information in the notification is accurate, and under
        penalty of perjury that you are authorized to act on behalf of the owner of an
        exclusive right that is allegedly infringed.</span></p>
        
        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:22.0pt;
        margin-left:47.0pt;text-indent:-.25in'><span lang=EN style='font-size:12.0pt;
        line-height:115%;color:#111111'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>A physical or electronic signature (typing
        your full name will suffice) of the copyright owner or a person authorized to
        act on their behalf.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>All such
        Notifications must comply with the DMCA requirements. You may refer to a </span><span
        lang=EN><a href="https://www.websitepolicies.com/create/dmca-takedown-notice"><span
        style='font-size:12.0pt;line-height:115%;color:#111111;
        text-decoration:none'>DMCA takedown notice generator</span></a></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>
        or other similar services to avoid making mistake and ensure compliance of your
        Notification.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Filing a DMCA
        complaint is the start of a pre-defined legal process. Your complaint will be
        reviewed for accuracy, validity, and completeness. If your complaint has
        satisfied these requirements, our response may include the removal or
        restriction of access to allegedly infringing material.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>If we remove or
        restrict access to materials or terminate an account in response to a
        Notification of alleged infringement, we will make a good faith effort to
        contact the affected user with information concerning the removal or
        restriction of access.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Notwithstanding
        anything to the contrary contained in any portion of this Policy, Zion Church
        reserves the right to take no action upon receipt of a DMCA copyright
        infringement notification if it fails to comply with all the requirements of
        the DMCA for such notifications.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_zf4kjd3bfrby"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Changes
        and amendments</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We reserve the right
        to modify this Policy or its terms relating to the Website and Services at any
        time, effective upon posting of an updated version of this Policy on the
        Website. When we do, we will revise the updated date at the bottom of this
        page.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_qmevygbspfmw"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Reporting
        copyright infringement</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>If you would like to
        notify us of the infringing material or activity, you may send an email to
        support@everyhumanneeds.com.</span></p>
        
        <p class=MsoNormal><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>This document was last updated on April 26,
        2021</span></p>
        
        <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
        
        </div>`
    },
    acceptableUse: {
        title: 'Acceptable use policy',
        browserTitle: 'Acceptable use policy',
        content: `<div class=WordSection1>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>This acceptable use
        policy (&quot;Policy&quot;) sets forth the general guidelines and acceptable
        and prohibited uses of the </span><span lang=EN><a
        href="https://everyhumanneeds.com/"><span style='font-size:12.0pt;line-height:
        115%;color:#111111;text-decoration:none'>everyhumanneeds.com</span></a></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>
        website (&quot;Website&quot; or &quot;Service&quot;) and any of its related
        products and services (collectively, &quot;Services&quot;). This Policy is a
        legally binding agreement between you (&quot;User&quot;, &quot;you&quot; or
        &quot;your&quot;) and Zion Church (&quot;Zion Church&quot;, &quot;we&quot;,
        &quot;us&quot; or &quot;our&quot;). By accessing and using the Website and
        Services, you acknowledge that you have read, understood, and agree to be bound
        by the terms of this Agreement. If you are entering into this Agreement on
        behalf of a business or other legal entity, you represent that you have the authority
        to bind such entity to this Agreement, in which case the terms
        &quot;User&quot;, &quot;you&quot; or &quot;your&quot; shall refer to such
        entity. If you do not have such authority, or if you do not agree with the
        terms of this Agreement, you must not accept this Agreement and may not access
        and use the Website and Services. You acknowledge that this Agreement is a
        contract between you and Zion Church, even though it is electronic and is not
        physically signed by you, and it governs your use of the Website and Services.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_n6igytgfuhtv"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Prohibited
        activities and uses</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You may not use the
        Website and Services to publish content or engage in activity that is illegal
        under applicable law, that is harmful to others, or that would subject us to
        liability, including, without limitation, in connection with any of the
        following, each of which is prohibited under this Policy:</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Distributing
        malware or other malicious code.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Disclosing
        sensitive personal information about others.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Collecting,
        or attempting to collect, personal information about third parties without
        their knowledge or consent.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Distributing
        pornography or adult related content.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Promoting
        or facilitating prostitution or any escort services.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Hosting,
        distributing or linking to child pornography or content that is harmful to
        minors.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Promoting
        or facilitating gambling, violence, terrorist activities or selling weapons or
        ammunition.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Engaging
        in the unlawful distribution of controlled substances, drug contraband or
        prescription medications.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Managing
        payment aggregators or facilitators such as processing payments on behalf of
        other businesses or charities.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Facilitating
        pyramid schemes or other models intended to seek payments from public actors.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Threatening
        harm to persons or property or otherwise harassing behavior.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Infringing
        the intellectual property or other proprietary rights of others.</span></p>
        
        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:22.0pt;
        margin-left:47.0pt;text-indent:-.25in'><span lang=EN style='font-size:12.0pt;
        line-height:115%;color:#111111'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>Facilitating, aiding, or encouraging any of
        the above activities through the Website and Services.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_6n6sq1i1lny9"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>System
        abuse</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Any User in violation
        of the Website and Services security is subject to criminal and civil
        liability, as well as immediate account termination. Examples include, but are
        not limited to the following:</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Use
        or distribution of tools designed for compromising security of the Website and
        Services.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Intentionally
        or negligently transmitting files containing a computer virus or corrupted
        data.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Accessing
        another network without permission, including to probe or scan for
        vulnerabilities or breach security or authentication measures.</span></p>
        
        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:22.0pt;
        margin-left:47.0pt;text-indent:-.25in'><span lang=EN style='font-size:12.0pt;
        line-height:115%;color:#111111'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>Unauthorized scanning or monitoring of data
        on any network or system without proper authorization of the owner of the
        system or network.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_6jmkg9ir3dp5"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Service
        resources</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You may not consume
        excessive amounts of the resources of the Website and Services or use the
        Website and Services in any way which results in performance issues or which
        interrupts the Services for other Users. Prohibited activities that contribute
        to excessive use, include without limitation:</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Deliberate
        attempts to overload the Website and Services and broadcast attacks (i.e.
        denial of service attacks).</span></p>
        
        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:22.0pt;
        margin-left:47.0pt;text-indent:-.25in'><span lang=EN style='font-size:12.0pt;
        line-height:115%;color:#111111'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>Engaging in any other activities that degrade
        the usability and performance of the Website and Services.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_5bvghnd9acvi"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>No
        spam policy</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You may not use the
        Website and Services to send spam or bulk unsolicited messages. We maintain a
        zero tolerance policy for use of the Website and Services in any manner
        associated with the transmission, distribution or delivery of any bulk e-mail,
        including unsolicited bulk or unsolicited commercial e-mail, or the sending,
        assisting, or commissioning the transmission of commercial e-mail that does not
        comply with the U.S. CAN-SPAM Act of 2003 (&quot;SPAM&quot;).</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Your products or
        services advertised via SPAM (i.e. Spamvertised) may not be used in conjunction
        with the Website and Services. This provision includes, but is not limited to,
        SPAM sent via fax, phone, postal mail, email, instant messaging, or newsgroups.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_irgv6pghxaol"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Defamation
        and objectionable content</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We value the freedom
        of expression and encourage Users to be respectful with the content they post.
        We are not a publisher of User content and are not in a position to investigate
        the veracity of individual defamation claims or to determine whether certain
        material, which we may find objectionable, should be censored. However, we
        reserve the right to moderate, disable or remove any content to prevent harm to
        others or to us or the Website and Services, as determined in our sole
        discretion.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_w3lafdnoogqv"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Copyrighted
        content</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Copyrighted material
        must not be published via the Website and Services without the explicit permission
        of the copyright owner or a person explicitly authorized to give such
        permission by the copyright owner. Upon receipt of a claim for copyright
        infringement, or a notice of such violation, we may, at our discretion, run an
        investigation and, upon confirmation, may remove the infringing material from
        the Website and Services. We may terminate the Service of Users with repeated
        copyright infringements. Further procedures may be carried out if necessary. We
        will assume no liability to any User of the Website and Services for the
        removal of any such material. If you believe your copyright is being infringed
        by a person or persons using the Website and Services, please get in touch with
        us to report copyright infringement.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_3wnwpau065jn"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Security</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You take full
        responsibility for maintaining reasonable security precautions for your
        account. You are responsible for protecting and updating any login account
        provided to you for the Website and Services. You must protect the
        confidentiality of your login details, and you should change your password
        periodically.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_6hf3lhbcugyh"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Enforcement</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We reserve our right
        to be the sole arbiter in determining the seriousness of each infringement and
        to immediately take corrective actions, including but not limited to:</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Suspending
        or terminating your Service with or without notice upon any violation of this
        Policy. Any violations may also result in the immediate suspension or
        termination of your account.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Disabling
        or removing any content which is prohibited by this Policy, including to
        prevent harm to others or to us or the Website and Services, as determined by
        us in our sole discretion.</span></p>
        
        <p class=MsoNormal style='margin-left:47.0pt;text-indent:-.25in'><span lang=EN
        style='font-size:12.0pt;line-height:115%;color:#111111'>●<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang=EN style='font-size:12.0pt;line-height:115%;color:#111111'>Reporting
        violations to law enforcement as determined by us in our sole discretion.</span></p>
        
        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:22.0pt;
        margin-left:47.0pt;text-indent:-.25in'><span lang=EN style='font-size:12.0pt;
        line-height:115%;color:#111111'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>A failure to respond to an email from our
        abuse team within 2 days, or as otherwise specified in the communication to
        you, may result in the suspension or termination of your account.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Suspended and
        terminated User accounts due to violations will not be re-activated.</span></p>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>Nothing contained in
        this Policy shall be construed to limit our actions or remedies in any way with
        respect to any of the prohibited activities. We reserve the right to take any
        and all additional actions we may deem appropriate with respect to such
        activities, including without limitation taking action to recover the costs and
        expenses of identifying offenders and removing them from the Website and
        Services, and levying cancellation charges to cover our costs. In addition, we
        reserve at all times all rights and remedies available to us with respect to
        such activities at law or in equity.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_j6csbky7my60"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Reporting
        violations</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>If you have
        discovered and would like to report a violation of this Policy, please contact
        us immediately. We will investigate the situation and provide you with full
        assistance.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_77agk0ixfp"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Changes
        and amendments</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>We reserve the right
        to modify this Policy or its terms relating to the Website and Services at any
        time, effective upon posting of an updated version of this Policy on the
        Website. When we do, we will post a notification on the main page of the
        Website. Continued use of the Website and Services after any such changes shall
        constitute your consent to such changes.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_m5nf032nlwnh"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Acceptance
        of this policy</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>You acknowledge that
        you have read this Policy and agree to all its terms and conditions. By
        accessing and using the Website and Services you agree to be bound by this
        Policy. If you do not agree to abide by the terms of this Policy, you are not
        authorized to access or use the Website and Services.</span></p>
        
        <h2 style='margin-top:0in;margin-right:0in;margin-bottom:11.0pt;margin-left:
        0in;page-break-after:auto'><a name="_qawqb23j6rhy"></a><b><span lang=EN
        style='font-size:17.0pt;line-height:115%;color:#111111'>Contacting
        us</span></b></h2>
        
        <p class=MsoNormal style='margin-bottom:11.0pt'><span lang=EN style='font-size:
        12.0pt;line-height:115%;color:#111111'>If you would like to
        contact us to understand more about this Policy or wish to contact us
        concerning any matter relating to it, you may send an email to
        support@everyhumanneeds.com.</span></p>
        
        <p class=MsoNormal><span lang=EN style='font-size:12.0pt;line-height:115%;
        color:#111111'>This document was last updated on October 8,
        2020</span></p>
        
        <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
        
        </div>`
    }
}

export default POLICY_CONTENT;