/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import nav from "../../configs/nav.json";
import "./Sidebar.scss";
import { userType } from "../../configs/utility";
import { GlobalContext } from "../../context/GlobalContext";
import { v4 as uuidv4 } from "uuid";

function Sidebar({ showSidebar = true, setShowSidebarAction }) {
  const [menuItems, setMenuItems] = React.useState([]);
  const [isMobile, setIsMobile] = React.useState([]);

  const globalContext = React.useContext(GlobalContext);
  const location = useLocation();

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    })
  }, []);

  React.useEffect(() => {
    if (globalContext.user) {
      const _menuItems = nav[userType(globalContext.user.User.Role)];
      setMenuItems(_menuItems !== undefined ? _menuItems : []);
    }
  }, [location, globalContext.user]);

  return (
    <div
      onClick={() => {isMobile && setShowSidebarAction(!showSidebar)}}
      className={`sidebar-nav ${showSidebar ? "addSidebar" : ""}`}
    >
      {menuItems.length > 0 && (
        <ul className="profile-list sidebar-elements-container">
          {menuItems.map((item) => (
            <li key={uuidv4()}>
              <Link to={item.route}>{item.text}</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Sidebar;
