/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import "./AdminCampaigns.scss";
import { useHistory } from "react-router-dom";
import {
  API,
  AXIOS_METHOD_TYPES,
  CAMPAIGN_STATUS,
} from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import { convertQueryStringToObj, sortByName } from "../../../configs/utility";
import Meta from "../../common/meta/Meta";
import moment from "moment";

const AdminCampaigns = ( props ) => {

  const globalContext = useContext(GlobalContext);
  const [status, setStatus] = useState(
    parseInt(convertQueryStringToObj(props.location.search).status)
  );
  const [campaigns, setcampaigns] = useState([]);

  let history = useHistory();

  useEffect(() => {
    setcampaigns([]);
    let params = status === 0 ? { IsFeatured: true } : { Status: [status] };
    callRequesterAPI(params);
  }, [status]);

  const callRequesterAPI = (params) => {
    let serviceParams = {
      api: API.CAMPAIGNS,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          setcampaigns(result.Data ? sortByName(result.Data,true) : []);
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };

  return (
    <div className="container">
      <Meta title={"Admin-Campaigns"}  content={ "Admin campaigns" }/>
      { globalContext.user.User.Role === 2 ?  <div className="row">
        <div className="col-12">
          <div className="form-div">
            <div className="d-flex justify-content-between mb-5 ">
              <h3 className="dash-heading my-4">List of Campaigns</h3>
              <div className="row d-flex align-items-center mr-3" style={{alignSelf:'center'}}>
                <div className="col-sm-12 col-md-8 col-lg-8" style={{fontSize:12}}>
                  <select
                      value={ status }
                      className="from-control max-w-150 mr-3"
                      onChange={ ( e ) => {
                        e.preventDefault();
                        setStatus( parseInt( e.target.value ) );
                      } }
                  >
                    { CAMPAIGN_STATUS.map( ( item , index ) => (
                        <option value={ item.id } key={ index }>
                          { item.text }
                        </option>
                    ) ) }
                  </select>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <a
                      className="btn site-btn text-nowrap sm-mt-4 text-center"
                      onClick={ () => history.push( "/admin-create-campaign" ) }
                      style={ { color : "#fff" } }
                  >
                    Add New
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="client-table">
                  <div className="table-responsive">
                    <table className="table custom-table">
                      <thead>
                        <tr>
                          <th>Recipient Name</th>
                          <th>Last Action/Date</th>
                          <th>Campaign Name</th>
                          <th>Campaign Status</th>
                          <th>Ending date</th>
                          <th>Notes</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      { campaigns.map( ( {
                        IsFeatured ,
                        RequesterName ,
                        AddedByName ,
                        AddedOn ,
                        Name ,
                        StatusName ,
                        EndDate ,
                        Id
                      } , index ) => (
                          <tr key={ index } className={ `${ IsFeatured && "table-active" }` }>
                            <td>
                              <p>{ RequesterName }</p>
                            </td>
                            <td>
                              <p>{ `${
                                  AddedByName.split( " " )[ 0 ]
                              } - ${ moment( AddedOn ).format(
                                  "MM/DD/YYYY"
                              ) }` }</p>
                            </td>
                            <td className={ ""}>
                              <div className={"row" }>
                                <div className={"col"}>
                                  <p>{ Name }</p>
                                </div>
                                <div className={"col"}>
                                  { IsFeatured && <label
                                      className={ `no-wrap ${ IsFeatured && "feature-bg mr-4" }` }
                                  >
                                    { "Featured" }
                                  </label> }
                                </div>
                              </div>
                            </td>
                            <td>
                              <p>{ StatusName }</p>
                            </td>
                            <td>
                              <p>
                                { moment( EndDate ).format( "MM/DD/YYYY" ) }
                              </p>
                            </td>
                            <td>
                              <p/>
                            </td>
                            <td>
                              <div
                                  className="btn table-sitebtn no-wrap"
                                  onClick={ () =>
                                      history.push(
                                          `/admin-campaign-details/id/${ Id }`
                                      )
                                  }
                              >
                                View Details
                              </div>
                            </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
    </div> 
  );
}

export default withAPIRequest( AdminCampaigns );
