/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import "./RequesterSettings.scss";
import { useHistory } from "react-router-dom";
import Meta from "../../common/meta/Meta";
import { GlobalContext } from "../../../context/GlobalContext";


const RequesterSettings = (props) => {

  const globalContext = useContext(GlobalContext);
  const myUserRole = globalContext.user.User.Role;
  
  let history = useHistory();
  return (
    <div className="defult-home">
      <Meta title={ "Requester-Settings" } content={ "Requester settings" }/>
      { myUserRole === 4 ?<div>
      <div className="sidebar-nav">
        <ul className="profile-list">
          <li className="active">
            <a href="index.html"> Home </a>
          </li>
          <li>
            <a href="#"> Open Campaigns </a>
          </li>
          <li>
            <a href="#"> Needs Approval </a>
          </li>
          <li>
            <a href="#"> Requesters </a>
          </li>
          <li>
            <a href="#"> Donors </a>
          </li>
          <li>
            <a href="#"> Feature Stories </a>
          </li>
          <li>
            <a href="#"> Settings </a>
          </li>
        </ul>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="form-div">
              <div className="mb-5">
                <h3 className="dash-heading mb-0">Requester Settings</h3>
              </div>
              <div className="row">
                <div className="col-12">
                  <ul className="settings-list">
                    <li>
                      <div
                        className="hover"
                        onClick={() => history.push("/change-email")}
                      >
                        <div className="capabilities-div">
                          <div className="icon-div-holder">
                            <span className="icon-holder">
                              <i
                                className="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <h4 className="head-h6">Change Email</h4>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        className="hover"
                        onClick={() => history.push("/change-password")}
                      >
                        <div className="capabilities-div">
                          <div className="icon-div-holder">
                            <span className="icon-holder">
                              <i className="fa fa-lock" aria-hidden="true"></i>
                            </span>
                            <h4 className="head-h6">Change Password</h4>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                    <div
                      className="hover"
                      onClick={() => history.push("/change-personal-details")}
                    >
                      <div className="capabilities-div">
                        <div className="icon-div-holder">
                          <span className="icon-holder">
                            <i className="fa fa-user" aria-hidden="true"></i>
                          </span>
                          <h4 className="head-h6">Change Profile Image</h4>
                        </div>
                      </div>
                    </div>
                  </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
    </div>
  );
};

export default RequesterSettings;
