import React , { useContext , useEffect , useState } from "react";
import { API , AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { GlobalContext } from "../../../context/GlobalContext";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import moment from "moment";
import "./RequesterDonorSummary.scss";
import Meta from "../../common/meta/Meta";

const RequesterDonorSummary = ( props ) => {

    const [ donorSummary , SetDonorSummary ] = useState( [] );
    const globalContext = useContext( GlobalContext );
    const userRole = globalContext.user.User.Role

    useEffect( () => {
        if(userRole === 4){
            getDonors();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    } , [] );

    const getDonors = () => {
        let serviceParams = {
            api : `${ API.DONOR_REQUESTER_ALL_CAMPAIGN }14` ,
            method : AXIOS_METHOD_TYPES.GET ,
        };
        globalContext.setLoading( true );
        props.commonAPIRequest( serviceParams , ( result ) => {
            globalContext.setLoading( false );
            if ( result && result.Status.StatusId === 1 ) {
                SetDonorSummary( result.Data );
            } else {
                globalContext.setNotifications( {
                    type : "error" ,
                    text : result ? result.status.Message : "Data not found" ,
                } );
            }
        } );
    }

    const sendGreetings = ( donorId ) => {
        let serviceParams = {
            api : `${ API.REQUESTERS_GREETINGS }` ,
            method : AXIOS_METHOD_TYPES.POST ,
            params : {
                DonorId : donorId ,
                Title : "Thank You" ,
                Message : "Thank you for your donation"
            }
        };
        globalContext.setLoading( true );
        props.commonAPIRequest( serviceParams , ( result ) => {
            if ( result ) {
                const { Status : { Message , StatusId } } = result;
                if ( StatusId === 1 ) {
                    globalContext.setNotifications( {
                        type : "success" ,
                        text : Message ? Message : "Thank you for your donation" ,
                    } );
                    globalContext.setLoading( false );
                } else {
                    globalContext.setNotifications( {
                        type : "error" ,
                        text : result ? result.status.Message : "Data not found" ,
                    } );
                }
            }

        } );
    }

    return (
        <div className="defult-home">
            <Meta title={ "Requester-Donor-Summary" } content={ "Requester donor summary" }/>
            {userRole === 4 ?<div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="form-div">
                            <div className="d-flex justify-content-between mb-5">
                                <h3 className="dash-heading mb-0">Requester Donor Summary </h3>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="client-table">
                                        <div className="table-responsive">
                                            <table className="table custom-table">
                                                <thead>
                                                <tr>
                                                    <th>Donor Name</th>
                                                    <th>Date</th>
                                                    <th>Contribution</th>
                                                    <th/>
                                                    <th>Send Thank You</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                { donorSummary.map( ( {
                                                    AddedOn ,
                                                    FirstName ,
                                                    LastName ,
                                                    Id ,
                                                    CampaignResourcesName
                                                } , index ) => (
                                                    <tr key={ index }>
                                                        <td>
                                                            <p>{ `${ FirstName } ${ LastName }` }</p>
                                                        </td>
                                                        <td>
                                                            <p>{ moment( AddedOn ).format( "MM/DD/YYYY" ) }</p>
                                                        </td>
                                                        <td>
                                                            <p>{ CampaignResourcesName }</p>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td  className="hover">
                                                            <button className="btn table-sitebtn"
                                                                    onClick={ () => sendGreetings( Id ) }>
                                                                Send
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ) ) }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
        </div>
    );
}

export default withAPIRequest( RequesterDonorSummary );
