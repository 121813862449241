/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router";
import "./AdminCampaignDetails.scss";
import { API, AXIOS_METHOD_TYPES, RESOURCE_STATUS, TIPS } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Vimeo from "@u-wave/react-vimeo";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { timeSince, getCampaignShareableURL } from '../../../configs/utility';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import moment from 'moment';
import Meta from "../../common/meta/Meta";
import ProgressBar from '../../../components/progressBar/ProgressBar';

let isAccordionClose;
let currentAccordienID;
let timeoutTemp;

const AdminCampaignDetailsPage = (props) => {

  const globalContext = useContext(GlobalContext);
  const [campaignData, setCampaignData] = useState(null);
  const [donors, setDonors] = useState([]);
  const [files, setFiles] = useState([]);
  const { id } = useParams();
  const [chatList, setChatList] = useState(null);
  const [chatDataMsg, setChatDataMsg] = useState(null);
  const [chatMsg, setChatMsg] = useState("Loading...");
  const [chatUsers, setChatUsers] = useState(null);

  useEffect(() => {
    if(globalContext.user.User.Role === 2 ){
      getCampaignDetails(id);
      getChatList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, []);

  const getChatList = () => {
    let serviceParams = {
      api: `${API.GET_CHAT_LIST}${id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        setChatList(result.Data);
        return;
      }
      globalContext.setNotifications({
        type: "error",
        text: result ? result.Status.Message : "No data found",
      });
    });
  };

  const onAccordinChange = (event) => {
    if (event.length) {
      isAccordionClose = false;
      currentAccordienID = event[0]
      setChatDataMsg(null);
      getChatDataMsg();
    } else {
      clearInterval(timeoutTemp);
      isAccordionClose = true;
    }
  }

  const getCampaignDetails = (id) => {
    let serviceParams = {
      api: `${API.CAMPAIGN_DETAILS}${id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        setCampaignData(result.Data);
        setDonors(result.Data.Donors);
        setFiles(result.Data.OtherFiles);
        return;
      } else {
        globalContext.setNotifications({
          type: "error",
          text: result ? result.status.Message : "Data not found",
        });
      }
    });
  };

  const getChatDataMsg = () => {
    if (timeoutTemp) {
      clearInterval(timeoutTemp);
    }
    let currentURL = window.location.hash
    let found = currentURL.includes("#/admin-campaign-details");
    if (found && !isAccordionClose) {
      let serviceParams = {
        api: `${API.GET_CHAT_MESSAGES}${currentAccordienID}`,
        method: AXIOS_METHOD_TYPES.GET,
      };
      setChatMsg("Loading...")
      props.commonAPIRequest(serviceParams, (result) => {
        if (result && result.Status.StatusId === 1) {
          if (result.Data.ChatMessages.length === 0) {
            setChatMsg("No Message Found")
          }
          if (result.Data.ChatMessages.length && result.Data.ChatMessages[0].ChatId === currentAccordienID) {
            setChatUsers(result.Data.ChatUsers);
            setChatDataMsg(result.Data.ChatMessages);
            timeoutTemp = setInterval(() => {
              getChatDataMsg()
            }, 20000);
          }
          return;
        }
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "No data found",
        });
      });
    } else {
      return
    }
  }

  const updateResourceStatus = (params) => {
    let serviceParams = {
      api: API.CAMPAIGN_RESOURCE_STATUS_UPDATE,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        getCampaignDetails(id);
      } else {
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "Data not found",
        });
      }
    });
  };

  return (
    <div className="container campaign-details-container">
      <Meta title={"Admin-Campaign-Details"} content={"Admin campaign detail"} />
      { globalContext.user.User.Role === 2 ? campaignData && <div className="row">
        <div className="col-12">

          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">EveryHumanNeeds Use Tips</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div dangerouslySetInnerHTML={{ __html: TIPS }}></div>
                </div>

              </div>
            </div>
          </div>
          <div className="form-div details-page-holder">

            <div className="row mb-5">
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-3 mb-lg-0">
                <h3 className="dash-heading mb-0">{campaignData.Name} Details</h3>
              </div>

              <div className={"col-sm-12 col-md-7 col-lg-7 col-xl-7"}>
                <div className={"row ml-5"}>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex  p-0 mb-4 mb-lg-0">
                    <div className='row d-flex align-items-center w-100'>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="mr-5 no-wrap m-0"> <strong>Campaign Status:</strong> </p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <label className={`m-0 chips-box-btn no-wrap ${campaignData && campaignData.Status === 2 ? "grn-bg" : ""}`} > {campaignData && campaignData.StatusName} </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex mb-3 mb-lg-0 p-0">
                    <div className='row d-flex align-items-center'>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="mr-2 no-wrap m-0"> <strong>End date:</strong> </p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                        <p className="day-text font-weight-normal m-0"> {moment(new Date(campaignData.EndDate)).format('MM/DD/YYYY')}</p>
                      </div>
                    </div>
                  </div>

                  {/* <div class='col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex mb-3 mb-lg-0 p-0'>
                    <div className='row d-flex align-items-center'>
                      <p className="m-0 mr-2"><b>Tips:</b></p>
                      <button type="button" class="btn table-sitebtn no-wrap tips-btn " data-toggle="modal" data-target="#exampleModal"> <i className="fa fa-info" /> </button>
                    </div>
                  </div> */}
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-12 col-lg-3 col-md-4 text-center">
                <div className="details-img-holder">
                  <div className="details-img d-flex flex-column d-md-block">
                    <img
                      className="user img-profile"
                      src={
                        campaignData && campaignData.ImageLink
                          ? campaignData.ImageLink
                          : "assets/images/logo.png"
                      }
                      alt=""
                    />
                    <div className="mb-20 mt-10 ">
                      <div className={"row ml-5"}>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 px-0 d-flex align-items-center">
                          <p className="mb-2 mr-4">
                            <strong> Share Story </strong>
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 px-0 d-flex align-items-center mr-3">
                          <ul className="footer_social">
                            <li>
                              <FacebookShareButton url={getCampaignShareableURL(campaignData.Id)}
                                children={<i className="fa fa-facebook" />} />
                            </li>
                            <li>
                              <TwitterShareButton url={getCampaignShareableURL(campaignData.Id)}
                                children={<i className="fa fa-twitter" />} />
                            </li>
                          </ul>
                        </div>
                        <div className="table-responsive">
                      <table className="table custom-table mb-4">
                        <thead>
                          <tr>
                            <th scope="col">Summary</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                        {campaignData && campaignData.Resources ? (
                            campaignData.Resources.map((item, index) => {
                              // // debugger
                              return(
                              <tr key={item.Id}>
                                <td>  
                                  <ProgressBar  index={index} bgcolor={"black"} name={item.Name} totalNo={item.Quantity} pendingNo={item.PendingQuantity} isCash={item.IsCash} />
                                </td>
                              </tr>
                              )}))
                        :null}
                      
                        </tbody>
                      </table>
                </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-9 col-md-8 details-holder">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center flex-wrap mb-3">
                      <p className="mb-2 mr-4">
                        <h4 className="mt-4 mb-3">Recipient:</h4>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap">
                        <li>
                          {campaignData?.RequesterName}
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex align-items-center flex-wrap mb-3">
                      <p className="mb-2 mr-4">
                        <h4 className="mt-4 mb-3">Campaign Story:</h4>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap">
                        <li>
                          {campaignData?.Story}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-7 col-lg-12 col-xl-12">
                    <div className="video-hloder">
                      {campaignData && parseInt(campaignData.VideoLink) ? (
                        <Vimeo video={campaignData.VideoLink} width="450" />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-3 mt-3">
                    <hr />
                    <h4 className="mt-4 mb-3"> Resources Needed </h4>
                    <div className="client-details-table">
                      <div className="table-responsive">
                        <table className="table custom-table">
                          <thead>
                            <tr>
                              <th>Amount</th>
                              <th>Resource Name</th>
                              <th>Resource Details </th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaignData && campaignData.Resources ? (
                              campaignData.Resources.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <p>{item.IsCash && '$'}{item.PendingQuantity}</p>
                                  </td>
                                  <td>
                                    <p>{item.Name}</p>
                                  </td>
                                  <td>
                                    <p>{item.Description}</p>
                                  </td>
                                  <td>
                                    <div className="dropdown">
                                      <button
                                        className="btn chips-box dropdown-toggle col-12 mb-2 mt-2"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        {RESOURCE_STATUS.find((status) => status.id === item.Status).text || 'N/A'}
                                      </button>
                                      <div
                                        className="dropdown-menu dropdown-menu-right"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        {RESOURCE_STATUS.map((status, index) =>
                                          <button
                                            key={index}
                                            className="dropdown-item"
                                            onClick={() =>
                                              updateResourceStatus({
                                                Id: item.Id,
                                                Status: status.id
                                              })
                                            }
                                          > {status.text}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td />
                                <td>No Resources Found</td>
                                <td />
                                <td />
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {files && files.length ? <div className="col-12 col-md-7 col-lg-10 col-xl-10 my-4">
                    <hr />
                    <h4 className="mt-4 mb-3"> Files </h4>
                    {files
                      ? files.map((file, index) => (
                        <div
                          key={index}
                          className={
                            "file-list d-flex align-items-center justify-content-between"
                          }
                        >
                          <p className="m-0">{file ? file.Title : ""}</p>
                          <a
                            className="btn site-btn text-white"
                            href={file && file.FilePath}
                            target="_blank"
                            rel="noreferrer"
                            download={file ? file.Title : ""}
                          >
                            View
                              </a>
                        </div>
                      ))
                      : null}
                  </div> : null}
                  <div className="col-12 col-md-7 col-lg-12 col-xl-12 mt-4 mb-4">
                    <button
                      className="btn site-btn"
                      onClick={() => {
                        props.history.push(`/admin-edit-campaign/id/${id}`);
                      }}
                    >
                      Edit Campaign
                    </button>
                  </div>
                  <div className="col-12 col-sm-12 col-lg-10 col-xl-10 mb-3">
                    <div className="client-details-table">
                      <hr />
                      <h4 className="mt-4 mb-3">List of Donors</h4>
                      <div className="table-responsive">
                        <table className="table custom-table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Amount</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaignData && campaignData.Resources ? (
                              donors && donors.map(
                                (
                                  { FirstName, LastName, Email, ResourcesName,IsConfirmed },
                                  index
                                ) => (
                                  <tr key={index}>
                                    <td>
                                      <p>{FirstName + " " + LastName}</p>
                                    </td>
                                    <td>
                                      <p>{Email}</p>
                                    </td>
                                    <td>
                                      <p>{ResourcesName}</p>
                                    </td>
                                    <td>
                                    <p>{IsConfirmed?'Confirmed':'Pending'}</p>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td />
                                <td>No Donors Found</td>
                                <td />
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {chatList && chatList.length ? <div className="col-12 col-sm-12 col-lg-10 col-xl-10 mb-3">
                    <hr />
                    <div class='mt-4 d-flex justify-content-start align-items-center'>
                      <h4 className="m-0 mr-3"> Messages </h4>
                      <small><b>Tips:</b></small>
                      <button type="button" class="btn table-sitebtn no-wrap small-tip-btn ml-2" data-toggle="modal" data-target="#exampleModal">
                        <i className="fa fa-info" /> 
                      </button>
                    </div>
                    <Accordion allowZeroExpanded onChange={onAccordinChange}>
                      {chatList && chatList.map((item, index) => (
                        <AccordionItem key={index} uuid={item.Id}>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="msg-head">
                                <div className="media align-items-center">
                                  <div className="media-body" >
                                    <h4 className="my-0">{item.Name}</h4>
                                  </div>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel style={{ borderWidth: 1 }}  >
                            <div className="col-lg-12 col-md-7 col-sm-12 mesgs" >
                              {chatUsers && chatDataMsg && chatDataMsg.map((item) => {
                                return (
                                  <div className="msg_history">
                                    { chatUsers.find((user) => user.Id === item.SenderId).Role !== 4 ? <div className="incoming_msg">
                                      <div class="incoming_msg_img"> <img src={chatUsers.find((user) => user.Id === item.SenderId).ProfilePic} alt="profile" /> </div>
                                      <div className="received_msg">
                                        <div className="received_withd_msg">
                                          <p>
                                            <strong>{chatUsers.find((user) => user.Id === item.SenderId).FirstName} </strong>(Donor): {item.Message}
                                          </p>
                                          <span className="time_date"> {timeSince(new Date(item.SentOn.concat('Z')))}</span>
                                        </div>
                                      </div>
                                    </div> : null}
                                    { chatUsers.find((user) => user.Id === item.SenderId).Role === 4 ? <div className="outgoing_msg">
                                      <div className="sent_msg position-relative">
                                        <div className="sent_msg_img"> <img src={chatUsers.find((user) => user.Id === item.SenderId).ProfilePic} alt="profile" /> </div>
                                        <div className="sent-msg-ctnr">
                                          <p><strong>{chatUsers.find((user) => user.Id === item.SenderId).FirstName} </strong>(Requestor): {item.Message}</p>
                                          <span className="time_date"> {timeSince(new Date(item.SentOn.concat('Z')))}</span>
                                        </div>
                                      </div>
                                    </div> : null}
                                  </div>
                                )
                              }).reverse()}
                              {(!chatDataMsg) ? <div style={{
                                height: 100,
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center'
                              }}>{chatMsg}</div> : null} </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div> : null}
                  {campaignData && campaignData.Feedbacks && campaignData.Feedbacks.length ? <div className="col-12 col-md-12 col-lg-10 col-xl-10 mb-3 mt-5">
                    <div className="client-details-table">
                      <hr />
                      <h4 className="mt-4 mb-3">Feedback</h4>
                      <div className="table-responsive">
                        <table className="table custom-table">
                          <thead>
                            <tr>
                              <th>Feedback by</th>
                              <th>Feedback to</th>
                              <th>Feedback</th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaignData && campaignData.Resources ? (
                              campaignData.Feedbacks && campaignData.Feedbacks.map(
                                (
                                  { RequesterName, DonorName, FeedbackText, FeedbackBy },
                                  index
                                ) => (
                                  <tr key={index}>
                                    <td>
                                      <p>{FeedbackBy === 1 ? RequesterName : DonorName}</p>
                                    </td>
                                    <td>
                                      <p>{FeedbackBy === 1 ? DonorName : RequesterName}</p>
                                    </td>
                                    <td>
                                      <p>{FeedbackText}</p>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td />
                                <td>No Feedback Found</td>
                                <td />
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
    </div>
  );
}

export default withAPIRequest(AdminCampaignDetailsPage);
