/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Meta from "../../common/meta/Meta";
import { setCarousel,getLetterInitials } from "../../../configs/utility";
import { GlobalContext } from "../../../context/GlobalContext";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import "./Home.scss";

const Home = (props) => {
  const globalContext = useContext(GlobalContext);
  const [campaigns, setCampaigns] = useState([]);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setCarousel();
    getAllCampaings();
  }, []);

  const handleNewsletterSubs = (e) => {
    e.preventDefault();

    globalContext.setNotifications({
      type: "info",
      text: "Submitted successfully.",
    });

    setEmail("");
  };

  const getAllCampaings = () => {
    let serviceParams = {
      api: API.PUBLIC_LATEST_CAMPAIGNS,
      method: AXIOS_METHOD_TYPES.POST,
      params: { Status: [2, 0] },
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          setCampaigns(result.Data ? result.Data : []);
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };

  return (
    <div className="defult-home">
      <Meta title={"Home"} content={"Home page"} />
      <div className="main-content">
        <div className="rs-slider main-home">
          <div
            className="rs-carousel owl-carousel"
            data-loop="true"
            data-items="1"
            data-margin="0"
            data-autoplay="true"
            data-hoverpause="true"
            data-autoplay-timeout="5000"
            data-smart-speed="800"
            data-dots="false"
            data-nav="false"
            data-nav-speed="false"
            data-center-mode="false"
            data-mobile-device="1"
            data-mobile-device-nav="false"
            data-mobile-device-dots="false"
            data-ipad-device="1"
            data-ipad-device-nav="false"
            data-ipad-device-dots="false"
            data-ipad-device2="1"
            data-ipad-device-nav2="false"
            data-ipad-device-dots2="false"
            data-md-device="1"
            data-md-device-nav="true"
            data-md-device-dots="false"
          >
            <div className="slider-content slide1">
              <div className="container">
                <div className="content-part">
                  <div
                    className="wow bounceInLeft heading-container"
                    data-wow-delay="300ms"
                    data-wow-duration="2000ms"
                  >
                    empowering people to love{" "}
                  </div>
                  <h1
                    className="wow fadeInRight h1-style-2"
                    data-wow-delay="600ms"
                    data-wow-duration="2000ms"
                  >
                    their neighbour by providing everyday human needs.
                  </h1>
                  <div
                    className="sl-btn wow fadeInUp"
                    data-wow-delay="900ms"
                    data-wow-duration="2000ms"
                  >
                    <Link className="readon orange-btn main-home" to="/login">
                      Want To Give
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="rs-features"
            style={{ display: "none" }}
            className="rs-features main-home"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 sm-mb-30">
                  <div className="features-wrap">
                    <div className="icon-part">
                      <img src={"assets/images/features/icon/3.png"} alt="" />
                    </div>
                    <div className="content-part">
                      <h4 className="title">
                        <span className="watermark">5,320 online courses</span>
                      </h4>
                      <p className="dese">Enjoy a variety of fresh topics</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 sm-mb-30">
                  <div className="features-wrap">
                    <div className="icon-part">
                      <img src={"assets/images/features/icon/2.png"} alt="" />
                    </div>
                    <div className="content-part">
                      <h4 className="title">
                        <span className="watermark">Expert instruction</span>
                      </h4>
                      <p className="dese">Find the right instructor</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="features-wrap">
                    <div className="icon-part">
                      <img src={"assets/images/features/icon/1.png"} alt="" />
                    </div>
                    <div className="content-part">
                      <h4 className="title">
                        <span className="watermark">Lifetime access</span>
                      </h4>
                      <p className="dese">Learn on your schedule</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="aboutUsSection"
          style={{ backgroundColor: "#ebebeb" }}
          className="rs-about style1 pt-100 pb-100 md-pt-70 md-pb-70"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-last padding-0 md-pl-15 md-pr-15 md-mb-30">
0                <div className="img-part">
                  <img
                    className=""
                    src={"assets/images/about2orange.png"}
                    alt="About Image"
                  />                                                           
                </div>
              </div>
              <div className="col-lg-6 pr-70 md-pr-15" id="rs-about">
                <div className="sec-title">
                  <h2 className="title mb-17">About us</h2>
                  <h2 className="title mb-17">What is Every Human Needs?</h2>
                  <div className="desc">
                    Every Human Needs (EHN) is a platform that connects
                    generosity with those who need a little grace and resources.
                    Being founded by the belief that people matter to God, EHN
                    seeks to provide encouragement by meeting the everyday human
                    needs of love, care and support through tangible items and
                    acts of service. There are men and women all around the
                    world who are generous at their core and in times of crisis
                    are often looking for ways to help serve the basic human
                    needs of their community. If that is you, join us by{" "}
                    <Link className="" to="/donor-register">
                      Clicking here{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="rs-categories"
          className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-40"
        >
          <div className="container">
            <div
              id="homeFeaturedStories"
              className="sec-title3 text-center mb-45"
            >
              <div className="sub-title"> Our Campaigns</div>
              <h2 className="title black-color">Featured Stories </h2>
            </div>
            <div className="row mb-35">
              {globalContext.featuredCampaings.map((campaign, index) => (
                <div key={index} className="col-lg-4 col-md-6 mb-30">
                  <div className="categories-items">
                    <div className="cate-images">
                      <a href="#">
                      {campaign.IsPrivacy?
                       <div className="featured-image featured-image-text"><h2 class="title black-color text-light">{getLetterInitials(campaign.RequesterName)}</h2></div>:
                        <img                                                                               
                          className={"featured-image"}
                          src={
                            campaign.ImageLink
                              ? campaign.ImageLink
                              : "assets/images/logo.png"
                          }
                          alt="default-image"
                        />}
                      </a>
                    </div>
                    <div className="contents">
                      <div className="content-wrap">
                        <h2 className="title">
                          <Link to={`/campaign-details/${campaign.Id}`}>
                            {campaign.Name}
                          </Link>
                        </h2>
                        <Link
                          className="course-qnty"
                          to={`/campaign-details/${campaign.Id}`}
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-12 text-center">
              <HashLink
                className="readon orange-btn main-home"
                to="/#latestCampaigns"
              >
                View All Campaigns{" "}
              </HashLink>
            </div>
          </div>
        </div>
        <div
          id="rs-team"
          style={{ backgroundColor: "#fff6ed" }}
          className="video-carousel rs-team style1 orange-color pt-94 pb-100 md-pt-64 md-pb-70 "
        >
          <div className="container">
            <div className="sec-title mb-50 md-mb-30">
              <div className="sub-title orange">Every Human Needs</div>
              <h2 className="title mb-0">A Little Encouragement</h2>
            </div>
            <div
              className="rs-carousel owl-carousel nav-style2 orange-color"
              data-loop="true"
              data-items="3"
              data-margin="30"
              data-autoplay="true"
              data-hoverpause="true"
              data-autoplay-timeout="5000"
              data-smart-speed="800"
              data-dots="false"
              data-nav="true"
              data-nav-speed="false"
              data-center-mode="false"
              data-mobile-device="1"
              data-mobile-device-nav="true"
              data-mobile-device-dots="false"
              data-ipad-device="1"
              data-ipad-device-nav="true"
              data-ipad-device-dots="false"
              data-ipad-device2="1"
              data-ipad-device-nav2="true"
              data-ipad-device-dots2="false"
              data-md-device="1"
              data-md-device-nav="true"
              data-md-device-dots="false"
            >
              <div className="team-item">
                <iframe
                  width="100%"
                  height="615"
                  // src="https://vimeo.com/555430079"
                  src="https://player.vimeo.com/video/555430079"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="team-item">
                <iframe
                  width="100%"
                  height="615"
                  // src="https://vimeo.com/555432850"
                  src="https://player.vimeo.com/video/555432850"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="team-item">
                <iframe
                  width="100%"
                  height="615"
                  // src="https://vimeo.com/555435043"
                  src="https://player.vimeo.com/video/555435043"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="team-item">
                <iframe
                  width="100%"
                  height="615"
                  // src="https://vimeo.com/555436310"
                  src="https://player.vimeo.com/video/555436310"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="team-item">
                <iframe
                  width="100%"
                  height="615"
                  // src="https://vimeo.com/555437003"
                  src="https://player.vimeo.com/video/555437003"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="team-item">
                <iframe
                  width="100%"
                  height="615"
                  // src="https://vimeo.com/555437896"
                  src="https://player.vimeo.com/video/555437896"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="rs-cart allCampaignsTable orange-color pt-100 pb-100 md-pt-70 md-pb-70"
          style={{ backgroundColor: "#f9f9f9" }}
        >
          <div className="container">
            <div id="latestCampaigns" className="sec-title3 text-center mb-50">
              <h2 className="title">Latest Campaigns</h2>
            </div>
            <div className="cart-wrap">
              <table className="cart-table">
                <thead>
                  <tr className="table-header">
                    <th className="product-name">Name </th>
                    <th />
                    <th className="product-price">End date</th>
                  </tr>
                </thead>
                <tbody>
                  {campaigns.map((campaign, index) => (
                    <tr
                      key={index}
                      className={`${campaign.IsFeatured && "table-active"}`}
                    >
                      <td className="product-name">
                        <Link to={`/campaign-details/${campaign.Id}`}>
                          {campaign.Name}
                        </Link>
                      </td>
                      <td>
                        {campaign.IsFeatured && (
                          <label className="no-wrap feature-bg ml-3">
                            Featured
                          </label>
                        )}
                      </td>
                      <td className="product-price">
                        {moment(campaign.EndDate).format("MM/DD/YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="rs-newsletter style1 orange-color mb-90 sm-mb-0 sm-pb-70">
          <div className="container">
            <div className="newsletter-wrap">
              <div className="row y-middle">
                <div className="col-lg-6 col-md-12 md-mb-30">
                  <div className="content-part">
                    <div className="sec-title">
                      <div className="title-icon md-mb-15">
                        <img src={"assets/images/newsletter.png"} alt="" />
                      </div>
                      <h2 className="title mb-0 white-color">
                        Subscribe to Newsletter
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <iframe src="https://zionchurch.us4.list-manage.com/subscribe/post?u=628cbb966d112599f033815de&amp;id=f67c20eb45" width={'100%'} height={'576px'} frameborder="0"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAPIRequest(Home);
