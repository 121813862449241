/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import { withAPIRequest } from "../../components/HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../configs/constants";
import {setHomepagePublicMenuMobile} from '../../configs/utility';
import { GlobalContext } from "../../context/GlobalContext";
import { HashLink } from "react-router-hash-link";
import { useState } from "react";

function Header(props) {
  const globalContext = useContext(GlobalContext);

  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    setHomepagePublicMenuMobile();
    callRequesterAPI({ PageSize: 6 });
  }, []);

  const callRequesterAPI = (params) => {
    let serviceParams = {
      api: API.PUBLIC_FEATURED_CAMPAIGNS,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          globalContext.setFeaturedCampaings(result.Data ? result.Data : []);
        }
      }
    }, true);
  };

  const handleClickBurgurIcon = (e) => {
    e.preventDefault();
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="full-width-header home8-style4 main-home">
      {/*Header Start*/}
      <header id="rs-header" className="rs-header">
        {/* Menu Start */}
        <div className={`menu-area menu-sticky ${props.isSticky && "sticky"}`}>
          <div className="container">
            <div className="row y-right">
              
              <div className="col-lg-3">
                <div className="logo-cat-wrap">
                  <div className="logo-part">
                  {(
                    <div className="my-prof burger" onClick={handleClickBurgurIcon}>
                      {" "}
                      {!showSidebar ? (
                        <a className="rs-menu-toggle">
                          ☰
                        </a>
                      ) : (
                        <a className="rs-menu-toggle">
                          <div className="fa fa-close"></div>
                        </a>
                      )}{" "}
                    </div>
                  )}
                  <div className="logos-div">
                    <Link to="/">
                  
                      <img
                        className="normal-logo logo-margin-ehu"
                        src="/assets/images/logo.png"
                        alt=""
                      />
                      
                      <img
                        className="sticky-logo logo-margin-ehu"
                        src="/assets/images/logo.png"
                        alt=""
                      />
                    </Link>
                    <a href="https://zionchurch.org">
                      <img
                          className="normal-logo logo-margin-zion"
                          src="/assets/images/zion_logo.png"
                          alt=""
                        />
                  
                        <img
                        className="sticky-logo logo-margin-zion"
                        src="/assets/images/zion_logo.png"
                        alt=""
                      />
                    </a>
                    </div>
                  </div>
                </div>
              </div>
             
              <div className="col-lg-9 text-right">
                <div className="rs-menu-area top-sec-wrap">
                  <div className="main-menu">
                    <div className="mobile-menu">
                      <a className="rs-menu-toggle">
                        <i className="fa fa-bars" />
                      </a>
                    </div>
                    <nav className="rs-menu rs-menu-close">
                      <ul className="nav-menu">
                        <li className="">
                          <Link style={{ color: "red" }} to="/">
                            Home
                          </Link>
                        </li>
                        <li className="">
                          {/* <Link onClick={aboutUsTap}>About us</Link> */}
                          <HashLink to={"/#aboutUsSection"}>About us</HashLink>
                        </li>
                        <li className="menu-item-has-children">
                          <HashLink to="/#homeFeaturedStories">
                            Featured Stories{" "}
                          </HashLink>
                          <ul className="sub-menu">
                            { globalContext.featuredCampaings.map( ( campaign , index ) => (
                                <li key={ index }>
                                  <Link to={`/campaign-details/${campaign.Id}`}>
                                    {campaign.Name}
                                  </Link>
                                </li>
                            ))}
                          </ul>
                        </li>
                        <li className="">
                          <Link to="/login">Login</Link>
                        </li>
                      </ul>{" "}
                      {/* //.nav-menu */}
                    </nav>
                  </div>{" "}
                  {/* //.main-menu */}
                  <div
                    className="sl-btn wow fadeInUp d-none d-lg-block"
                    data-wow-delay="900ms"
                    data-wow-duration="2000ms"
                  >
                    <Link className="readon orange-btn main-home" to="/login">
                      Want To Give
                    </Link>
                  </div>
                </div>
              </div>
            
            {/*  <div className="col-lg-2 text-right"></div>*/}
            </div>
          </div>
        </div>
        {/* Menu End */}
      </header>
      {/*Header End*/}
    </div>
  );
}

export default withAPIRequest(Header);
