/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import "./ChangeEmail.scss";
import { useHistory } from "react-router-dom";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const ChangeEmail = (props) => {
  const globalContext = useContext(GlobalContext);
  let history = useHistory();

  const changeEmailHandler = async (params) => {
    let serviceParams = {
      api: API.CHANGE_EMAIL,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    await globalContext.setLoading(true);
    await props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        localStorage.setItem("user", null);
        globalContext.setLoggedInUser(null);
        globalContext.setNotifications({
          id: Math.random(),
          type: "success",
          text: "Your Email is updated.",
        });
        history.push("/login");
      } else {
        globalContext.setNotifications({
          id: Math.random(),
          type: "error",
          text: result ? result.Status.Message : "Error while updating email",
        });
      }
    });
  };

  return (
    <div className="container">
      <Meta title={ "Chane-Email" } content={ "Change email" }/>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
          <div className="form-div ">
            <div className="d-flex mb-5">
              <h2 className="title">Change Email</h2>
            </div>
            <Formik
              initialValues={{
                Email: "",
                Password: "",
              }}
              validationSchema={Yup.object().shape({
                Email: Yup.string()
                  .email("Invalid email address")
                  .required("Required"),
                Password: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                changeEmailHandler(values);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <div className="row">
                    <div className="col-12 mb-20">
                      <input
                        className="from-control"
                        type="email"
                        placeholder="New Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Email}
                        id="Email"
                        name="Email"
                        required=""
                      />
                      {errors.Email && touched.Email && errors.Email}
                    </div>

                    <div className="col-12 col-lg-12 mb-35">
                      <input
                        className="from-control"
                        type="password"
                        placeholder="Current Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Password}
                        id="Password"
                        name="Password"
                        required=""
                      />
                      {errors.Password && touched.Password && errors.Password}
                    </div>
                    <div className="col-12 col-lg-12 mb-20">
                      <div className="d-flex justify-content-between">
                        <input
                          className="btn-send mr-3"
                          type="submit"
                          value="Submit"
                        />
                        <div
                          className="btn btn-send "
                          onClick={() => history.goBack()}
                        >
                          Back
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAPIRequest(ChangeEmail);
