/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Chat.scss";
import Meta from "../../common/meta/Meta";

const Chat = ( props ) => {

  return (
    <div className="container">
      <Meta title={ "Chat" } content={ "Chat" }/>
      <div className="row">
        <div className="col-12">
          <div className="form-div details-page-holder  pt-30">
            <div className="d-flex justify-content-between mb-5 ">
              <h3 className="dash-heading mb-0">Recipient Campaign Details</h3>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="messaging">
                  <div className="inbox_msg">
                    <div className="col-lg-4 col-md-5 col-sm-12 inbox_people">
                      <div className="headind_srch">
                        <div className="recent_heading">
                          <h4>Chat List</h4>
                        </div>
                      </div>
                      <div className="inbox_chat">
                        <div className="chat_list active_chat">
                          <div className="chat_people">
                            <div className="chat_img">
                              {" "}
                              <img
                                src="assets/images/user-img.png"
                                alt="sunil"
                              />{" "}
                            </div>
                            <div className="chat_ib">
                              <h5>
                                Alex <span className="chat_date">Dec 25</span>
                              </h5>
                              <p>
                                Test, which is a new approach to have all
                                solutions astrology under one roof.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="chat_list">
                          <div className="chat_people">
                            <div className="chat_img">
                              {" "}
                              <img
                                src="assets/images/user-img.png"
                                alt="sunil"
                              />{" "}
                            </div>
                            <div className="chat_ib">
                              <h5>
                                Sandra <span className="chat_date">Dec 25</span>
                              </h5>
                              <p>
                                Test, which is a new approach to have all
                                solutions astrology under one roof.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="chat_list">
                          <div className="chat_people">
                            <div className="chat_img">
                              {" "}
                              <img
                                src="assets/images/user-img.png"
                                alt="sunil"
                              />{" "}
                            </div>
                            <div className="chat_ib">
                              <h5>
                                Lisa <span className="chat_date">Dec 25</span>
                              </h5>
                              <p>
                                Test, which is a new approach to have all
                                solutions astrology under one roof.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="chat_list">
                          <div className="chat_people">
                            <div className="chat_img">
                              {" "}
                              <img
                                src="assets/images/user-img.png"
                                alt="sunil"
                              />{" "}
                            </div>
                            <div className="chat_ib">
                              <h5>
                                Group Text{" "}
                                <span className="chat_date">Dec 25</span>
                              </h5>
                              <p>
                                Test, which is a new approach to have all
                                solutions astrology under one roof.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="chat_list">
                          <div className="chat_people">
                            <div className="chat_img">
                              {" "}
                              <img
                                src="assets/images/user-img.png"
                                alt="sunil"
                              />{" "}
                            </div>
                            <div className="chat_ib">
                              <h5>
                                Jennifer{" "}
                                <span className="chat_date">Dec 25</span>
                              </h5>
                              <p>
                                Test, which is a new approach to have all
                                solutions astrology under one roof.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="chat_list">
                          <div className="chat_people">
                            <div className="chat_img">
                              {" "}
                              <img
                                src="assets/images/user-img.png"
                                alt="sunil"
                              />{" "}
                            </div>
                            <div className="chat_ib">
                              <h5>
                                Travis <span className="chat_date">Dec 25</span>
                              </h5>
                              <p>
                                Test, which is a new approach to have all
                                solutions astrology under one roof.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="chat_list">
                          <div className="chat_people">
                            <div className="chat_img">
                              {" "}
                              <img
                                src="assets/images/user-img.png"
                                alt="sunil"
                              />{" "}
                            </div>
                            <div className="chat_ib">
                              <h5>
                                Lisa <span className="chat_date">Dec 25</span>
                              </h5>
                              <p>
                                Test, which is a new approach to have all
                                solutions astrology under one roof.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-sm-12 mesgs">
                      <div className="msg-head">
                        <div className="media align-items-center">
                          <div className="media-body">
                            <h4 className="my-0">Title here</h4>
                          </div>
                          <div className="dropdown">
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></i>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a className="dropdown-item" href="#">
                                Block
                              </a>
                              <a className="dropdown-item" href="">
                                Report
                              </a>
                              <a className="dropdown-item" href="">
                                Closed
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="msg_history">
                        <div className="incoming_msg">
                          <div className="incoming_msg_img">
                            {" "}
                            <img
                              src="assets/images/user-img.png"
                              alt="sunil"
                            />{" "}
                          </div>
                          <div className="received_msg">
                            <div className="received_withd_msg">
                              <p>
                                Test which is a new approach to have all
                                solutions
                              </p>
                              <span className="time_date">
                                {" "}
                                11:01 AM | June 9
                              </span>
                              <div className="ellipsis-div">
                                <div className="dropdown">
                                  <i
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  ></i>
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a className="dropdown-item" href="#">
                                      Delete
                                    </a>
                                    <a className="dropdown-item" href="">
                                      Edit
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="outgoing_msg">
                          <div className="sent_msg">
                            <p>
                              Test which is a new approach to have all solutions
                            </p>
                            <span className="time_date">
                              {" "}
                              11:01 AM | June 9
                            </span>
                          </div>
                        </div>
                        <div className="incoming_msg">
                          <div className="incoming_msg_img">
                            {" "}
                            <img
                              src="assets/images/user-img.png"
                              alt="sunil"
                            />{" "}
                          </div>
                          <div className="received_msg">
                            <div className="received_withd_msg">
                              <p>Test, which is a new approach to have</p>
                              <span className="time_date">
                                {" "}
                                11:01 AM | Yesterday
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="outgoing_msg">
                          <div className="sent_msg">
                            <p>Apollo University, Delhi, India Test</p>
                            <span className="time_date"> 11:01 AM | Today</span>
                          </div>
                        </div>
                        <div className="incoming_msg">
                          <div className="incoming_msg_img">
                            {" "}
                            <img
                              src="assets/images/user-img.png"
                              alt="sunil"
                            />{" "}
                          </div>
                          <div className="received_msg">
                            <div className="received_withd_msg">
                              <p>
                                We work directly with our designers and
                                suppliers, and sell direct to you, which means
                                quality, exclusive products, at a price anyone
                                can afford.
                              </p>
                              <span className="time_date">
                                {" "}
                                11:01 AM | Today
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="type_msg">
                        <div className="input_msg_write">
                          <input
                            type="text"
                            className="write_msg"
                            placeholder="Type a message"
                          />
                          <button className="msg_send_btn" type="button">
                            <i
                              className="fa fa-paper-plane-o"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
