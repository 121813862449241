/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES, RESOURCE_STATUS, TIPS } from "../../../configs/constants";
import { GlobalContext } from "../../../context/GlobalContext";
import "./RequesterCampaignDetails.scss";
import Vimeo from "@u-wave/react-vimeo";
import { Form, Formik } from "formik";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { timeSince, getCampaignShareableURL } from '../../../configs/utility';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import Meta from "../../common/meta/Meta";
import moment from 'moment';
import * as Yup from "yup";

let isAccordionClose;
let currentAccordienID;
let timeoutTemp;

const RequesterCampaignDetails = (props) => {

  const [campaignDetails, setCampaignDetails] = useState({});
  const globalContext = useContext(GlobalContext);
  const [files, setFiles] = useState([]);
  const [donors, setDonors] = useState([]);
  const { id } = useParams();
  const [chatList, setChatList] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [chatDataMsg, setChatDataMsg] = useState(null);
  const [sendMsgText, setSendMsgText] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [chatMsg, setChatMsg] = useState("Loading...");
  const myUserID = globalContext.user.User.Id;
  const [chatUsers, setChatUsers] = useState(null);
  const userRole = globalContext.user.User.Role
  useEffect(() => {
    (async () => await getCampaignDetails(id))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAccordinChange = (event) => {
    if (event.length && currentId !== event[0]) {
      isAccordionClose = false;
      setCurrentId(event[0]);
      currentAccordienID = event[0]
      setChatDataMsg(null);
      getChatDataMsg();
      setSendMsgText('');
    } else {
      clearInterval(timeoutTemp);
      isAccordionClose = true;
      setCurrentId([]);
    }
  }

  const getChatList = () => {
    let serviceParams = {
      api: `${API.GET_CHAT_LIST}${id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        setChatList(result.Data);
        setChatId(result.Data[0]?.Id);
        result.Data[0] && onAccordinChange([result.Data[0]?.Id])

         // CODE TO OPEN THE FIRST CHAT BY DEFAULT
         let _currentId = result.Data.length ? result.Data[0].Id : null;
         if (_currentId) {
           setCurrentId(_currentId);
           currentAccordienID = _currentId;
           setChatDataMsg(null);
           getChatDataMsg();
           setSendMsgText('');
         }
        return;
      }
      globalContext.setNotifications({
        type: "error",
        text: result ? result.Status.Message : "No data found",
      });
    });
  };

  const getChatDataMsg = () => {
    if (timeoutTemp) {
      clearInterval(timeoutTemp);
    }
    let currentURL = window.location.hash
    let found = currentURL.includes("#/requester-campaign-details");
    if (found && !isAccordionClose) {
      let serviceParams = {
        api: `${API.GET_CHAT_MESSAGES}${currentAccordienID}`,
        method: AXIOS_METHOD_TYPES.GET,
      };
      setChatMsg("Loading...")
      props.commonAPIRequest(serviceParams, (result) => {
        if (result && result.Status.StatusId === 1) {
          if (result.Data.ChatMessages.length === 0) {
            setChatMsg("No Message Found")
          }
          if (result.Data.ChatMessages.length && result.Data.ChatMessages[0].ChatId === currentAccordienID) {
            setChatUsers(result.Data.ChatUsers);
            setChatDataMsg(result.Data.ChatMessages);
            timeoutTemp = setInterval(() => {
              getChatDataMsg()
            }, 20000);
          }
          return;
        }
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "No data found",
        });
      });
    } else {
      return
    }
  }

  const onSendBtnTap = () => {
    if (sendMsgText && currentId) {
      let serviceParams = {
        api: API.NEW_MESSAGE,
        method: AXIOS_METHOD_TYPES.POST,
        params: {
          "ChatId": currentId,
          "Message": sendMsgText
        },
      };

      globalContext.setLoading(true);
      props.commonAPIRequest(serviceParams, (result) => {
        globalContext.setLoading(false);
        if (result && result.Status.StatusId === 1) {
          getChatDataMsg()
          setSendMsgText('');
          return;
        }
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "No data found",
        });
      });
    }
  }

  const sendFeedbackMessage = (feedback) => {
    delete feedback.CreatedOn;
    delete feedback.Id;
    delete feedback.$id;
    delete feedback.Name;
    feedback['UserId'] = myUserID
    feedback['RequesterId'] = campaignDetails.RequesterId;
    let serviceParams = {
      api: API.SEND_FEEDBACK_MESSAGE,
      method: AXIOS_METHOD_TYPES.POST,
      params: feedback
    };
    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        hideModal();
        globalContext.setNotifications({
          type: "success",
          text: result ? result.Status.Message : "Feedback sent successfully.",
        });
      } else {
        hideModal();
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "Data not found",
        });
      }
    });
  }

  const hideModal = () => {
    document.getElementById("exampleModal").style.display = "none";
    document.getElementsByClassName("modal-backdrop")[0].style.display = "none";
  }

  const onTextChange = (event) => {
    setSendMsgText(event.target.value);
  }

  const getCampaignDetails = async (id) => {
    let serviceParams = {
      api: `${API.CAMPAIGN_DETAILS}${id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };

    await globalContext.setLoading(true);
    await props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        result.Data.Resources.map((item) => { item.selectedQuantity = item.Quantity; return null; });
        setCampaignDetails(result.Data);
        setDonors(result.Data.Donors);
        setFiles(result.Data.OtherFiles);
        getChatList();
      } else {
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "Data not found",
        });
      }
    });
  };

  const updateResourceStatus = (params) => {
    let serviceParams = {
      api: API.CAMPAIGN_RESOURCE_STATUS_UPDATE,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        (async () => await getCampaignDetails(id))();
      } else {
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "Error while updating",
        });
      }
    });
  };

  const handleQuantityChange = (quantity, index, resourceId) => {
    let _campaignData = JSON.parse(JSON.stringify(campaignDetails));
    _campaignData.Resources[index].selectedQuantity = parseInt(quantity);
    setCampaignDetails(_campaignData);

    let serviceParams = {
      api: API.UPDATE_PENDING_QUANTITY,
      method: AXIOS_METHOD_TYPES.POST,
      params: {
        "Id": resourceId,
        "PendingQuantity": parseInt(quantity)
      },
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        // (async ()=> await getCampaignDetails(id))();
      }
    });
  }

  return (
    <div className="defult-home">
      <Meta title={"Recipient-Campaign-Details"} content={"Recipient campaign details"} />
      {userRole === 4 ?<div>
      <div className="container campaign-details-container">
        {campaignDetails && <div className="row">
          <div className="col-12">

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">EveryHumanNeeds Use Tips</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div dangerouslySetInnerHTML={{ __html: TIPS }}></div>
                  </div>

                </div>
              </div>
            </div>
            <div className="form-div details-page-holder">
              <div className="row mb-5">

                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-3 mb-lg-0">
                  <h3 className="dash-heading mb-0">{campaignDetails.Name} Details</h3>
                </div>

                <div className={"col-sm-12 col-md-7 col-lg-7 col-xl-7"}>
                  <div className={"row ml-5"}>
                    {/* Campaign Status */}
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex  p-0 mb-4 mb-lg-0">
                      <div className='row d-flex align-items-center w-100'>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                          <p className="mr-5 no-wrap m-0"> <strong>Campaign Status:</strong> </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                          <label className={`m-0 chips-box-btn no-wrap ${campaignDetails && campaignDetails.Status === 2 ? "grn-bg" : ""}`} > {campaignDetails && campaignDetails.StatusName} </label>
                        </div>
                      </div>
                    </div>

                    {/* Campaign End Date */}
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex mb-3 mb-lg-0 p-0">
                      <div className='row d-flex align-items-center'>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                          <p className="mr-2 no-wrap m-0"> <strong>End date:</strong> </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                          <p className="day-text font-weight-normal m-0"> {moment(new Date(campaignDetails.EndDate)).format('MM/DD/YYYY')}</p>
                        </div>
                      </div>
                    </div>

                    {/* Tips */}
                    {/* <div class='col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex mb-3 mb-lg-0 p-0'>
                      <div className='row d-flex align-items-center'>
                        <p className="m-0 mr-2"><b>Tips:</b></p>
                        <button type="button" class="btn table-sitebtn no-wrap tips-btn " data-toggle="modal" data-target="#exampleModal"> <i className="fa fa-info" /> </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-3 col-md-4 text-center">
                  <div className="details-img-holder">
                    <div className="details-img d-flex flex-column d-md-block">
                      <img
                        className="user img-profile"
                        src={
                          campaignDetails && campaignDetails.ImageLink
                            ? campaignDetails.ImageLink
                            : "assets/images/logo.png"
                        }
                        alt=""
                      />
                      <div className="mb-20 mt-10 ">
                        <div className={"row ml-5"}>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 px-0 d-flex align-items-center">
                            <p className="mb-2 mr-4">
                              <strong> Share Story </strong>
                            </p>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 px-0 d-flex align-items-center mr-3">
                            <ul className="footer_social">
                              <li>
                                <FacebookShareButton url={getCampaignShareableURL(campaignDetails.Id)}
                                  children={<i className="fa fa-facebook" />} />
                              </li>
                              <li>
                                <TwitterShareButton url={getCampaignShareableURL(campaignDetails.Id)}
                                  children={<i className="fa fa-twitter" />} />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-9 col-md-8 details-holder">
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex align-items-center flex-wrap mb-3">
                        <p className="mb-2 mr-4">
                          <h4 className="mt-4 mb-3">Story:</h4>
                        </p>
                        <ul className="dash-check-list resources-list d-flex flex-wrap">
                          <li className={"story-text"}>
                            {campaignDetails?.Story}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 col-md-7 col-lg-10 col-xl-10">
                      <div className="video-hloder">
                        {campaignDetails && parseInt(campaignDetails.VideoLink) ? (
                          <Vimeo video={campaignDetails.VideoLink} width="450" />
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-10 col-xl-10 mt-3">
                      <hr />
                      <h4 className="mb-4"> Resources needed </h4>
                      <div className="table-responsive">
                        <table className="table custom-table">
                          <thead>
                            <tr>
                              <th>Resource Name</th>
                              <th>Resource Details </th>
                              <th>Quantity</th>
                              <th>Resource Type</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaignDetails && campaignDetails.Resources ? (
                              campaignDetails.Resources.map(
                                (campaign, index) => (
                                  <tr key={campaign.Id}>
                                    <td>
                                      <p>{campaign.Name}</p>
                                    </td>
                                    <td>
                                      <p>{campaign.Description}</p>
                                    </td>
                                    <td>{campaign.Status !== 2 ? <p>{campaign.Quantity}</p> : <select
                                      className="from-control quantityDropdown"
                                      value={campaign.selectedQuantity}
                                      onChange={(e) => { handleQuantityChange(e.target.value, index, campaign.Id) }} >
                                      {Array.from(Array(campaign.Quantity).keys()).map(count => (
                                        <option key={count} value={count + 1}>{count + 1}</option>
                                      ))}
                                    </select>}
                                    </td>
                                    <td><p>{campaign.IsCash ? 'Cash' : 'Non cash'}</p></td>
                                    <td>
                                      <div className="dropdown">
                                        <button
                                          className="btn chips-box dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {RESOURCE_STATUS.find((status) => status.id === campaign.Status).text || 'N/A'}
                                        </button>
                                        <div
                                          className="dropdown-menu dropdown-menu-right"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          {RESOURCE_STATUS.map((status, index) =>
                                            <button key={index}
                                              className="dropdown-item"
                                              onClick={() =>
                                                updateResourceStatus({
                                                  Id: campaign.Id,
                                                  Status: status.id
                                                })
                                              }
                                            > {status.text}
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td>
                                  <p />
                                </td>
                                <td>
                                  <p>No resources found</p>
                                </td>
                                <td />
                                <td />
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-12 col-md-12 col-lg-12 col-xl-12 p-0 mt-4">
                        <div className="client-details-table">
                          <hr />
                          <h4 className="mb-4">List of Donors</h4>
                          <div className="table-responsive">
                            <table className="table custom-table">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Donation Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {campaignDetails && campaignDetails.Resources ? (
                                  donors && donors.map(
                                    (
                                      { FirstName, LastName, Email, ResourcesName,IsAnonymous },
                                      index
                                    ) => (
                                      <tr key={index}>
                                        <td>
                                          <p>{!IsAnonymous?`${FirstName} ${LastName}`:"Anonymous"}</p>
                                        </td>
                                        <td>
                                          <p>{!IsAnonymous?`${Email}`:"Anonymous"}</p>
                                        </td>
                                        <td>
                                          <p>{ResourcesName}</p>
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td />
                                    <td>No Donors Found</td>
                                    <td />
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {files && files.length ? <div className="col-12 col-md-7 col-lg-12 col-xl-12 px-0 my-4">
                          <hr />
                          <h4 className="mb-4 mb-3">Files</h4>
                          {files
                            ? files.map((file, index) => (
                              <div
                                key={index}
                                className={
                                  "file-list d-flex align-items-center justify-content-between"
                                }
                              >
                                <p className="m-0">{file && file.Title}</p>
                                <a
                                  className="btn site-btn"
                                  href={file && file.FilePath}
                                  target="_blank"
                                  rel="noreferrer"
                                  download={file && file.Title}
                                >
                                  View
                                  </a>
                              </div>
                            ))
                            : null}
                        </div> : null}
                      </div>
                      {chatList && chatList.length ? <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3 px-0">
                        <hr />
                        <div class='mt-4 d-flex justify-content-start align-items-center'>
                          <h4 className="m-0 mr-3"> Messages </h4>
                          <small><b>Tips:</b></small>
                          <button type="button" class="btn table-sitebtn no-wrap small-tip-btn ml-2" data-toggle="modal" data-target="#exampleModal">
                            <i className="fa fa-info" /> 
                          </button>
                        </div>
                        {chatId && <Accordion allowZeroExpanded onChange={onAccordinChange}>
                          {chatList && chatList.map((item, index) => (
                            <AccordionItem key={index} uuid={item.Id}  dangerouslySetExpanded={currentId === item.Id}>
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  <div className="msg-head">
                                    <div className="media align-items-center">
                                      <div className="media-body" >
                                        <h4 className="my-0">{item.Name}</h4>
                                      </div>
                                      <div className="dropdown mr-3">
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                          id="dropdownMenuButton"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        />
                                        <div
                                          className="dropdown-menu dropdown-menu-right"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          <a data-toggle="modal" data-target="#exampleModal" className="dropdown-item" onClick={() => setFeedback(item)}>
                                            Feedback
                                            </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel style={{ borderWidth: 1 }}  >
                                <div className="col-lg-12 col-md-7 col-sm-12 mesgs" >
                                  {chatDataMsg && chatDataMsg.map((item, index) => {
                                    return (
                                      <div key={index} className="msg_history">
                                        { myUserID !== item.SenderId ? <div className="incoming_msg">
                                          <div class="incoming_msg_img"> <img src={chatUsers.find((user) => user.Id === item.SenderId).ProfilePic} alt="profile" /> </div>
                                          <div className="received_msg">
                                            <div className="received_withd_msg">
                                              <p>
                                                {item.Message}
                                              </p>
                                              <span className="time_date"> {timeSince(new Date(item.SentOn.concat('Z')))}</span>
                                            </div>
                                          </div>
                                        </div> : null}
                                        { myUserID === item.SenderId ? <div className="outgoing_msg">
                                          <div className="sent_msg position-relative">
                                            <div className="sent_msg_img"> <img src={chatUsers.find((user) => user.Id === item.SenderId).ProfilePic} alt="profile" /> </div>
                                            <div className="sent-msg-ctnr">
                                              <p> {item.Message}</p>
                                              <span className="time_date"> {timeSince(new Date(item.SentOn.concat('Z')))}</span>
                                            </div>
                                          </div>
                                        </div> : null}
                                      </div>
                                    )
                                  }).reverse()}
                                  {(!chatDataMsg) ? <div style={{ height: 100, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>{chatMsg}</div> : null}
                                  <div className="type_msg">
                                    <div className="input_msg_write">
                                      <input
                                        type="text"
                                        className="write_msg"
                                        placeholder="Type a message"
                                        onChange={onTextChange}
                                        value={sendMsgText || ""}
                                      />
                                      <button className="msg_send_btn mr-3" type="button" onClick={() => { onSendBtnTap(); }}>
                                        <i
                                          className="fa fa-paper-plane-o"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </AccordionItemPanel>
                            </AccordionItem>
                          ))}
                        </Accordion>}
                      </div> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Feedback</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <div className="w-100">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      feedbackMessage: ""
                    }}
                    validationSchema={Yup.object().shape({
                      feedbackMessage: Yup.string().required("Required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      feedback['FeedbackText'] = values.feedbackMessage;
                      sendFeedbackMessage(feedback)
                      setSubmitting(false);
                    }}
                  >
                    {({ values, errors, touched, handleChange, handleBlur }) => (
                      <Form>
                        <input
                          className="w-90 feedback-input"
                          type="text"
                          placeholder="Enter feedback"
                          id="feedbackMessage"
                          name="feedbackMessage"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required=""
                          defaultValue={""}
                        />
                        <span className="text-danger">{errors.feedbackMessage &&
                          touched.feedbackMessage &&
                          errors.feedbackMessage}</span>
                        <div className="modal-footer">
                          <input
                            className="btn btn-secondary"
                            type="submit"
                            value="Send feedback"
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
    </div>
  );
};

export default withAPIRequest(RequesterCampaignDetails);
