import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./Login.scss";
import { useHistory, Link, Redirect, useParams } from "react-router-dom";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import POLICY_CONTENT from '../../../configs/policies';
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";

const Login = (props) => {

  let history = useHistory();
  let { token } = useParams();
  const globalContext = useContext(GlobalContext);
  useEffect(() => {
    if (token) verifyToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyToken = (verificationToken) => {
    let serviceParams = {
      api: `${API.VERIFY_TOKEN}${verificationToken}`,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Data) {
          globalContext.setNotifications({
            type: "success",
            text: result && result.Status.Message,
          })
        } else {
          globalContext.setNotifications({
            type: "error",
            text: result && result.Status.Message,
          })
          props.history.push("/");
        }
      }
    });
  };

  const callLoginAPI = (email, password) => {
    let serviceParams = {
      api: API.LOGIN,
      method: AXIOS_METHOD_TYPES.POST,
      params: {
        Email: email,
        Password: password,
      },
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        localStorage.setItem("user", JSON.stringify(result.Data));
        globalContext.setLoggedInUser(result.Data);
        if (result.Status.StatusId === 1) {
          history.push(getRoutingString(result.Data.User.Role));
        } else {
          globalContext.setNotifications({
            type: "error",
            text: result ? result.Status.Message : "No data found",
          });
        }
      }
    });
  };

  const getRoutingString = (role) => {
    switch (role) {
      case 1:
        return "/superadmin-users";
      case 2:
        return "/admin-dashboard";
      case 3:
        return "/donor-all-campaings";
      case 4:
        return "/requester-campaigns?status=1";
      default:
        return "/";
    }
  };

  return (
    <>
      <Meta title={"Login"} content={"Login"} />
      {globalContext.user === null ? (
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
              <div className="form-div form-pad">
                <div className="inner-part text-center">
                  <h2 className="title mb-5">Login</h2>
                </div>
                <div id="form-messages contact-form" />
                <Formik
                  initialValues={{ email: "", password: "", IsChecked: false }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Invalid email")
                      .required("Required"),
                    password: Yup.string()
                      .min(6, "Must be 6 characters or more")
                      .required("Required"),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    if (values.IsChecked) {
                      callLoginAPI(values.email, values.password);
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    isSubmitting,
                  }) => (
                    <Form>
                      <fieldset>
                        {!values.IsChecked && touched.IsChecked && (
                          <div
                            style={{
                              color: "#721c24",
                              backgroundColor: "#f8d7d9",
                              height: 50,
                              paddingTop: 15,
                              paddingLeft: 10,
                              marginBottom: 10,
                            }}
                          >
                            Please accept Terms & Privacy Policy
                          </div>
                        )}
                        <div className="row">
                          <div className="col-12 mb-20">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="from-control"
                              value={values.email}
                              type="text"
                              id="email"
                              name="email"
                              placeholder="Email"
                            />
                            {errors.email && touched.email && (
                              <span>{errors.email}</span>
                            )}
                          </div>
                          <div className="col-12 mb-35">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="from-control"
                              value={values.password}
                              type="password"
                              id="password"
                              name="password"
                              placeholder="Password"
                            />
                            {errors.password && touched.email && (
                              <span>{errors.password}</span>
                            )}
                          </div>
                        </div>

                        <div className="col-12 mb-35">
                          <label className="check-style">
                          I have read and accept the terms and conditions, privacy policy, cookie policy, disclaimer, DMCA policy and acceptable use policy.<a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"> Click to view </a>
                            <input
                              type="checkbox"
                              checked={values.IsChecked}
                              onChange={handleChange}
                              id="IsChecked"
                              name="IsChecked"
                            />
                            <span className="checkmark"></span>
                          </label>

                          <div class="collapse mt-3" id="collapseExample">
                            <div class='card card-body login-text'>
                              <ul>
                                <li className="mb-2"><Link to={'/policies/privacy'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.privacy.title}</Link></li>
                                <li className="mb-2"><Link to={'/policies/terms'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.terms.title}</Link></li>
                                <li className="mb-2"><Link to={'/policies/cookie'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.cookie.title}</Link></li>
                                <li className="mb-2"><Link to={'/policies/disclaimer'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.disclaimer.title}</Link></li>
                                <li className="mb-2"><Link to={'/policies/dmca'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.dmca.title}</Link></li>
                                <li><Link to={'/policies/acceptableUse'} target="_blank" rel="noopener noreferrer">{POLICY_CONTENT.acceptableUse.title}</Link></li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-20">
                          <input
                            className="btn-send"
                            type="submit"
                            value="Login"
                          />
                        </div>
                      </fieldset>
                    </Form>
                  )}
                </Formik>

                <div className="d-flex justify-content-between">
                  <p
                    className="text-center mb-0 login-text hover"
                    onClick={() => history.push("/forgot-password")}
                  >
                    <Link to="/forgot-password">Forgot password</Link>
                  </p>
                  <p
                    className="text-center mb-0 login-text hover"
                    onClick={() => history.push("/donor-register")}
                  >
                    <Link to="/donor-register">Create an account</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Redirect to={getRoutingString(globalContext.user.User.Role)} />
      )}
    </>
  );
}

export default withAPIRequest(Login);
