import React , { useContext , useEffect , useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { API , AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import Meta from "../../common/meta/Meta";
import moment from "moment";
import Pagination from "react-js-pagination";
import "./AdminResources.scss";

const AdminResource = (props) => {

  const globalContext = useContext(GlobalContext);
  const [resources, setResources] = useState([]);
  const [initialResourceList, setInitialResourceList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const [filterId, setFilterId] = useState(null);

  useEffect(() => {
    if(globalContext.user.User.Role === 2 ){
    getResource(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, []);

  const getResource = (pageNumber) => {
    let serviceParams = {
      api: `${API.RESOURCES}${pageNumber}`,
      method: AXIOS_METHOD_TYPES.GET
    };
    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, ({ Status: { StatusId, Message }, Data: { ResultData, TotalPages, RowCount, PageSize } }) => {
      globalContext.setLoading(false);
      if (ResultData && StatusId === 1) {
        filterId ? filterResource( filterId ) : setResources( ResultData );
        setInitialResourceList(ResultData);
        setTotalPages(TotalPages);
        setTotalItems(RowCount);
        setPageSize(PageSize);
        return;
      }
      globalContext.setNotifications({
        type: "error",
        text: ResultData ? Message : "Data not found",
      });
    });
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    getResource(pageNumber);
  }

  const filterResource = (id) => {
    setFilterId( id );
    setResources(id === "All" ? initialResourceList : initialResourceList.filter(({ ResourceStatus }) => ResourceStatus === id));
  };

  return (
      <div className="container">
        <Meta title={ "Admin-Resources" } content={ "Admin resources" }/>
        { globalContext.user.User.Role === 2 ? <div className="row">
          <div className="col-12">
            <div className="form-div">
              <div className="d-flex justify-content-between mb-5">
                <h3 className="dash-heading mb-0">List of Resources</h3>
                <select
                    className="from-control col-sm-2 col-md-3 col-lg-2 col-xl-2 mr-3"
                    onChange={(e) => {
                      e.preventDefault();
                      filterResource(e.target.value === "All" ? "All" : parseInt(e.target.value));
                    }}
                >
                  <option>{"All"}</option>
                  <option value={"2"}>{"OPEN"}</option>
                  <option value={"-1"}>{"CLOSE"}</option>
                  <option value={"3"}>{"PENDING"}</option>
                </select>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="client-table">
                    <div className="table-responsive">
                      <table className="table custom-table">
                        <thead>
                        <tr>
                          <th>Resources name</th>
                          <th>Campaign name</th>
                          <th>Recipient Name</th>
                          <th>Status</th>
                          <th>Campaign Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {resources.map(
                            (
                                {
                                  ResourceName,
                                  CampaignName,
                                  RequesterName,
                                  ResourceStatusName,
                                  CampaignEndDate,
                                },
                                index
                            ) => (
                                <tr key={index}>
                                  <td>
                                    <p>{ResourceName}</p>
                                  </td>
                                  <td>
                                    <p>{CampaignName}</p>
                                  </td>
                                  <td>
                                    <p>{RequesterName}</p>
                                  </td>
                                  <td>
                                    <p>{ResourceStatusName}</p>
                                  </td>
                                  <td>
                                    <p>
                                      {moment(CampaignEndDate).format("MM/DD/YYYY")}
                                    </p>
                                  </td>
                                </tr>
                            )
                        )}
                        </tbody>
                      </table>
                      <Pagination
                          totalItemsCount={totalItems}
                          onChange={handlePageChange}
                          activePage={activePage}
                          itemsCountPerPage={pageSize}
                          pageRangeDisplayed={totalPages-1}
                          innerClass="pagination justify-content-center"
                          itemClass="page-item textColor"
                          linkClass="page-link textColor"
                          activeLinkClass="btnColor"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
      </div>
  );
};

export default withAPIRequest(AdminResource);