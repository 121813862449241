/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";

const Header = ({
  isSticky,
  setShowSidebarAction,
  isLogin,
  userType,
  globalContext,
  showSidebar
}) => {
  const user = globalContext.user;
  let history = useHistory();

  const handleClickBurgurIcon = (e) => {
    e.preventDefault();
    setShowSidebarAction();
  };

  const goToHome = () => {
   /* if (globalContext.user) {
      let role = user.User.Role;
      switch (role) {
        case 1:
          history.push("/superadmin-users");
          break;
        case 2:
          history.push("/admin-dashboard");
          break;
        case 3:
          history.push("/donor-all-campaings");
          break;
        case 4:
          history.push("/requester-dashboard");
          break;
        default:
      }
    } else {*/
      history.push("/");
    //}
  };

  const logoutHandler = () => {
    localStorage.setItem("user", null);
    globalContext.setLoggedInUser(null);
    history.push("/");
    globalContext.setNotifications({
      type: "success",
      text: "Logged out successfully",
    });
  };

  return (
    <>
      <div className="full-width-header home8-style4 main-home">
        {/*Header Start*/}
        <header
          id="rs-header"
          className={isSticky ? "rs-header sticky" : "rs-header"}
        >
          {/* Menu Start */}
          <div className="menu-area menu-sticky dashboard-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="menu-holder">
                    {isLogin && (
                      <div className="my-prof" onClick={handleClickBurgurIcon}>
                        {" "}
                        {!showSidebar ? '☰' : <div className="fa fa-close"></div>}{" "}
                      </div>
                    )}
                    <div className="logo-cat-wrap">
                      <div className="logo-part  logo-part-header">
                        <Link onClick={goToHome}>
                          <img
                            className="normal-logo"
                            src="/assets/images/logo.png"
                            alt=""
                          />
                          <img
                            className="sticky-logo"
                            src="/assets/images/logo.png"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="logo-part  logo-part-header secondary-logo-margin">
                        <a href="https://zionchurch.org">
                          <img
                            className="normal-logo"
                            src="/assets/images/zion_logo.png"
                            alt=""
                          />
                          <img
                            className="sticky-logo"
                            src="/assets/images/zion_logo.png"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                   
                    {isLogin && (
                      <div className="user-dashboard">
                     
                    {user.User.Role==3? 
                      <Link to={{ pathname: '/change-personal-details' }}>
                     <img src={user.User.ProfilePic} alt="Profile Pic" class="avatar" />
                     </Link>
                     :null}
                        <div className="dropdown">
                          <div className="user-name"
                               id="dropdownMenuButton"
                                data-toggle="dropdown">{user.User.FirstName}</div>
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <button
                              className="dropdown-item"
                              onClick={logoutHandler}
                            >
                              Logout
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Menu End */}
        </header>
        {/*Header End*/}
      </div>
      <div className="header-gap"></div>
    </>
  );
};

export default Header;
