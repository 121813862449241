/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import './DonorCampaigns.scss';
import { API, AXIOS_METHOD_TYPES } from '../../../configs/constants';
import { withAPIRequest } from '../../../components/HOC/withAPIRequest';
import { GlobalContext } from '../../../context/GlobalContext';
import { Link } from 'react-router-dom';
import Meta from "../../common/meta/Meta";

const DonorCampaigns = (props) => {

	const globalContext = useContext(GlobalContext);
	const [CampaignList, setCampaignList] = useState();

	useEffect(() => {
		getDonorDetails();
	}, []);

	const getDonorDetails = () => {
		let userId = globalContext.user.User.Id;
		let serviceParams = {
			api: `${API.DONOR_CAMPAIGN}?userId=${userId}`,
			method: AXIOS_METHOD_TYPES.GET,
		}
		globalContext.setLoading(true);
		props.commonAPIRequest(serviceParams, (result) => {
			globalContext.setLoading(false);
			if (result) {
				setCampaignList(result.Data.CampaignResources)
			}
		});
	}

	return (
		<div className="container">
			<Meta title={ "Donor-Campaigns" } content={ "Donor campaigns" }/>
			<div className="row">
				<div className="col-12">
					<div className="form-div">
						<div className="d-flex justify-content-between mb-5">
							<h3 className="dash-heading mb-0">Donor Campaigns</h3>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="client-table">
									<div className="table-responsive">
										<table className="table custom-table">
											<thead>
												<tr>
													<th>Donation Amount</th>
													<th>Campaign Name</th>
													<th>Campaign Status</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{CampaignList && CampaignList.map((item, index) => {
													return ( <tr key={ index }>
														<td> <p> {item.ResourceName} </p> </td>
														<td> <p>{item.CampaignName}</p> </td>
														<td> <p>{item.CampaignStatusName}</p> </td>
														<td> <Link to={`donor-campaign-details/${item.CampaignId}`} className="btn table-sitebtn">View</Link></td>
													</tr>)
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default withAPIRequest(DonorCampaigns);
