import React, { useContext, useEffect, useState } from "react";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { GlobalContext } from "../../../context/GlobalContext";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import moment from "moment";
import "./AdminCampaignDonorDetails.scss";
import Meta from "../../common/meta/Meta";
import { convertQueryStringToObj, sortByName } from "../../../configs/utility";
import { Link } from "react-router-dom";

const AdminDonorDetails = (props) => {
  const [donationSummary, SetDonationSummary] = useState([]);
  const globalContext = useContext(GlobalContext);
  const userRole = globalContext.user.User.Role;
  const [queryStringToObj, setQueryStringToObj] = useState(
    convertQueryStringToObj(props.location.search)
  );

  useEffect(() => {
    getCampaignResourcesDonationSummary();
  }, []);

  const getCampaignResourcesDonationSummary = () => {
    let serviceParams = {
      api: `${API.CAMPAIGN_RESOURCES_DONOR_DETAILS}`,
      method: AXIOS_METHOD_TYPES.POST,
      params: {
        PaymentStatus: queryStringToObj.PaymentStatus,
        StartDate: queryStringToObj.StartDate,
        EndDate: queryStringToObj.EndDate,
        IsCash: queryStringToObj.IsCash,
        IsOpen: queryStringToObj.IsOpen,
      },
    };
    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        SetDonationSummary(result.Data);
      } else {
        globalContext.setNotifications({
          type: "error",
          text: result ? result.status.Message : "Data not found",
        });
      }
    });
  };

  return (
    <div className="defult-home">
      <Meta
        title={"Requester-Donor-Summary"}
        content={"Requester donor summary"}
      />
      {userRole === 2 ? (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="form-div">
                <div className="d-flex justify-content-between mb-5">
                  <h4 className=" mb-0">
                    {queryStringToObj.title &&
                      queryStringToObj.title.replace(/%20/g, " ")}{" "}
                  </h4>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="client-table">
                      <div className="table-responsive">
                        <table className="table custom-table">
                          {/* table-striped table-borderless */}
                          <thead>
                            <tr>
                              <th>Donor Name</th>
                              <th>Campaigns</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {donationSummary&&donationSummary.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {" "}
                                  <p>{`${item.DonorName} `}</p>{" "}
                                </td>
                                <td>
                                  {item.Campaigns && (
                                    <div className="chip-holder">
                                      {item.Campaigns.map((campaign) => (
                                        <div className="chip">
                                          {campaign.CampaignName}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </td>
                                <td className="hover">
                                  {" "}
                                  <Link
                                    to={`/admin-donor-details/${item.DonorId}`}
                                    className="btn table-sitebtn"
                                  >
                                    View
                                  </Link>{" "}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            fontSize: 24,
            color: "red",
          }}
        >
          Access Deined
        </div>
      )}
    </div>
  );
};

export default withAPIRequest(AdminDonorDetails);
