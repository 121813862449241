import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./FileUploader.scss";

function MyDropzone({ icon, setFiles, placeholder, type, onChange, isMultiple = false }) {
  const onDrop = useCallback((acceptedFiles) => {
    isMultiple ? onChange(acceptedFiles) : setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: type,
    multiple: isMultiple
  });

  return (
    <div {...getRootProps()} className="file-wrapper">
      <input {...getInputProps()} />
      {isDragActive ? (
        <p className="drop">Drop the files here ...</p>
      ) : (
        <p className="drag-and-drop">{icon && icon} {placeholder}</p>
      )}
    </div>
  );
}

export default MyDropzone;