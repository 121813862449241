/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { API, AXIOS_METHOD_TYPES } from "../../configs/constants";
import { withAPIRequest } from "../../components/HOC/withAPIRequest";

function Footer(props) {
  const [version,setVersion]=useState();
  useEffect(()=>{
    callLatestVersionAPI();
  },[null])
  const {showContactUs}=props

  const currentYear=new Date().getFullYear();

  const aboutUsTap = () => {
    window.scroll({
      top: 800,
      left: 0,
      behavior: "smooth",
    });
  }

  
  const callLatestVersionAPI = (params) => {
    let serviceParams = {
      api: API.GET_LATEST_VERSION,
      method: AXIOS_METHOD_TYPES.GET
    };

    props.commonAPIRequest(serviceParams, (result) => {
      if (result) {
        if (result.Status.StatusId === 1) {
          if(result.Data&&result.Data.Name){
           var previousItem =localStorage.getItem("version")
           localStorage.setItem("version",result.Data.Name)
           if(previousItem&&previousItem!=result.Data.Name){
              window.location.reload(true);
           }
          }
         setVersion(result.Data)
        } else {
        }
      }
    },true);
  };

  return (
    <footer id="rs-footer" className="rs-footer home9-style main-home">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 footer-widget">
              <div className="footer-logo mb-30">
                <a href="index.html">
               
                  <img src="/assets/images/logo.png" alt="" />
                   
                </a>
                <a href="https://zionchurch.org">
                 <img
                  className="logo-margin-footer"
                  src="/assets/images/zion_logo.png" alt="" 
                />
                </a>
              </div>
             
              <ul className="footer_social">
                <li>
                  <a href="https://www.facebook.com/zionchurchonline" target="_blank">
                    <span>
                      <i className="fa fa-facebook" />
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/zionchurchorg" target="_blank">
                    <span>
                      <i className="fa fa-twitter" />
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/zionchurch/" target="_blank">
                    <span>
                      <i className="fa fa-instagram" />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
             <div className="offset-lg-1 col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
             <div className="textwidget white-color pr-60 md-pr-15">
               <h3 className="widget-title">About Us</h3>
                <p>
                ​​Connecting generosity with those in need of a little grace and resources.
                </p>
              </div>
           {/*  <h3 className="widget-title">Address</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-location" />
                  <div className="desc">Lanham Maryland</div>
                </li>
                <li>
                  <i className="flaticon-email" />
                  <div className="desc">
                    <a href="mailto:info@everyhumanneeds.com">
                        info@everyhumanneeds.com
                    </a>
                  </div>
                </li>
  </ul>*/}
  </div>
            <div className="col-lg-4 col-md-12 col-sm-12 pl-50 md-pl-15 footer-widget md-mb-50">
              <h3 className="widget-title">Quick Links</h3>
              <ul className="site-map">
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <HashLink to="/#aboutUsSection">About us</HashLink>
                </li>
                <li>
                  <HashLink to="/#homeFeaturedStories">
                    Featured Stories
                  </HashLink>
                </li>
                <li>
                  <Link onClick={(e) => {
                    e.preventDefault()
                    showContactUs(true)
                  }
                    }>Contact us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 md-mb-20">
              <div className="copyright">
                <p>© {currentYear} All Rights Reserved. {version&&version.Name?"Version "+version.Name:""}</p>
              </div>
            </div>
            <div className="col-lg-6 text-right md-text-left">
              <ul className="copy-right-menu">
                <li>
                  <Link to={'/policies/privacy'}>Privacy policy</Link>
                </li>
                <li>
                  <Link to={'/policies/terms'}>Terms of use</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default withAPIRequest(Footer);
