/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";

const InitialOperations = ({ setAppLoaded }) => {
  const globalContext = useContext(GlobalContext);
  const history = useHistory();
  let logoutTimeout;

  useEffect(() => {
    history.listen((location, action) => {
      window.scrollTo(0, 0);
    });

    // Set logged in user
    let user = JSON.parse(localStorage.getItem("user"));
    globalContext.setLoggedInUser(user);

    // App is ready to load
    setAppLoaded(true);
    autoLogoutInit();
  }, []);
  const autoLogout = () => {
        let currentURL = window.location.hash
        if (currentURL !== '#/' && currentURL !== '#/login' && currentURL !== '#/#latestCampaigns' && !currentURL.includes('#/campaign-details/')) {
          clearLogoutTimeout()
          localStorage.setItem("user", null);
          globalContext.setLoggedInUser(null);
          history.push("/");
          globalContext.setNotifications({
            type: "success",
            text: "Logged out successfully",
          });
        }
      }
    
      const setLogoutTimeout = () => {
        logoutTimeout = setTimeout(autoLogout, 900000);
      }
      const clearLogoutTimeout = () => {
        if (logoutTimeout) clearTimeout(logoutTimeout);
      }
    
      const resetTimeout = () => {
        clearLogoutTimeout();
        setLogoutTimeout();
      }
    
      const autoLogoutInit = () => {
        const events = [
          "load",
          "mousemove",
          "mousedown",
          "click",
          "scroll",
          "keypress"
        ];
    
        events.map((event) => {
          window.addEventListener(event, resetTimeout);
          return null;
        })
        setLogoutTimeout()
      }
  return null;
};

export default InitialOperations;
