import React, { Component } from "react";
import "./Loading.scss";

class Loading extends Component {
  state = {};
  render() {
    return (
      <div
        className="loading-screen-wrapper"
        onClick={(e) => {
          // to prevent a click when loading is show. But not working :(
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="loading-screen-icon">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
