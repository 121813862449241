/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect,useRef } from "react";
import "./AdminDonors.scss";
import { Link } from "react-router-dom";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";
import moment from "moment";

const AdminDonors = (props) => {

  const globalContext = useContext(GlobalContext);
  const [donors, setDonors] = useState([]);
  const [selectedDonor, setSelectedDonor] = useState(null);
  const closeButton= useRef(null);

  useEffect(() => {
    if (globalContext.user.User.Role === 2) {
      callDonorsAPI()
    }
  }, []);

  const callDonorsAPI = () => {
    let serviceParams = {
      api: API.DONORS,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          setDonors(result.Data);
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };

  const callDeleteDonorAPI = () => {
    // debugger
    let serviceParams = {
      api: `${API.DELETE_DONORS}?userId=${selectedDonor.Id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {

      globalContext.setLoading(false);
      closeButton.current.click();
      if (result) {
      
        if (result.Status.StatusId === 1) {
          callDonorsAPI();
          globalContext.setNotifications({
            id: Math.random(),
            type: "success",
            text:'Donor deleted successfully' ,
          });
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: "Error while deleting donor"
          });
        }
      }
    });
  };
  return (
    <div className="container">
      <Meta title={"Admin-Donors"} content={"Admin donors"} />
      {globalContext.user.User.Role === 2 ? <div className="row">
        <div class="modal fade" id="deleteDonorModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">Delete Donor</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref={closeButton}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                {selectedDonor && !selectedDonor.Campaigns ?
                  <>
                    <div>

                      <p>You are about to delete all <span><strong>{`${selectedDonor.FirstName} ${selectedDonor.LastName}`}</strong></span> data?</p>

                    </div>
                    <div className="col-12 col-lg-12 delete-btn-div">
                      <input
                        className="btn btn-delete"
                        onClick={() => {
                          callDeleteDonorAPI()
                        }}
                        value="Delete"
                      />
                    </div>
                  </>
                  : 
                  selectedDonor?<p><span><strong>{`${selectedDonor.FirstName} ${selectedDonor.LastName}`}</strong></span> can not be deleted.</p>:null
                }
              </div>

            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="form-div">
            <div className="d-flex justify-content-between mb-5">
              <h3 className="dash-heading mb-0"> List of Donors </h3>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="client-table">
                  <div className="table-responsive">
                    <table className="table custom-table">
                      {/* table-striped table-borderless */}
                      <thead>
                        <tr>
                          <th>Donor Name</th>
                          <th>Campaigns</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {donors.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {" "}
                              <p>{`${item.FirstName} ${item.LastName}`}</p>{" "}
                            </td>
                            <td>
                              {item.Campaigns && (
                                <div className="chip-holder">
                                  {item.Campaigns.map((campaign) => (
                                    <div className="chip">
                                      {campaign.CampaignName}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </td>
                            <td>
                              {" "}
                              <p>
                                {moment(item.AddedOn).format("MM/DD/YYYY")}
                              </p>{" "}
                            </td>
                            <td className="hover">
                              {" "}

                              <Link
                                to={`/admin-donor-details/${item.Id}`}
                                className="btn table-sitebtn"
                              >
                                View
                              </Link>{" "}
                              <div
                                data-toggle="modal" data-target="#deleteDonorModal"
                                className="btn table-sitebtn"
                                onClick={() => {
                                  setSelectedDonor(item);
                                }}
                              >
                                <i className="fa fa-trash-o" />
                              </div>

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <div style={{ width: '100%', alignItems: 'center', textAlign: 'center', fontSize: 24, color: 'red' }}>Access Deined</div>}
    </div>
  );
}

export default withAPIRequest(AdminDonors);
