/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "./ResetPassword.scss";
import { useHistory } from "react-router-dom";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import { Form, Formik } from "formik";
import Meta from "../../common/meta/Meta";
import * as Yup from "yup";

function Page(props) {
  const globalContext = useContext(GlobalContext);
  const [token, setToken] = useState(null);

  let history = useHistory();

  useEffect(() => {
    let _token = props.location.search.split("t=")[1];
    if (_token) setToken(_token);
  }, []);

  const callResetPasswordAPI = (params) => {
    let serviceParams = {
      api: API.RESET_PASSWORD,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          history.push("/login");
          globalContext.setNotifications({
            id: Math.random(),
            type: "success",
            text: "Your password is updated.",
          });
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };

  return (
    <div className="container">
      <Meta title={ "Reset-Password" } content={ "Reset password" }/>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
          <div className="form-div form-pad">
            <div className="inner-part text-center">
              <h2 className="title mb-5">Reset password</h2>
            </div>
            <div id="form-messages"></div>
            <Formik
              initialValues={{
                NewPassword: "",
                ConfirmPassword: "",
              }}
              validationSchema={Yup.object().shape({
                NewPassword: Yup.string().required("Required").min(6, "Must be 6 characters or more"),
                ConfirmPassword: Yup.string().oneOf(
                  [Yup.ref("NewPassword"), null],
                  "Passwords must match"
                ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                delete values["ConfirmPassword"];
                values["PasswordResetCode"] = token;
                callResetPasswordAPI(values);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <div className="row">
                    <div className="col-12 mb-20">
                      {/* <label>User Name</label>  */}
                      <input
                        className="from-control"
                        type="password"
                        placeholder="Create a new password "
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.NewPassword}
                        id="NewPassword"
                        name="NewPassword"
                        required=""
                      />
                      {errors.NewPassword &&
                        touched.NewPassword &&
                        errors.NewPassword}
                    </div>
                    <div className="col-12 mb-35">
                      {/* <label>User Name</label>  */}
                      <input
                        className="from-control"
                        type="password"
                        placeholder="Confirm new password "
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.ConfirmPassword}
                        id="ConfirmPassword"
                        name="ConfirmPassword"
                        required=""
                      />
                      {errors.ConfirmPassword &&
                        touched.ConfirmPassword &&
                        errors.ConfirmPassword}
                    </div>
                  </div>
                  <div className="mb-20">
                    <input className="btn-send" type="submit" value="Submit" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAPIRequest(Page);
