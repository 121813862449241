import React from "react";
import "./Notifications.scss";
import Meta from "../../common/meta/Meta";

const Notifications = ( props ) => {

  return (
    <div className="container">
      <Meta title={ "Notification" } content={ "Notifications" }/>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
          <div className="form-div ">
            <div className="d-flex">
              <h2 className="title mb-mb-15">Notification </h2>
            </div>
            <ul>
              <li>
                <div className="notifications-list">
                  <p>
                    <span className="noti-dot mr-1"></span>
                    <span className="noti-name"> Jesse Davidson </span>{" "}
                    commented on your videos{" "}
                  </p>
                  <div className="d-flex align-items-center mt-1">
                    <h6 className="date-time-text mb-0">Just Now</h6>
                    <ul className="d-flex ml-auto">
                      <li className="mr-3">
                        <i className="fa fa-comments-o" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div className="notifications-list">
                  <p>
                    <span className="noti-dot mr-1"></span>
                    <span className="noti-name"> Jesse Davidson </span>{" "}
                    commented on your videos{" "}
                  </p>
                  <div className="d-flex align-items-center mt-1">
                    <h6 className="date-time-text mb-0">Just Now</h6>
                    <ul className="d-flex ml-auto">
                      <li className="mr-3">
                        <i className="fa fa-comments-o" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div className="notifications-list">
                  <p>
                    <span className="noti-dot mr-1"></span>
                    <span className="noti-name"> Jesse Davidson </span>{" "}
                    commented on your videos{" "}
                  </p>
                  <div className="d-flex align-items-center mt-1">
                    <h6 className="date-time-text mb-0">Just Now</h6>
                    <ul className="d-flex ml-auto">
                      <li className="mr-3">
                        <i className="fa fa-comments-o" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
