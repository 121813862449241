import React from "react";
import Meta from "../../common/meta/Meta";
import POLICY_CONTENT  from '../../../configs/policies';
import { useParams } from "react-router";

const Policies = () => {
    const { id } = useParams();

    return (<div className="container">
        <Meta title={POLICY_CONTENT[id].browserTitle} content={POLICY_CONTENT[id].browserTitle} />
        <div className="row">
            <div className="col-12">
                <div className="form-div">
                    <div className="d-flex justify-content-between mb-5">
                        <h3 className="dash-heading mb-0">{POLICY_CONTENT[id].title}</h3>
                    </div>
                    <div className="row">
                        <div className="col-12" dangerouslySetInnerHTML={{ __html: POLICY_CONTENT[id].content }}></div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default Policies;