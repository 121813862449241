export const userType = (id) => {
  let type = null
  switch (id) {
    case 1:
      type = "superadmin"
      break;
    case 2:
      type = "admin"
      break;
    case 3:
      type = "donor"
      break;
    case 4:
      type = "requester"
      break;
    default:
  }
  return type
}

export const formatDate = (value) => {
  const date = new Date(value).toLocaleDateString()
  return date.split("/").join(".")
}

export const convertQueryStringToObj = (queryString) => {
  const arr = queryString.slice(1).split(/&|=/); // remove the "?", "&" and "="
  let params = {};

  for (let i = 0; i < arr.length; i += 2) {
    const key = arr[i], value = arr[i + 1];
    params[key] = value; // build the object = { limit: "10", page:"1", status:"APPROVED" }
  }
  return params;
}

export const setCarousel = () => {
  setTimeout(() => {
    window.$('.rs-carousel').each(function () {
      var owlCarousel = window.$(this),
        loop = owlCarousel.data('loop'),
        items = owlCarousel.data('items'),
        margin = owlCarousel.data('margin'),
        // stagePadding = owlCarousel.data('stage-padding'),
        autoplay = owlCarousel.data('autoplay'),
        autoplayTimeout = owlCarousel.data('autoplay-timeout'),
        smartSpeed = owlCarousel.data('smart-speed'),
        dots = owlCarousel.data('dots'),
        nav = owlCarousel.data('nav'),
        navSpeed = owlCarousel.data('nav-speed'),
        xsDevice = owlCarousel.data('mobile-device'),
        xsDeviceNav = owlCarousel.data('mobile-device-nav'),
        xsDeviceDots = owlCarousel.data('mobile-device-dots'),
        smDevice = owlCarousel.data('ipad-device'),
        smDeviceNav = owlCarousel.data('ipad-device-nav'),
        smDeviceDots = owlCarousel.data('ipad-device-dots'),
        smDevice2 = owlCarousel.data('ipad-device2'),
        smDeviceNav2 = owlCarousel.data('ipad-device-nav2'),
        smDeviceDots2 = owlCarousel.data('ipad-device-dots2'),
        mdDevice = owlCarousel.data('md-device'),
        centerMode = owlCarousel.data('center-mode'),
        HoverPause = owlCarousel.data('hoverpause'),
        mdDeviceNav = owlCarousel.data('md-device-nav'),
        mdDeviceDots = owlCarousel.data('md-device-dots');
      owlCarousel.owlCarousel({
        loop: (loop ? true : false),
        items: (items ? items : 4),
        lazyLoad: true,
        center: (centerMode ? true : false),
        autoplayHoverPause: (HoverPause ? true : false),
        margin: (margin ? margin : 0),
        //stagePadding: (stagePadding ? stagePadding : 0),
        autoplay: (autoplay ? true : false),
        autoplayTimeout: (autoplayTimeout ? autoplayTimeout : 1000),
        smartSpeed: (smartSpeed ? smartSpeed : 250),
        dots: (dots ? true : false),
        nav: (nav ? true : false),
        navText: ["<i className='fa fa-angle-left'></i>", "<i className='fa fa-angle-right'></i>"],
        navSpeed: (navSpeed ? true : false),
        responsiveClass: true,
        responsive: {
          0: {
            items: (xsDevice ? xsDevice : 1),
            nav: (xsDeviceNav ? true : false),
            dots: (xsDeviceDots ? true : false),
            center: false,
          },
          576: {
            items: (smDevice2 ? smDevice2 : 2),
            nav: (smDeviceNav2 ? true : false),
            dots: (smDeviceDots2 ? true : false),
            center: false,
          },
          768: {
            items: (smDevice ? smDevice : 3),
            nav: (smDeviceNav ? true : false),
            dots: (smDeviceDots ? true : false),
            center: false,
          },
          992: {
            items: (mdDevice ? mdDevice : 4),
            nav: (mdDeviceNav ? true : false),
            dots: (mdDeviceDots ? true : false),
          }
        }
      });
    });
  }, 500);
}

export function timeSince(date) {
  console.log('date :>> ', date);

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

export const getCampaignShareableURL = (campaignId) => {
  var url = window.location.href;
  var arr = url.split("/");
  var host = arr[0] + "//" + arr[2];

  let result = `${host}/#/campaign-details/${campaignId}`
  return result;
}

export const sortByName = ( list , name ) => {
  return [ ...list.filter( ( { IsFeatured } ) => {
    return IsFeatured === name;
  } ) , ...list.filter( ( { IsFeatured } ) => {
    return IsFeatured !== name;
  } ) ];
}

export const setHomepagePublicMenuMobile = (params) => {
  setTimeout(() => {
     //Defines variables	
	var arrow_up = '<i class="fa fa-angle-up" aria-hidden="true"></i>';
	var arrow_down = '<i class="fa fa-angle-down" aria-hidden="true"></i>';
	var arrow_span = '<span class="rs-menu-parent">' + arrow_down + '</span>';
	var close_button = '<div class="sub-menu-close"><i class="fa fa-times" aria-hidden="true"></i>Close</div>';
	
	//Insert all arrow down span element
	window.$('.nav-menu .rs-mega-menu').append(arrow_span);
	window.$('.nav-menu > .menu-item-has-children').append(arrow_span);
	window.$('.nav-menu > .menu-item-has-children .sub-menu > .menu-item-has-children').append(arrow_span);
	
	//Insert all close button element
	window.$('.nav-menu .menu-item-has-children .sub-menu').append(close_button);
	window.$('.nav-menu .rs-mega-menu .mega-menu').append(close_button);

	/*-----------------------------------------------------------------------------------*/
	/*	OPEN SUB MENU FUNCTION
	/*-----------------------------------------------------------------------------------*/
	window.$('span.rs-menu-parent').on('click', function(e){
		e.preventDefault();
		
		var t = window.$(this);
		var menu = t.siblings('ul');	
		var parent = t.parent('li');
		var siblings = parent.siblings('li');
		var arrow_target = 'span.rs-menu-parent';
		
		if (menu.hasClass('sub-menu')) { 
      // eslint-disable-next-line
			var menu = t.siblings('ul.sub-menu'); 
		} else if(menu.hasClass('mega-menu')) {
      // eslint-disable-next-line
			var menu = t.siblings('ul.mega-menu');
		}
		
		if (menu.hasClass('visible')) {
			setTimeout(function() { menu.removeClass('visible'); }, 10);	
			t.html(arrow_down);		
		} else {
			setTimeout(function() { menu.addClass('visible'); }, 10);
			t.html(arrow_up);
		}
			
		/*-------------------------------------*/
		/*	CLOSE MENUS
		/*-------------------------------------*/
			
		//Close sub menus
		parent.find('ul.visible').removeClass('visible');	
		
		//Close sub menus parents
		parent.siblings('li').children('ul').removeClass('visible');	
		
		//Close sub menus child parents 
		siblings.find('ul.visible').removeClass('visible');	
		
		/*-------------------------------------*/
		/*	INSERT ARROW DOWN
		/*-------------------------------------*/	
		
		//Insert arrow down in sub menus
		parent.children('ul').find(arrow_target).html(arrow_down);
		
		//Insert arrow down in sub menus parents
		siblings.children(arrow_target).html(arrow_down);
		
		//Insert arrow down in sub menus child parents 
		siblings.find(arrow_target).html(arrow_down);
	}); 
	
	/*-----------------------------------------------------------------------------------*/
	/*	CLOSE BUTTON
	/*-----------------------------------------------------------------------------------*/ 
	window.$('ul.nav-menu div.sub-menu-close').on('click', function(e){
	   e.preventDefault();
		  
	   var a = window.$(this).parent('ul');      
	   a.removeClass('visible');
	   a.siblings('span.rs-menu-parent').html(arrow_down);
	}); 
	
	/*-----------------------------------------------------------------------------------*/
	/*	EFFECTS ON MENU TOGGLE
	/*-----------------------------------------------------------------------------------*/ 
	window.$('a.rs-menu-toggle').on('click', function(e){
		e.preventDefault();	
		var menu_height = window.$('.rs-menu ul').height();
		if (window.$('a.rs-menu-toggle').hasClass('rs-menu-toggle-open')) {		
			window.$('a.rs-menu-toggle').removeClass('rs-menu-toggle-open').addClass('rs-menu-toggle-close');
			window.$('.rs-menu').animate({height:'0px'},{queue:false, duration:300}).addClass('rs-menu-close');	
		} else {			
			window.$('a.rs-menu-toggle').removeClass('rs-menu-toggle-close').addClass('rs-menu-toggle-open');
			window.$('.rs-menu').animate({height:menu_height},{queue:false, duration:300}).removeClass('rs-menu-close');
		}
	});	
	
	/*-----------------------------------------------------------------------------------*/
	/*	CLOSE MENUS ON RESIZE
	/*-----------------------------------------------------------------------------------*/ 
	var window_width = 0;
	 
	window.$(window).on('load', function () {	
		window_width = window.$(window).width();
		window.$('.rs-menu').addClass( "rs-menu-close" );
	});
	
	window.$(window).resize( function(){    
		if(window_width !== window.$(window).width()){		
			window.$('.visible').removeClass('visible');	
			window.$('.rs-menu-toggle').removeClass('rs-menu-toggle-open').addClass( "rs-menu-toggle-close" );	
			window.$('.rs-menu').css( "height", "0" ).addClass( "rs-menu-close" );		
		
			window.$('span.rs-menu-parent').html( arrow_down );		
			window_width = window.$(window).width();	
		}
	});	
  }, 500);
 
}

export const  getLetterInitials = (str) => {
  const name = str.split(" ")
  console.log(name)
  let _name = "";
  for(let x = 0; x < name.length; x++) {
    _name = _name + name[x].charAt(0)
  }
  return _name.toUpperCase();
}

export const getRecipientName=(Name, UserName, IsPrivacy, IsAnonymous)=>{
   if(IsAnonymous&&UserName){
     return[UserName,false]
   }
   if(IsPrivacy){
    return[getLetterInitials(Name),Name]
  }
  return[Name,false]
}