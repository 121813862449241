/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

export const GlobalContext = React.createContext();

export const GlobalContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [featuredCampaings, setFeaturedCampaings] = useState([]);
  const { addToast } = useToasts();

  const setNotifications = (notification) => {
    addToast(notification.text, {
      appearance: notification.type,
      autoDismiss: true,
    }); // Appearance: success, error, warning and info
  };

  const handleLoading = (flag) => {
    setLoading(flag);
  };

  const setLoggedInUser = (user) => {
    setUser(user);
  };

  return (
    <GlobalContext.Provider
      value={{
        user,
        loading,
        featuredCampaings,
        setLoggedInUser: setLoggedInUser,
        setLoading: handleLoading,
        setNotifications: setNotifications,
        setFeaturedCampaings: setFeaturedCampaings,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
