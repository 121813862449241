/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React , { useContext , useEffect , useState } from "react";
import "./AdminDashboard.scss";
import { useHistory } from "react-router-dom";
import { API , AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";

const AdminDashboard = ( props ) => {

  const globalContext = useContext(GlobalContext);
  const [summary, setSummary] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if(globalContext.user.User.Role === 2 ){
      getCampaignSummaryAPI();
    }
  },[]);

  const getCampaignSummaryAPI = () => {
    let serviceParams = {
      api: API.CAMPAIGN_SUMMARY,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          setSummary(result.Data);
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };

  return (<div>
    { globalContext.user.User.Role === 2 ? <div className="container">
      <Meta title={"Admin-Dashboard"} content={ "Admin Dashboard" }/>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h3 className="dash-heading mb-0">Admin Dashboard</h3>
      </div>
      <div className="row mx-10-row">
        <div
          onClick={() => history.push("/admin-campaigns?status=2")}
          className="col-xl-3 col-lg-6 col-md-12 col-12 "
        >
          <div className="dashboard-cards dashboard-cards1">
            <div className="dashboard-cards-r">
              <h4>Open Campaigns</h4>
              <h5>{summary && summary.OpenCampaigns}</h5>
            </div>
          </div>
        </div>
        <div
          onClick={() => history.push("/admin-campaigns?status=1")}
          className="col-xl-3 col-lg-6 col-md-12 col-12 "
        >
          <div className="dashboard-cards dashboard-cards2">
            <div className="dashboard-cards-r">
              <h4>Pending Campaigns</h4>
              <h5>{summary && summary.PendingCampaigns}</h5>
            </div>
          </div>
        </div>
        <div
          onClick={() => history.push("/admin-campaigns?status=0")}
          className="col-xl-3 col-lg-6 col-md-12 col-12 "
        >
          <div className="dashboard-cards dashboard-cards3">
            <div className="dashboard-cards-r">
              <h4>Featured Campaigns</h4>
              <h5>{summary && summary.FeaturedCampaigns}</h5>
            </div>
          </div>
        </div>
        <div
          onClick={() => history.push("/admin-campaigns?status=4")}
          className="col-xl-3 col-lg-6 col-md-12 col-12 "
        >
          <div className="dashboard-cards dashboard-cards4">
            <div className="dashboard-cards-r">
              <h4>Completed Campaigns</h4>
              <h5>{summary && summary.CompletedCampaigns}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
  </div>);
}

export default withAPIRequest( AdminDashboard );
