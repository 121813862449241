import { useEffect, useState,useContext,useRef } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import   "./ContactUs.scss";
import { API, AXIOS_METHOD_TYPES } from "../../configs/constants";
import { withAPIRequest } from "../HOC/withAPIRequest";
import { GlobalContext } from "../../context/GlobalContext";

import PropTypes from "prop-types";
const ContactUsPopup = (props) => {
  const [show, setShow] = useState(false);
  const formRef=useRef();

  const closeHandler = (e) => {
    setShow(false);
    props.onClose(false);
    formRef.current?.resetForm()
  };

  useEffect(() => {
    setShow(props.show);
    // debugger
  }, [props.show]);


  const globalContext = useContext(GlobalContext);
  const [email, setEmail] = useState("");

  const callLoginAPI = (params, resetForm) => {
    // debugger
    let serviceParams = {
      api: API.PUBLIC_CONTACT_US,
      method: AXIOS_METHOD_TYPES.POST,
      params: {
        Name: params.Name,
        Email: params.Email,
        Phone: params.Phone,
        Subject: params.Subject,
        Message: params.Message,
      },
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          resetForm();
          globalContext.setNotifications({
            type: "success",
            text: result.Status.Message,
          });
          setShow(false);
    props.onClose(false);
        }
      }
    });
  };


  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0"
      }}
      className={"custom-pop-up-overlay"}
    >
   <div className="contact-page-section contact-page-popup-section">
      <div className={"custom-pop-up-popup"}>
      <div className="inner-part">
      <div className="row align-items-center">
      <div className="col-lg-12">
                  <h6 className="title mb-mb-15">Get In Touch</h6>
                  </div>
                 </div>
                </div>
        <span className={"close"} onClick={closeHandler}>
          &times;
        </span>
        <div className={"content"}>
           
            <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="contact-comment-box">
                <div className="inner-part">
                  <p>Someone will respond to you within 48 hours.</p>

                </div>
                <div className="contact-us-form-required">
                  * required
                </div>
                <div id="form-messages"></div>
                <Formik
                  innerRef={formRef}
                  initialValues={{
                    Name: "",
                    Email: "",
                    Phone: "",
                    Subject: "",
                    Message: "",
                  }}
                  validationSchema={Yup.object().shape({
                    Name: Yup.string().required("Required"),
                    Email: Yup.string()
                      .email("Invalid email")
                      .required("Required"),
                    Subject: Yup.string().required("Required"),
                    Message: Yup.string().required("Required"),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    callLoginAPI(values, resetForm);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    isSubmitting,
                  }) => (
                    <Form id="contact-form" method="post" action="#">
                      <fieldset>
                        <div className="row">
                          <div className="col-lg-6 mb-8 col-md-6 col-sm-6">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.Name}
                              className="from-control"
                              type="text"
                              id="Name"
                              name="Name"
                            //  placeholder="Name"
                              required=""
                            />
                          <label className={"contact-us-form-label"}>Name <span className={"contact-us-form-label-required"}>*</span>
                          </label>

                          </div>
                          <div className="col-lg-6 mb-8 col-md-6 col-sm-6">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.Email}
                              className="from-control"
                              type="text"
                              id="Email"
                              name="Email"
                              //placeholder="Email"
                              required=""
                            />
                            <label className={"contact-us-form-label"}>Email <span className={"contact-us-form-label-required"}>*</span></label>
                            {errors.Email && errors.Email=="Invalid email" && touched.Email && (
                            <span className={"contact-us-form-field-error"}>{errors.Email}</span>
                            )}
                          </div>
                          <div className="col-lg-6 mb-8 col-md-6 col-sm-6">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.Phone}
                              className="from-control"
                              type="text"
                              id="Phone"
                              name="Phone"
                             // placeholder="Phone"
                            />
                            <label className={"contact-us-form-label"}>Phone 
                           </label>
                         
                          </div>
                          <div className="col-lg-6 mb-8 col-md-6 col-sm-6">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.Subject}
                              className="from-control"
                              type="text"
                              id="Subject"
                              name="Subject"
                              //placeholder="Subject"
                              required=""
                            />
                           <label className={"contact-us-form-label"}>Subject <span className={"contact-us-form-label-required"}>*</span>
                           </label>
                            
                          </div>
                          <div className="col-lg-12 mb-20">
                            <textarea
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.Message}
                              className="from-control"
                              id="Message"
                              name="Message"
                             // placeholder=" Message"
                              required=""
                            ></textarea>
                            <label className={"contact-us-form-label"}>Message <span className={"contact-us-form-label-required"}>*</span>
                          </label>
                           
                          </div>
                        </div>
                        <div className="form-group mb-0">
                          <input
                            className="btn-send"
                            type="submit"
                            value="Submit Now"
                          />
                        </div>
                      </fieldset>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
    </div>
  );
};

ContactUsPopup.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withAPIRequest(ContactUsPopup);
