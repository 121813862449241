import React from 'react';
import { withRouter } from "react-router-dom";
import { Route, Redirect } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const globalContext = React.useContext(GlobalContext);
    const isLogin = globalContext.user !== null;

    return (
        <Route {...rest} render={props => {
            return (
                isLogin ?
                    <Component {...props} />
                    : <Redirect to="/login" />
            )
        }} />
    );
};

export default withRouter(PrivateRoute);

