import React, { useContext } from "react";
import "./ForgotPassword.scss";
import { useHistory } from "react-router-dom";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const ForgotPassword = ( props ) => {

  const globalContext = useContext(GlobalContext);
  let history = useHistory();

  const callForgotPasswordAPI = (params) => {
    let serviceParams = {
      api: API.FORGOT_PASSWORD,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };
    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          history.goBack();
          globalContext.setNotifications({
            id: Math.random(),
            type: "success",
            text: "Please check your inbox to reset password",
          });
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };

  return (
    <div className="container">
      <Meta title={ "Forgot-Password" } content={ "Forgot password" }/>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
          <div className="form-div form-pad">
            <div className="inner-part text-center">
              <h2 className="title mb-5">Forgot password</h2>
            </div>
            <div id="form-messages"></div>
            <Formik
              initialValues={{
                Email: "",
              }}
              validationSchema={Yup.object().shape({
                Email: Yup.string()
                  .email("Invalid email address")
                  .required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                callForgotPasswordAPI(values);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <div className="row">
                    <div className="col-12 mb-20">
                      <input
                        className="from-control"
                        type="text"
                        placeholder="Enter email used to register"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Email}
                        id="Email"
                        name="Email"
                        required=""
                      />
                      {errors.Email && touched.Email && errors.Email}
                    </div>
                  </div>
                  <div className="mb-20">
                    <input className="btn-send" type="submit" value="Submit" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAPIRequest( ForgotPassword );
