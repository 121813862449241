/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import { sortByName,getLetterInitials,getRecipientName } from '../../../configs/utility';
import moment from "moment";
import "./DonorAllCampaigns.scss";
import Meta from "../../common/meta/Meta";

const DonorAllCampaigns = (props) => {

  const globalContext = useContext(GlobalContext);
  const [campaigns, setcampaigns] = useState([]);
  const [initialCampaign, setInitialCampaign] = useState([]);
  const [resources, setResources] = useState([]);
  const [favouriteCompaigns, setFavouriteCompaigns] = useState([]);
  let history = useHistory();

  useEffect(() => {
    setcampaigns([]);
    let params = { Status: [2] }
    callRequesterAPI(params);
    getResource();
    getFavouriteCampaignDetails();
  }, []);

  const callRequesterAPI = (params) => {
    let serviceParams = {
      api: API.CAMPAIGNS,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          setcampaigns(result.Data ? sortByName(result.Data, true) : []);
          setInitialCampaign(result.Data ? sortByName(result.Data, true) : []);
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };

  const getResource = () => {
    let serviceParams = {
      api: API.CAMPAIGN_UNIQUE_RESOURCES,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          setResources(result.Data)
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };

  const getFavouriteCampaignDetails = () => {
   
    let serviceParams = {
      api: `${API.GET_FAVOURITE_CAMPAIGN}?campaignId=`,
      method: AXIOS_METHOD_TYPES.GET
    };

    props.commonAPIRequest(serviceParams, (result) => {
      // // debugger
      if (result && result.Status.StatusId === 1) {
       setFavouriteCompaigns(result.Data)
      }
    },true);
  };



  const filterResource = async (name) => {
    // // debugger
    if(name === 'all'){
      setcampaigns(initialCampaign);
    }
    else if(name === 'favourite'){
      const campaigns = []
      await initialCampaign.forEach((item) => {
        // // debugger
          if (favouriteCompaigns&&favouriteCompaigns.filter(x=>x.CampaignId==item.Id&&x.IsFavourite).length)
          {
           campaigns.push(item);
          }
      
      });
      const uniqueCampaigns = await [...new Set(campaigns.map(item => item))];
      setcampaigns(uniqueCampaigns)
    }
    else {
      const campaigns = []
      await initialCampaign.forEach((item) => {
        let arr = item.Resources;
        arr.forEach((e, index) => {
          if (e.Name === name) campaigns.push(item);
        })
      });
      const uniqueCampaigns = await [...new Set(campaigns.map(item => item))];
      setcampaigns(uniqueCampaigns)
    }
  };

  return (
    <div className="container">
      <Meta title={"Donor-Campaigns"} content={"Donor campaigns"} />
      <div>
        <div
          id="rs-categories"
          className="rs-categories main-home pt-90 md-pt-60 md-pb-40"
        >
          <div className="container">
            <div
              id="homeFeaturedStories"
              className="sec-title3 text-center mb-45"
            >
              <h2 className="title black-color">Featured Campaigns </h2>
            </div>
            <div className="row mb-35">
              {globalContext.featuredCampaings.map((campaign, index) => (
               <div key={index} className="col-lg-4 col-md-6 mb-30">
                  <div className="categories-items">
                    <div className="cate-images">
                    {campaign.IsPrivacy?
                    <div className="featured-image featured-image-text"><h2 class="title black-color text-light">{getLetterInitials(campaign.RequesterName)}</h2></div>:
                      <a href="#">
                        <img  src={campaign.ImageLink ? campaign.ImageLink : "assets/images/logo.png"} alt="default-image"  className="featured-image"  />
                      </a>}
                    </div>
                    <div className="contents">
                      <div className="content-wrap">
                        <h2 className="title">
                          <Link to={`/donor-campaign-details/${campaign.Id}`}>
                            {campaign.Name}
                          </Link>
                        </h2>
                        <Link className="course-qnty" to={`/donor-campaign-details/${campaign.Id}`}>
                          View Details</Link>
                      </div>
                    </div>
                  </div>
               </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="text-center"
      >
        <img src={"assets/images/downarrow.png"} alt="default-image" style={{ width: 30, height: 30 }} />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-div">
            <div className="d-flex justify-content-between mb-5 ">
              <h3 className="dash-heading mb-0">All Campaigns</h3>
              <div className="col-sm-5 col-md-2 col-lg-2 p-0" style={{ fontSize: 12 }}>
                <select
                  className="from-control max-w-150"
                  onChange={(e) => {
                    e.preventDefault();
                    filterResource(e.target.value);
                  }}
                >
                  
                  <option value='all' key={'all'}>All</option>
                  <option value='favourite' key={'favourite'}>Favourites</option>
               
                  <option disabled className={"option-divider"}>──────────────────</option>
                
                  {resources.map((item, index) => (
                    <>
                      <option value={item.id} key={index}> {item} </option>
                    </>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="client-table">
                  <div className="table-responsive">
                    <table className="table custom-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Recipient Name</th>
                          <th>Campaign Name</th>
                          <th>Resources</th>
                          <th>Campaign Status</th>
                          <th>Ending date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaigns.map(({
                          IsFeatured,
                          RequesterName,
                          AddedByName,
                          AddedOn,
                          Name,
                          StatusName,
                          EndDate,
                          Resources,
                          Id,
                          IsPrivacy,
                          RequesterUserName,
                          IsRecipientAnonymous
                        }, index) => (
                            <tr key={index} className={`${IsFeatured && "table-active"}`}>
                              <td>
                              <i className={"fa fa-heart-icon "+ (favouriteCompaigns&&favouriteCompaigns.filter(x=>x.CampaignId==Id&&x.IsFavourite).length?"fa-heart":"fa-heart-o")} 
                                style={{}}
                             />
                              </td>
                            <td>
                              <p className={getRecipientName(RequesterName,RequesterUserName,IsPrivacy,IsRecipientAnonymous)[1] ? "initial-text" : ""}>
                                {getRecipientName(RequesterName,RequesterUserName,IsPrivacy,IsRecipientAnonymous)[0]}
                              </p>
                            </td>
                              <td>
                                <div className="row align-items-center">
                                  <div className="col-lg-12 col-xl-6 ">
                                    <p>{Name}</p>
                                  </div>
                                  <div className="col-lg-12 col-xl-6 ">
                                    {IsFeatured && <label
                                      className={`no-wrap ${IsFeatured && "feature-bg mr-4"}`}
                                    >
                                      {"Featured"}
                                    </label>}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="chip-holder">
                                  {Resources && Resources
                                    ? Resources.map(
                                      (resource, index) => (
                                        <small
                                          key={index}
                                          className="chip cursor-default"
                                        >
                                          {resource.Name}
                                        </small>
                                      )
                                    )
                                    : "N/A"}
                                </div>
                              </td>
                              <td>
                                <p>{StatusName}</p>
                              </td>
                              <td>
                                <p>
                                  {moment(EndDate).format("MM/DD/YYYY")}
                                </p>
                              </td>
                              <td>
                                <div
                                  className="btn table-sitebtn no-wrap"
                                  onClick={() =>
                                    history.push(
                                      `/donor-campaign-details/${Id}`
                                    )
                                  }
                                >
                                  View Details >>
                              </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAPIRequest(DonorAllCampaigns);
