/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import {
  API,
  AXIOS_METHOD_TYPES,
  USER_STATUS,
} from "../../../configs/constants";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";
import "./AdminDonorDetails.scss";

const AdminDonorDetails = (props) => {

  const globalContext = useContext(GlobalContext);
  const { id } = useParams();
  const [donorDetails, setDonorDetails] = useState({});
  const [campaignResources, setCampaignResources] = useState([]);
  const [requesterStatus, setRequesterStatus] = useState("");

  useEffect(() => {
    if (globalContext.user.User.Role === 2) {
      (async () => {
        await getDonorDetails(id);
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, []);

  const getDonorDetails = async (id) => {
    let serviceParams = {
      api: `${API.DONOR_DETAILS}${id}`,
      method: AXIOS_METHOD_TYPES.GET,
    };
    await globalContext.setLoading(true);
    await props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        setRequesterStatus(result.Data.StatusName);
        setDonorDetails(result.Data);
        setCampaignResources(result.Data.CampaignResources);
      } else {
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "Details not found",
        });
      }
    });
  };

  const updateStatus = async (statusId) => {
    let serviceParams = {
      api: `${API.UPDATE_USER_STATUS}`,
      method: AXIOS_METHOD_TYPES.POST,
      params: {
        UserId: id,
        Status: statusId,
      },
    };
    await globalContext.setLoading(true);
    await props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        USER_STATUS.forEach(({ id, text }) => {
          if (id === statusId) {
            setRequesterStatus(text);
            globalContext.setNotifications({
              type: "success",
              text: "Status Updated Successfully",
            });
            return;
          }
        });
      } else {
        globalContext.setNotifications({
          type: "error",
          text: result ? result.Status.Message : "Error While Updating Status",
        });
      }
    });
  };

  return (
    <div className="container">
      <Meta title={"Admin-Donor-Details"} content={"Admin donor details"} />
      {globalContext.user.User.Role === 2 ? <div className="row">
        <div className="col-12">
          <div className="form-div details-page-holder">
            <div className="d-flex justify-content-between">
              <h3 className="dash-heading mb-0">{`${donorDetails.FirstName} ${donorDetails.LastName}`}</h3>
            </div>
            <div className="row">
              <div className="col-12 col-lg-3 col-md-4 text-center">
                <div className="details-img-holder">
                  <div className="details-img mb-10">
                    <img
                      className="user"
                      src={donorDetails.ProfilePic}
                      alt="donor"
                    />
                  </div>
                  <Link to={{ pathname: '/change-personal-details', state: { profilePic: donorDetails.ProfilePic, userId: donorDetails.Id } }}>Update profile image</Link>
                </div>
              </div>
              <div className="col-12 col-lg-9 col-md-8 details-holder">
                <div className="row">
                  <div className="col-12 sm-mt-15">
                    <div className="d-flex align-items-center flex-wrap mb-3 mr-3 ">
                      <p className="mr-4">
                        <strong>Status</strong>
                      </p>
                      <label>
                        <div className="dropdown">
                          <button
                            className="btn chips-box grn-bg  mb-3 mr-3 mr-sm-5 dropdown-toggle"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {requesterStatus}
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {USER_STATUS.map(({ text, id }, index) => {
                              if (requesterStatus !== text) {
                                return (
                                  <button
                                    key={index}
                                    className="dropdown-item"
                                    onClick={() => updateStatus(id)}
                                  >
                                    {text}
                                  </button>
                                );
                              } else {
                                return false;
                              }
                            })}
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="d-flex">
                      <p className="mb-2 mr-4">
                        <strong>Email</strong>
                      </p>
                      <p>{donorDetails ? donorDetails.Email : ""}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-9 col-xl-9 mt-3">
                    <h4 className="mb-4"> List of Campaign resources </h4>
                    <div className="table-responsive">
                      <table className="table custom-table">
                        <thead>
                          <tr>
                            <th>Resource Name</th>
                            <th>Campaign Name </th>
                            <th>Status </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {campaignResources ? (
                            campaignResources.map(
                              (
                                {
                                  ResourceName,
                                  CampaignName,
                                  StatusName,
                                  UpdatedOn,
                                  CampaignId,
                                  IsConfirmed
                                },
                                index
                              ) => (
                                <tr key={index}>
                                  <td>
                                    <p>{ResourceName}</p>
                                  </td>
                                  <td>
                                    <p>{CampaignName}</p>
                                  </td>
                                  <td>
                                    <p>{IsConfirmed ? 'Confirmed' : 'Pending'}</p>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/admin-campaign-details/id/${CampaignId}`}
                                      className="btn table-sitebtn"
                                    >
                                      View
                                    </Link>{" "}

                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td>
                                <p>Campaigns not found</p>
                              </td>
                              <td />
                              <td />
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <div style={{ width: '100%', alignItems: 'center', textAlign: 'center', fontSize: 24, color: 'red' }}>Access Deined</div>}
    </div>
  );
};

export default withAPIRequest(AdminDonorDetails);
