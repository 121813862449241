/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React , { useContext , useEffect , useState } from "react";
import "./AdminRequesters.scss";
import { Link , useHistory } from "react-router-dom";
import { API , AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";
import moment from "moment";

const AdminRequesters = ( props ) => {

  const globalContext = useContext(GlobalContext);
  const [isRecipientDetail, setIsRecipientDetail] = useState(false);
  const [requesters, setRequesters] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  let history = useHistory();

  useEffect(() => {
    if(globalContext.user.User.Role === 2 ){
        callRequesterAPI()
    }
  }, []);

  const callRequesterAPI = () => {
    let serviceParams = {
      api: API.REQUESTERS,
      method: AXIOS_METHOD_TYPES.GET,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          setRequesters(result.Data.requesters);
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };
  const callResendInviteRequesterAPI = (Id) => {
    // debugger
    let serviceParams = {
      api: API.RESEND_REQUESTER_INVITATION,
      method: AXIOS_METHOD_TYPES.POST,
      params:{Id:Id}
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          globalContext.setNotifications({
            id: Math.random(),
            type: "success",
            text: "Invitation resent to recipient"
        });
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };
  return (
    <div className="container">
      <Meta title={ "Admin-Requesters" } content={ "Admin requesters" }/>
      { globalContext.user.User.Role === 2 ?  <div className="row">
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Recipient Details</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div>
                  <div className="d-flex align-items-center flex-wrap">
                      <p className="mb-2 mr-3">
                        <strong> Name: </strong>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap ml-4">
                        <li>
                          <label>
                            {selectedRecipient ?  `${selectedRecipient.FirstName} ${selectedRecipient.LastName}` : "N/A"}
                          </label>
                        </li>
                      </ul>
                  </div>
                  <div className="d-flex align-items-center flex-wrap">
                      <p className="mb-2 mr-3">
                        <strong> Email: </strong>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap ml-4">
                        <li>
                          <label>
                            {selectedRecipient ? selectedRecipient.Email : "N/A"}
                          </label>
                        </li>
                      </ul>
                  </div>
                  <div className="d-flex align-items-center flex-wrap">
                      <p className="mb-2 mr-3">
                        <strong> Phone: </strong>
                      </p>
                      <ul className="dash-check-list resources-list d-flex flex-wrap ml-4">
                        <li>
                          <label>
                            {selectedRecipient ? selectedRecipient.Phone : "N/A"}
                          </label>
                        </li>
                      </ul>
                  </div>           
                  <p className="mb-2 mr-3">
                    <strong> Payment: </strong>
                  </p>
                  <div className="client-table">
                        <div className="table-responsive">
                          <table className="table custom-table">
                            <thead>
                              <tr>
                                <th>Platform Name</th>
                                <th>URL</th>
                              
                              </tr>
                            </thead>
                            <tbody>
                              {selectedRecipient&&selectedRecipient.UserPaymentOption.map(
                                (item, index) => (
                                  <tr key={item.PaymentPlatformId}>
                                    <td>
                                      <p>{item.PaymentPlatformName}</p>
                                    </td>
                                    <td>
                                       <p>{item.PaymentUrl}</p>

                                    </td>
                                   
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        <div className="col-12">
          <div className="form-div">
            <div className="d-flex justify-content-between mb-5">
              <div>
                <h3 className="dash-heading mb-0">List of Recipients</h3>
              </div>
              <div>
                <a
                    className="btn site-btn text-nowrap align-items-center d-flex text-white"
                    onClick={ () => history.push( "/admin-create-requester" ) }
                >
                  Add New
                </a>
              </div>
            </div>
            <div className="input-group mb-2">
              <label className="check-style">
                Recipients Detail
                <input
                  type="checkbox"
                  //   onBlur={handleBlur}
                  checked={isRecipientDetail}
                  onChange={() => { setIsRecipientDetail(!isRecipientDetail) }}
                  id="IsAnonymous"
                  name="IsAnonymous"
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {!isRecipientDetail ?
              <div className="row">
                <div className="col-12">
                  <div className="client-table">
                    <div className="table-responsive">
                      <table className="table custom-table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Campaign</th>
                            <th>Status</th>
                            <th>Campaign Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {requesters.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <p>
                                  {item.FirstName} {item.LastName}
                                </p>
                              </td>
                              <td>
                                {!item.IsJoined &&
                                  <>
                                    <label className="grn-bg chip text-white">Invitation sent</label>
                                    <a
                                      className="btn table-sitebtn text-white"
                                      onClick={() => callResendInviteRequesterAPI(item.Id)}
                                    >
                                      Resend Invite
                                    </a>
                                  </>
                                }
                                {item.Campaigns && (
                                  <div className="chip-holder">
                                    {item.Campaigns.map((campaign, index) => (
                                      <div className="chip" key={index}>
                                        {campaign.CampaignName}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </td>
                              <td>
                                <p>{item.StatusName}</p>
                              </td>
                              <td>
                                <p>{moment(item.AddedOn).format("MM/DD/YYYY")}</p>
                              </td>
                              <td className={"d-flex justify-content-between hover"}>
                                <Link
                                  to={`/admin-requester-details/id/${item.Id}`}
                                  className="btn table-sitebtn"
                                >
                                  View
                                </Link>
                                {item.StatusName === "Active" && <Link
                                  to="/admin-create-campaign"
                                  className="btn table-sitebtn"
                                >
                                  +
                                </Link>}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              :  <div className="row">
              <div className="col-12">
                <div className="client-table">
                  <div className="table-responsive">
                    <table className="table custom-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Payment Options</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requesters.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <p>
                                {item.FirstName} {item.LastName}
                              </p>
                            </td>
                            <td>
                              {item.Email}
                            </td>
                            <td>
                             {item.Phone}
                            </td>
                            <td>
                            {item.UserPaymentOption && (
                                  <div className="chip-holder">
                                    {item.UserPaymentOption.map((option, index) => (
                                      
                                      <div className="chip cursor" key={index}  data-toggle="modal" data-target="#exampleModal" onClick={()=>{
                                        setSelectedRecipient(item)
                                      }}>
                                        {option.PaymentPlatformName}
                                      </div>
                                    ))}
                                  </div>
                                )}
                            </td>
                           
                            <td className={"d-flex justify-content-between hover"}>
                              <Link
                                to={`/admin-requester-details/id/${item.Id}`}
                                className="btn table-sitebtn"
                              >
                                View
                              </Link>
                             
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
    </div>
  );
}

export default withAPIRequest( AdminRequesters );
