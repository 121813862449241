/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState, useRef } from "react";
import "./AdminCampaignDashboard.scss";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Link } from "react-router-dom";

const AdminCampaignDashboard = (props) => {
  const globalContext = useContext(GlobalContext);
  const [summary, setSummary] = useState([]);
  const [overallsummary, setOverAllSummary] = useState([]);
  const formRef = useRef();

  useEffect(() => {
    if (globalContext.user.User.Role === 2) {
      getCampaignOpenSummaryAPI(null, null, 1);
    }
  }, []);

  const getCampaignOpenSummaryAPI = (
    startDate = null,
    endDate = null,
    status = null
  ) => {
    getCampaignSummaryAPI(startDate, endDate);
    let serviceParams = {
      api: API.CAMPAIGN_RESOURCES_OPEN_SUMMARY,
      method: AXIOS_METHOD_TYPES.POST,
      params: {
        PaymentStatus: status,
        StartDate: startDate,
        EndDate: endDate,
      },
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          setSummary(result.Data);
        } else {
        }
      }
    });
  };
  const getCampaignSummaryAPI = (startDate, endDate) => {
    let serviceParams = {
      api: API.CAMPAIGN_RESOURCES_SUMMARY,
      method: AXIOS_METHOD_TYPES.POST,
      params: {
        StartDate: startDate,
        EndDate: endDate,
      },
    };

    globalContext.setLoading(false);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          setOverAllSummary(result.Data);
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };

  const getTitle = (type, isUser) => {
    var status = formRef.current.values.Status;
    //type=1 Cash
    if (type == 1 && !isUser) {
      return `Open Campaigns${
        status == 1 ? " Confirmed " : status == 0 ? " Non Confirmed " : ""
      } Committed - Cash`;
    }
    if (type == 2 && !isUser) {
      return `Open Campaigns${
        status == 1 ? " Confirmed " : status == 0 ? " Non Confirmed " : ""
      } Committed - Resources`;
    }

    if (type == 1 && isUser) {
      return `Open Campaigns Cash${
        status == 1 ? " Confirmed " : status == 0 ? " Non Confirmed " : ""
      } Committed - Users`;
    }
    if (type == 2 && isUser) {
      return `Open Campaigns Resources${
        status == 1 ? " Confirmed " : status == 0 ? " Non Confirmed " : ""
      } Committed - Users `;
    }
  };

  return (
    <div>
      {globalContext.user.User.Role === 2 ? (
        <div className="container">
          <Meta title={"Campaigns-Dashboard"} content={"Campaigns Dashboard"} />
          <div className="d-flex justify-content-between align-items-center mb-5">
            <h3 className="dash-heading mb-0">Campaigns Dashboard</h3>
          </div>
          <div className="form-div">
            <Formik
              innerRef={formRef}
              initialValues={{
                StartDate: "",
                EndDate: "",
                Status: "1",
              }}
              validationSchema={Yup.object().shape({
                StartDate: Yup.string(),
                EndDate: Yup.string(),
                Status: Yup.string(),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                debugger;
                getCampaignOpenSummaryAPI(
                  values.StartDate,
                  values.EndDate,
                  values.Status
                );
                getCampaignSummaryAPI(values.StartDate, values.EndDate);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue,
                handleSubmit,
                handleReset,
              }) => (
                <Form>
                  <div className="row">
                    <div className="col-12 col-md-3 mb-30 sm-mt-15">
                      <DatePicker
                        value={values.StartDate}
                        onChange={(e) => {
                          setFieldValue(
                            "StartDate",
                            moment(e).format("MM/DD/YYYY"),
                            handleChange(moment(e).format("MM/DD/YYYY"))
                          );
                        }}
                        onBlur={handleBlur}
                        placeholderText="Start date"
                        className="from-control"
                        id="StartDate"
                        name="StartDate"
                      />

                      <span className="text-danger">
                        {errors.EndDate && touched.EndDate && errors.EndDate}
                      </span>
                    </div>
                    <div className="col-12 col-md-3 mb-30 sm-mt-15">
                      <DatePicker
                        value={values.EndDate}
                        onChange={(e) => {
                          setFieldValue(
                            "EndDate",
                            moment(e).format("MM/DD/YYYY"),
                            handleChange(moment(e).format("MM/DD/YYYY"))
                          );
                        }}
                        onBlur={handleBlur}
                        placeholderText="End date"
                        className="from-control"
                        id="EndDate"
                        name="EndDate"
                      />
                      <span className="text-danger">
                        {errors.EndDate && touched.EndDate && errors.EndDate}
                      </span>
                    </div>
                    {/* <div className="col-12 col-md-3">
                      <select
                        className="from-control"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.Status}
                        id="Status"
                        name="Status"
                      >
                        <option value={-1} key={1}>
                          All
                        </option>
                        <option value={1} key={1}>
                          Confirmed
                        </option>
                        <option value={0} key={0}>
                          Pending
                        </option>
                      </select>
                      <span className="text-danger">
                        {errors.Status && touched.Status && errors.Status}
                      </span>
                    </div> */}
                    <div className="col-12 col-md-1 mt-2 sm-mt-15">
                      <a
                        className="btn site-btn text-nowrap sm-mt-4 text-center"
                        onClick={() => {
                          handleSubmit();
                        }}
                        style={{ color: "#fff" }}
                      >
                        Search
                      </a>
                    </div>
                    <div className="col-12 col-md-1 mt-2 sm-mt-15">
                      <a
                        className="btn site-btn text-nowrap sm-mt-4 text-center"
                        onClick={() => {
                          handleReset();
                          getCampaignOpenSummaryAPI(null, null, 1);
                        }}
                        style={{ color: "#fff" }}
                      >
                        Clear
                      </a>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/* <div className="container text-center">
            <h6 class="sub-title">Open Campaigns</h6>
          </div>
          <div className="row mx-10-row">
            <div className="col-xl-3 col-lg-6 col-md-12 col-12">
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-donotion-summary?PaymentStatus=${
                    formRef.current.values.Status
                  }&StartDate=${formRef.current.values.StartDate}&EndDate=${
                    formRef.current.values.EndDate
                  }&IsOpen=true&IsCash=true&title=${getTitle(1)}&Name=${
                    summary && summary.CashOpenCampaingQuantity
                      ? "$" + summary.CashOpenCampaingQuantity
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards1">
                  <div className="dashboard-cards-r">
                    <h4>Cash Committed</h4>
                    <h5>
                      $
                      {summary && summary.CashOpenCampaingQuantity
                        ? summary.CashOpenCampaingQuantity
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-12 col-12 "
            >
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-campaign-donor-details?PaymentStatus=${
                    formRef.current.values.Status
                  }&StartDate=${formRef.current.values.StartDate}&EndDate=${
                    formRef.current.values.EndDate
                  }&IsOpen=true&IsCash=true&title=${getTitle(1, true)}&Name=${
                    summary && summary.CashOpenCampaingQuantity
                      ? "$" + summary.CashOpenCampaingQuantity
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards2">
                  <div className="dashboard-cards-r">
                    <h4>Cash Committed Users</h4>
                    <h5>
                      {summary && summary.CashCommittedUsers
                        ? summary.CashCommittedUsers
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-12 col-12 "
            >
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-donotion-summary?PaymentStatus=${
                    formRef.current.values.Status
                  }&StartDate=${formRef.current.values.StartDate}&EndDate=${
                    formRef.current.values.EndDate
                  }&IsOpen=true&title=${getTitle(2)}&Name=${
                    summary && summary.NonCashOpenCampaingQuantity
                      ? summary.NonCashOpenCampaingQuantity
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards3">
                  <div className="dashboard-cards-r">
                    <h4>Resources Committed</h4>
                    <h5>
                      {summary && summary.NonCashOpenCampaingQuantity
                        ? summary.NonCashOpenCampaingQuantity
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-12 col-12 "
            >
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-campaign-donor-details?PaymentStatus=${
                    formRef.current.values.Status
                  }&StartDate=${formRef.current.values.StartDate}&EndDate=${
                    formRef.current.values.EndDate
                  }&IsOpen=true&title=${getTitle(2, true)}&Name=${
                    summary && summary.NonCashOpenCampaingQuantity
                      ? summary.NonCashOpenCampaingQuantity
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards4">
                  <div className="dashboard-cards-r">
                    <h4>Resources Committed Users</h4>
                    <h5>
                      {summary && summary.NonCashCommittedUsers
                        ? summary.NonCashCommittedUsers
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div> */}
          {/* <div className="container text-center">
            <h5 class="sub-title"> Overall Committed Resources</h5>
          </div>
          <div className="container text-center">
            <h6 class="sub-title"> Cash</h6>
          </div> */}
          <div className="row mx-10-row">
            <div
              className="col-xl-3 col-lg-6 col-md-12 col-12 "
            >
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-donotion-summary?PaymentStatus=1&IsCash=true&title=${"Donations"}&StartDate=${
                    formRef.current.values.StartDate
                  }&EndDate=${formRef.current.values.EndDate}&Name=${
                    overallsummary && overallsummary.SummaryConfirmedCash
                      ? "$" + overallsummary.SummaryConfirmedCash
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards1 ">
                  <div className="dashboard-cards-r">
                    {/* <h4>Confirmed Committment - Cash</h4> */}
                    <h4>Donations</h4>
                    <h5>
                      $
                      {overallsummary && overallsummary.SummaryConfirmedCash
                        ? overallsummary.SummaryConfirmedCash
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-12 col-3 "
            >
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-campaign-donor-details?PaymentStatus=1&IsCash=true&title=${"Donators"}&StartDate=${
                    formRef.current.values.StartDate
                  }&EndDate=${formRef.current.values.EndDate}&Name=${
                    overallsummary && overallsummary.SummaryConfirmedCash
                      ? "$" + overallsummary.SummaryConfirmedCash
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards2 ">
                  <div className="dashboard-cards-r">
                    {/* <h4>Confirmed Committment Users - Cash</h4> */}
                    <h4>Donators</h4>
                    <h5>
                      {overallsummary &&
                      overallsummary.SummaryConfirmedCashUsers
                        ? overallsummary.SummaryConfirmedCashUsers
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            {/* <div
              className="col-xl-3 col-lg-6 col-md-12 col-12 "
            >
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-donotion-summary?PaymentStatus=0&IsCash=true&title=${"Non Confirmed Committment - Cash"}&StartDate=${
                    formRef.current.values.StartDate
                  }&EndDate=${formRef.current.values.EndDate}&Name=${
                    overallsummary && overallsummary.SummaryNonConfirmedCash
                      ? "$" + overallsummary.SummaryNonConfirmedCash
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards3">
                  <div className="dashboard-cards-r">
                    <h4>Non Confirmed Committment - Cash</h4>
                    <h5>
                      $
                      {overallsummary && overallsummary.SummaryNonConfirmedCash
                        ? overallsummary.SummaryNonConfirmedCash
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-12 col-3 "
            >
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-campaign-donor-details?PaymentStatus=0&IsCash=true&title=${"Non Confirmed Committment Users - Cash"}&StartDate=${
                    formRef.current.values.StartDate
                  }&EndDate=${formRef.current.values.EndDate}&Name=${
                    overallsummary && overallsummary.SummaryNonConfirmedCash
                      ? "$" + overallsummary.SummaryNonConfirmedCash
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards4">
                  <div className="dashboard-cards-r">
                    <h4>Non Confirmed Committment Users - Cash</h4>
                    <h5>
                      {overallsummary &&
                      overallsummary.SummaryNonConfirmedCashUsers
                        ? overallsummary.SummaryNonConfirmedCashUsers
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="container text-center">
            <h6 class="sub-title">Other Resources</h6>
          </div>

          <div className="row mx-10-row">
            <div
             className="col-xl-3 col-lg-6 col-md-12 col-12 "
            >
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-donotion-summary?PaymentStatus=1&title=${"Confirmed Committment - Resources"}&StartDate=${
                    formRef.current.values.StartDate
                  }&EndDate=${formRef.current.values.EndDate}&Name=${
                    overallsummary && overallsummary.SummaryConfirmedNonCash
                      ? overallsummary.SummaryConfirmedNonCash
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards1 ">
                  <div className="dashboard-cards-r">
                    <h4>Confirmed Committment - Resources</h4>
                    <h5>
                      {overallsummary && overallsummary.SummaryConfirmedNonCash
                        ? overallsummary.SummaryConfirmedNonCash
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-12 col-3 "
            >
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-campaign-donor-details?PaymentStatus=1&title=${"Confirmed Committment Users - Resources"}&StartDate=${
                    formRef.current.values.StartDate
                  }&EndDate=${formRef.current.values.EndDate}&Name=${
                    overallsummary && overallsummary.SummaryConfirmedNonCash
                      ? overallsummary.SummaryConfirmedNonCash
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards2 ">
                  <div className="dashboard-cards-r">
                    <h4>Confirmed Committment Users - Resources</h4>
                    <h5>
                      {overallsummary &&
                      overallsummary.SummaryConfirmedNonCashUsers
                        ? overallsummary.SummaryConfirmedNonCashUsers
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-12 col-12 "
            >
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-donotion-summary?PaymentStatus=0&title=${"Non Confrimed Committment - Resources"}&StartDate=${
                    formRef.current.values.StartDate
                  }&EndDate=${formRef.current.values.EndDate}&Name=${
                    overallsummary && overallsummary.SummaryNonConfirmedNonCash
                      ? overallsummary.SummaryNonConfirmedNonCash
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards3 ">
                  <div className="dashboard-cards-r">
                    <h4>Non Confrimed Committment - Resources</h4>
                    <h5>
                      {overallsummary &&
                      overallsummary.SummaryNonConfirmedNonCash
                        ? overallsummary.SummaryNonConfirmedNonCash
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-12 col-3 "
            >
              <Link
                to={
                  formRef &&
                  formRef.current &&
                  `/admin-campaign-donor-details?PaymentStatus=0&title=${"Non Confirmed Committment Users - Resources"}&StartDate=${
                    formRef.current.values.StartDate
                  }&EndDate=${formRef.current.values.EndDate}&Name=${
                    overallsummary && overallsummary.SummaryNonConfirmedNonCash
                      ? overallsummary.SummaryNonConfirmedNonCash
                      : "-"
                  }`
                }
              >
                <div className="dashboard-cards dashboard-cards4 ">
                  <div className="dashboard-cards-r">
                    <h4> Non Confrimed Committment Users - Resources</h4>
                    <h5>
                      {overallsummary &&
                      overallsummary.SummaryNonConfirmedNonCashUsers
                        ? overallsummary.SummaryNonConfirmedNonCashUsers
                        : "-"}
                    </h5>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            fontSize: 24,
            color: "red",
          }}
        >
          Access Deined
        </div>
      )}
    </div>
  );
};

export default withAPIRequest(AdminCampaignDashboard);
