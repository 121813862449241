import React from 'react';
import {
    // Route, 
    Switch
} from 'react-router-dom';

import Login from "./pages/common/login/Login.jsx";
import Home from './pages/common/home/Home';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import SuperadminCreateUser from './pages/superadmin/superadmin-create-user/SuperadminCreateUser';
import SuperAdminSettings from './pages/superadmin/superadmin-settings/SuperAdminSettings';
import SuperadminEditUser from './pages/superadmin/superadmin-edit-user/SuperadminEditUser';
import SuperadminUsers from './pages/superadmin/superadmin-users/SuperadminUsers';
import AdminCampaignDetails from './pages/admin/admin-campaign-details/AdminCampaignDetails';
import AdminCampaigns from './pages/admin/admin-campaigns/AdminCampaigns';
import AdminCreateCampaign from './pages/admin/admin-create-campaign/AdminCreateCampaign';
import AdminEditCampaign from './pages/admin/admin-edit-campaign/AdminEditCampaign';
import AdminCreateRequester from './pages/admin/admin-create-requester/AdminCreateRequester';
import AdminDashboard from './pages/admin/admin-dashboard/AdminDashboard';
import AdminDonorDetails from './pages/admin/admin-donor-details/AdminDonorDetails';
import AdminDonors from './pages/admin/admin-donors/AdminDonors';
import AdminRequesterDetails from './pages/admin/admin-requester-details/AdminRequesterDetails';
import AdminRequesters from './pages/admin/admin-requesters/AdminRequesters';
import AdminSettings from './pages/admin/admin-settings/AdminSettings';
import AdminCampaignDashboard from './pages/admin/admin-campaign-dashboard/AdminCampaignDashboard';
import AdminDonotionSummary from './pages/admin/admin-donotion-summary/AdminDonotionSummary';
import AdminCampaignDonorDetails from './pages/admin/admin-campaign-donor-details/AdminCampaignDonorDetails';
import RequesterDashboard from './pages/requester/requester-dashboard/RequesterDashboard'
import RequesterSettings from './pages/requester/requester-settings/RequesterSettings'
import RequesterCampaigns from './pages/requester/requester-campaigns/RequesterCampaigns'
import RequesterCampaignDetails from './pages/requester/requester-campaign-details/RequesterCampaignDetails'
import RequesterDonorSummary from './pages/requester/requester-donor-summary/RequesterDonorSummary'
import RequesterDonorDetails from './pages/requester/requester-donor-details/RequesterDonorDetails'
import RequesterPaymentOptions from './pages/requester/requester-payment-options/RequesterPaymentOptions'
import DonorRegister from './pages/donor/donor-register/DonorRegister';
import DonorRegisterConfirmed from './pages/donor/donor-register-confirmed/DonorRegisterConfirmed';
import DonorCampaignDetails from './pages/donor/donor-campaign-details/DonorCampaignDetails';
import DonationConfirmationCampaign from './pages/donor/donation-confirmations-campaign/DonationConfirmationCampaign';
import DonorCampaigns from './pages/donor/donor-campaigns/DonorCampaigns';
import DonorAllCampaings from './pages/donor/donor-all-campaigns/DonorAllCampaings'
import DonorSettings from './pages/donor/donor-settings/DonorSettings';
// import AboutUs from './pages/common/about-us/AboutUs';
import CampaignDetails from './pages/common/campaign-details/CampaignDetails';
import Campaigns from './pages/common/campaigns/Campaigns';
import ChangeEmail from './pages/common/change-email/ChangeEmail';
import ChangePassword from './pages/common/change-password/ChangePassword';
import Chat from './pages/common/chat/Chat';
import ForgotPassword from './pages/common/forgot-password/ForgotPassword';
import Notifications from './pages/common/notifications/Notifications';
import ResetPassword from './pages/common/reset-password/ResetPassword';
import AdminResources from './pages/admin/admin-resources/AdminResources.jsx';
import ChangePersonalDetails from './pages/common/change-personal-details/ChangePersonalDetails';
import Policies from './pages/common/policies/Policies';

// import { setLogout } from './utils';

const mainRoutes = [
    {
        path: '/',
        component: Home,
        requireAuth: false
    },
    {
        path: '/login',
        component: Login,
        requireAuth: false
    },
    {
        path: '/login/:token',
        component: Login,
        requireAuth: false
    },
    // {
    //     path: '/about-us',
    //     component: AboutUs,
    //     requireAuth: false
    // },
    {
        path: '/campaign-details/:id',
        component: CampaignDetails,
        requireAuth: false
    },
    {
        path: '/campaigns',
        component: Campaigns,
        requireAuth: false
    },
    {
        path: '/change-email',
        component: ChangeEmail,
        requireAuth: false
    },
    {
        path: '/change-password',
        component: ChangePassword,
        requireAuth: false
    },
    {
        path: '/chat',
        component: Chat,
        requireAuth: false
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        requireAuth: false
    },
    {
        path: '/notifications',
        component: Notifications,
        requireAuth: true
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        requireAuth: false
    },
    {
        path: '/requester-dashboard',
        component: RequesterDashboard,
        requireAuth: true
    },
    {
        path: '/requester-settings',
        component: RequesterSettings,
        requireAuth: true
    },
    {
        path: '/requester-campaigns',
        component: RequesterCampaigns,
        requireAuth: true
    },
    {
        path: '/requester-campaign-details/:id',
        component: RequesterCampaignDetails,
        requireAuth: true
    },
    {
        path: '/requester-donor-summary',
        component: RequesterDonorSummary,
        requireAuth: true
    },
    {
        path: '/requester-donor-details',
        component: RequesterDonorDetails,
        requireAuth: true
    },
    {
        path: '/requester-payment-options',
        component: RequesterPaymentOptions,
        requireAuth: true
    },
    {
        path: '/superadmin-users',
        component: SuperadminUsers,
        requireAuth: true
    },
    {
        path: '/superadmin-create-user',
        component: SuperadminCreateUser,
        requireAuth: true
    },
    {
        path: '/superadmin-settings',
        component: SuperAdminSettings,
        requireAuth: true
    },
    {
        path: '/superadmin-edit-user/:id',
        component: SuperadminEditUser,
        requireAuth: true
    },
    {
        path: '/admin-dashboard',
        component: AdminDashboard,
        requireAuth: true
    },
    {
        path: '/admin-campaigns',
        component: AdminCampaigns,
        requireAuth: true
    },
    {
        path: '/admin-campaign-details/id/:id',
        component: AdminCampaignDetails,
        requireAuth: true
    },
    {
        path: '/admin-create-campaign',
        component: AdminCreateCampaign,
        requireAuth: true
    },
    {
        path: '/admin-edit-campaign/id/:id',
        component: AdminEditCampaign,
        requireAuth: true
    },
    {
        path: '/admin-requesters',
        component: AdminRequesters,
        requireAuth: true
    },
    {
        path: '/admin-requester-details/id/:id',
        component: AdminRequesterDetails,
        requireAuth: true
    },
    {
        path: '/admin-create-requester',
        component: AdminCreateRequester,
        requireAuth: true
    },
    {
        path: '/admin-donors',
        component: AdminDonors,
        requireAuth: true
    },
    {
        path: '/admin-donor-details/:id',
        component: AdminDonorDetails,
        requireAuth: true
    },
    {
        path: '/admin-settings',
        component: AdminSettings,
        requireAuth: true
    },
    {
        path: '/admin-resources',
        component: AdminResources,
        requireAuth: true
    },
    {
        path: '/admin-campaign-dashboard',
        component: AdminCampaignDashboard,
        requireAuth: true
    },
    {
        path: '/admin-donotion-summary',
        component: AdminDonotionSummary,
        requireAuth: true
    },
    {
        path: '/admin-campaign-donor-details',
        component: AdminCampaignDonorDetails,
        requireAuth: true
    },
    {
        path: '/donor-register',
        component: DonorRegister,
        requireAuth: false
    },
    {
        path: '/donor-register-confirmed',
        component: DonorRegisterConfirmed,
        requireAuth: false
    },
    {
        path: '/donor-campaigns',
        component: DonorCampaigns,
        requireAuth: true
    },
    {
        path: '/donor-campaign-details/:id/:paymentStatus?',
        component: DonorCampaignDetails,
        requireAuth: true
    },
    {
        path: '/donation-confirmations-campaign/:id',
        component: DonationConfirmationCampaign,
        requireAuth: true
    },
    {
        path: '/donor-settings',
        component: DonorSettings,
        requireAuth: true
    },
    {
        path: '/donor-all-campaings',
        component: DonorAllCampaings,
        requireAuth: true
    },
    {
        path: '/change-personal-details',
        component: ChangePersonalDetails,
        requireAuth: true
    },
    {
        path: '/policies/:id',
        component: Policies,
        requireAuth: false
    },
]

const prepareRoutes = () => {
    let body = mainRoutes.map((route, index) => {
        if (route.requireAuth) {
            return <PrivateRoute key={index} exact path={route.path} component={route.component} />
        } else {
            return <PublicRoute key={index} exact path={route.path} component={route.component} />
        }
    })

    return body;
}

export function AppRoutes(props) {
    return <Switch>{prepareRoutes()}</Switch>
}