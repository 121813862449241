/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./RequesterDonorDetails.scss";
import Meta from "../../common/meta/Meta";

const RequesterDonorDetails = ( props ) => {

  return (
    <div className="container">
      <Meta title={ "Requester-Donor-Details" } content={ "Requester donor details" }/>
      <div className="row">
        <div className="col-12">
          <div className="form-div details-page-holder">
            <div className="d-flex justify-content-between">
              <h3 className="dash-heading mb-0"> Donor Details </h3>
            </div>
            <div className="row">
              <div className="col-12 col-lg-3 col-md-4 text-center">
                <div className="details-img-holder">
                  <div className="details-img">
                    <img
                      className="user"
                      src="assets/images/categories/main-home/2.jpg"
                      alt=""
                    />
                  </div>
                  <h4 className="name-title mt-4">John Doe</h4>
                </div>
              </div>
              <div className="col-12 col-lg-9 col-md-8 details-holder">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-0 d-flex justify-content-between flex-wrap">
                      <div className="d-flex align-items-center flex-wrap mb-3 mr-3 ">
                        <p className="mb-2 mr-4">
                          {" "}
                          <strong>Status</strong>{" "}
                        </p>
                        <span className="chips-box grn-bg"> Approved </span>
                      </div>
                      <div className="d-flex align-items-center flex-wrap  mb-3 mr-3">
                        <p className="mb-2 mr-4">
                          {" "}
                          <strong>Day left in Campaign</strong>{" "}
                        </p>
                        <h5 className="day-text mb-0">
                          <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                          30 Days
                        </h5>
                      </div>
                      <div className="d-flex align-items-center flex-wrap mb-3">
                        <p className="mb-2 mr-4">
                          {" "}
                          <strong> Share Your Campaign </strong>{" "}
                        </p>
                        <ul className="footer_social">
                          <li>
                            <a href="#" target="_blank">
                              <span>
                                <i className="fa fa-facebook"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="# " target="_blank">
                              <span>
                                <i className="fa fa-twitter"></i>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="# " target="_blank">
                              <span>
                                <i className="fa fa-instagram"></i>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p>
                      {" "}
                      Duis pretium gravida enim, vel maximus ligula fermentum a.
                      Sed rhoncus eget ex id egestas. Nam nec nisl placerat,
                      tempus erat a, condimentum metus. Curabitur nulla nisi,
                      lacinia at lobortis at, suscipit at nibh. Proin quis
                      lectus finibus, mollis purus vitae, rutrum neque.
                      Pellentesque habitant morbi tristique senectus et netus et
                      malesuada fames ac turpis egestas. Etiam sed cursus metus,
                      vel viverra mi. Mauris aliquet egestas eros ac placerat.
                      Proin condimentum ligula at diam euismod fringilla et quis
                      lacus.
                    </p>
                  </div>
                  <div className="col-12 col-md-12 col-lg-9 col-xl-9 mt-3">
                    <h4 className="mb-4"> List of resources needed </h4>
                    <div className="table-responsive">
                      <table className="table custom-table">
                        <thead>
                          <tr>
                            <th>Resource Name</th>
                            <th>Resource Details </th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tr>
                          <td>
                            {" "}
                            <p>Shirt</p>{" "}
                          </td>
                          <td>
                            <p>XL</p>
                          </td>
                          <td>
                            <div className="dropdown">
                              <button
                                className="btn chips-box mb-2 mr-3 mr-sm-5"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Open
                              </button>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <button className="dropdown-item">
                                  {" "}
                                  Closed
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p>Shirt</p>{" "}
                          </td>
                          <td>
                            <p>XL</p>
                          </td>
                          <td>
                            <div className="dropdown">
                              <button
                                className="btn chips-box mb-2 mr-3 mr-sm-5"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Closed
                              </button>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <button className="dropdown-item"> Open</button>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p>Shirt</p>{" "}
                          </td>
                          <td>
                            <p>XL</p>
                          </td>
                          <td>
                            <div className="dropdown">
                              <button
                                className="btn chips-box mb-2 mr-3 mr-sm-5"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Closed
                              </button>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <button className="dropdown-item"> Open</button>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p>Shirt</p>{" "}
                          </td>
                          <td>
                            <p>XL</p>
                          </td>
                          <td>
                            <div className="dropdown">
                              <button
                                className="btn chips-box mb-2 mr-3 mr-sm-5"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Closed
                              </button>

                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <button className="dropdown-item"> Open</button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h4 className="my-4">List of Campaigns</h4>
                <div className="client-table">
                  <div className="table-responsive">
                    <table className="table custom-table">
                      <thead>
                        <tr>
                          <th>Recipient Name</th>
                          <th>Last Action/Date</th>
                          <th>Campaign Name</th>
                          <th>Campaign Status</th>
                          <th>Ending date</th>
                          <th>Notes</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <p>Holly Brown</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>Cynde - 12/23/2020</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>Homeless</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>Needs Approval</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>14.10.2020</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>
                              {" "}
                              <img src="assets/images/note.svg" alt="" />
                            </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <a href="" className="btn table-sitebtn">
                              View More
                            </a>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p>Holly Brown</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>Cynde - 12/23/2020</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>Homeless</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>Needs Approval</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>14.10.2020</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p> </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <a href="" className="btn table-sitebtn">
                              View More
                            </a>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p>Holly Brown</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>Cynde - 12/23/2020</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>Homeless</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>Needs Approval</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>14.10.2020</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p> </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <a href="" className="btn table-sitebtn">
                              View More
                            </a>{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h4 className="my-4">List of Donations</h4>
                <div className="client-table">
                  <div className="table-responsive">
                    <table className="table custom-table">
                      <thead>
                        <tr>
                          <th>Donor Name</th>
                          <th>Campaign Name</th>
                          <th>Campaign Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <p> Jon Deo </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p> Jon Deo </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>14.10.2020</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <a
                              className="btn table-sitebtn"
                              href="donors-details.html"
                            >
                              View
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p> Jon Deo </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p> Jon Deo </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>14.10.2020</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <a
                              className="btn table-sitebtn"
                              href="donors-details.html"
                            >
                              View
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p> Jon Deo </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p> Jon Deo </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>14.10.2020</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <a
                              className="btn table-sitebtn"
                              href="donors-details.html"
                            >
                              View
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p> Jon Deo </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p> Jon Deo </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>14.10.2020</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <a
                              className="btn table-sitebtn"
                              href="donors-details.html"
                            >
                              View
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p> Jon Deo </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p> Jon Deo </p>{" "}
                          </td>
                          <td>
                            {" "}
                            <p>14.10.2020</p>{" "}
                          </td>
                          <td>
                            {" "}
                            <a
                              className="btn table-sitebtn"
                              href="donors-details.html"
                            >
                              View
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequesterDonorDetails;
