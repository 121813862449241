import React from "react";
import { Helmet } from "react-helmet";

const Meta = ( { content , title = "" } ) => {

    return (
        <div>
            <Helmet>
                <title>EHN | { title }</title>
                <meta name={ "description" } content={ content }/>
            </Helmet>
        </div>
    )
}

export default Meta;