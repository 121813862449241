/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import "./RequesterCampaigns.scss";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import {  sortByName } from "../../../configs/utility";
import Meta from "../../common/meta/Meta";

const RequesterCampaigns = (props) => {

  const queryParam = useLocation().search.split("=").pop();
  const globalContext = useContext(GlobalContext);
  const [status] = useState(queryParam);
  const [campaigns, setcampaigns] = useState([]);
  const history = useHistory();
  const myUserRole = globalContext.user.User.Role;

  useEffect(() => {
    if(myUserRole === 4){
      setcampaigns([]);
      let params = {
        RequesterId: globalContext.user.User.Id
      };
      getRequesterCampaigns(params);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [status]);

  const getRequesterCampaigns = async (params) => {
    const serviceParams = {
      api: API.CAMPAIGNS,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    await globalContext.setLoading(true);
    await props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result && result.Status.StatusId === 1) {
        setcampaigns(result.Data ? sortByName(result.Data,true) : []);
      }
    });
  };

  return (
    <div className="defult-home">
      <Meta title={ "Requester-Campaigns" } content={ "Recipient campaigns" }/>
      { myUserRole === 4 ? <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="form-div">
              <div className="d-flex justify-content-between mb-5">
                <h3 className="dash-heading mb-0">Recipient Campaigns</h3>
              </div>
              <div className="row" >
                <div className="col-12">
                  <div className="client-table">
                    <div className="table-responsive">
                      <table className="table custom-table">
                        <thead>
                          <tr>
                            <th>Campaign Name</th>
                            <th>Campaign Status</th>
                            <th>Resources Needed</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {campaigns ? (
                            campaigns.map((campaign, index) => (
                                <tr key={ index } className={ ` ${ campaign.IsFeatured && "table-active" }` }>
                                  <td>
                                    <div className="row" >
                                      <div className="col">
                                        <p> { campaign.Name }</p>
                                      </div>
                                      <div className="col">
                                        { campaign.IsFeatured && <label
                                            className={ `no-wrap feature-bg mr-6 }` }
                                        >
                                          { "Featured" }
                                        </label> }
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p >{ campaign.StatusName }</p>
                                  </td>
                                  <td>
                                    <div className="chip-holder">
                                      { campaign && campaign.Resources
                                          ? campaign.Resources.map(
                                              ( resource , index ) => (
                                                  <div
                                                      key={ index }
                                                      className="chip"
                                                      style={{cursor: 'default'}}
                                                  >
                                                    { resource.Name }
                                                  </div>
                                              )
                                          )
                                          : "N/A" }
                                    </div>
                                  </td>
                                  <td>
                                    <button
                                        className="btn table-sitebtn no-wrap"
                                        onClick={ () =>
                                            history.push(
                                                `/requester-campaign-details/${ campaign.Id }`
                                            )
                                        }
                                    >
                                      View Details
                                    </button>
                                  </td>
                                </tr>
                            ))
                          ) : (
                            <tr>
                              <td/>
                              <td>
                                <p>No Campaigns found</p>
                              </td>
                              <td/>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
    </div>
  );
};

export default withAPIRequest(RequesterCampaigns);
