/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Meta from "../../common/meta/Meta";
import "./AdminSettings.scss";
import { GlobalContext } from "../../../context/GlobalContext";


const AdminSettings = ( props ) => {
  const globalContext = useContext(GlobalContext);

  let history = useHistory();
  return (
    <div className="container">
      <Meta title={ "Admin-Settings" } content={ "Admin settings" }/>
      { globalContext.user.User.Role === 2 ?<div className="row">
        <div className="col-12">
          <div className="form-div ">
            <div className="mb-5">
              <h3 className="dash-heading mb-0">Admin Settings</h3>
            </div>
            <div className="row">
              <div className="col-12">
                <ul className="settings-list">
                  <li>
                    <div
                      className="hover"
                      onClick={() => history.push("/change-email")}
                    >
                      <div className="capabilities-div">
                        <div className="icon-div-holder">
                          <span className="icon-holder">
                            <i
                              className="fa fa-envelope-o"
                              aria-hidden="true"
                            />
                          </span>
                          <h4 className="head-h6">Change Email</h4>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div
                      className="hover"
                      onClick={() => history.push("/change-password")}
                    >
                      <div className="capabilities-div">
                        <div className="icon-div-holder">
                          <span className="icon-holder">
                            <i className="fa fa-lock" aria-hidden="true"/>
                          </span>
                          <h4 className="head-h6">Change Password</h4>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
    </div>
  );
}

export default AdminSettings;
