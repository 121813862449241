import React, { useState, useContext, useEffect } from "react";
import Uploader from "../../../components/fileUploader/FileUploader";
import './ChangePersonalDetails.scss';
import { GlobalContext } from "../../../context/GlobalContext";
import {
    API,
    AXIOS_METHOD_TYPES
} from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";

const ChangePersonalDetails = (props) => {
    const globalContext = useContext(GlobalContext);
    const [images, setImages] = useState([]);
    const [readyToLoadPage, setReadyToLoadPage] = useState(false);
    const isAdmin = globalContext.user.User.Role === 2;

    useEffect(() => {
        if (isAdmin && !props.location.state) {
            props.history.push('/login')
        } else {
            setReadyToLoadPage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (params) => {
        if (images.length) {
            uploadFile((fileUrl) => {
                if (fileUrl) {
                    updateProfilePic(fileUrl) // file url
                }
            })
        } else {
            globalContext.setNotifications({
                type: "warning",
                text: 'Please select a file'
            });
        }
    }

    const uploadFile = (callback) => {
        const formData = new FormData();
        images.forEach((file) => {
            formData.append(file.name, file);
        });

        let serviceParams = {
            api: API.FILE_UPLOAD,
            method: AXIOS_METHOD_TYPES.POST,
            params: formData,
        };

        globalContext.setLoading(true);
        props.commonAPIRequest(serviceParams, (result) => {
            if (result && result.Status.StatusId === 1) {
                callback(result.Data[0].FileUrl);
            } else {
                globalContext.setLoading(false);
                callback(null);
            }
        })
    }

    const updateProfilePic = (fileUrl) => {
        let serviceParams = {
            api: API.UPDATE_PROFILE_PIC,
            method: AXIOS_METHOD_TYPES.POST,
            params: {
                ProfilePicUrl: fileUrl
            },
        };

        if (isAdmin) {
            serviceParams.params.UserId = props.location.state.userId;
        }

        props.commonAPIRequest(serviceParams, (result) => {
            globalContext.setLoading(false);
            if (result && result.Status.StatusId === 1) {
                // Update local storage value for profile pic
                let _user = JSON.parse(JSON.stringify(globalContext.user));
                _user.User.ProfilePic = fileUrl;
                globalContext.setLoggedInUser(_user)

                globalContext.setNotifications({
                    type: "success",
                    text: result.Status.Message
                });
            }
        })
    }

    const getPreviewImage = () => {
        if (images.length) {
            return images[0].preview;
        } else {
            return isAdmin ? props.location.state.profilePic : globalContext.user.User.ProfilePic;
        }
    }

    return (<div className="container">
        {readyToLoadPage && <div className="row">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
                <div className="form-div ">
                    <div className="d-flex mb-5">
                        <h2 className="title mb-mb-15">Change Profile Image</h2>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12 mb-50 text-center">
                            <img src={getPreviewImage()} alt="profile" className="profilePreview" />
                        </div>

                        <div className="col-12 mb-20">
                            <Uploader className="drop"
                                setFiles={setImages}
                                placeholder={"Click here to select an image"}
                                type="image/jpeg, image/png"
                                icon={<i className="fa fa-upload mr-2" />}
                            />
                        </div>
                        <div className="col-12 col-lg-12 mb-20">
                            <button className="btn-send" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>}

    </div>);
}

export default withAPIRequest(ChangePersonalDetails);