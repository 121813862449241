import React, { Component } from "react";
import "./ProgressBar.scss";

const ProgressBar = (props) => {
    
    const { bgcolor,name,isCash,pendingNo,totalNo,index } = props;
  
    const widthPercentage= ()=> 100-((pendingNo/totalNo)*100);
    const colors = ["#2cba05","#50a04b","#010a72", "#2cba05", "#4169E1", "#FA8072", "#800080", "#2578af","#2c7f75","#262077","#000a1a"];
    // debugger
    const fillerStyles = {
        height: '20px',
        width: `${widthPercentage() }%`,
        minWidth:`13%`,
        backgroundColor: colors[index%10+1],
        borderRadius: 'inherit',
        textAlign: 'right'
      }
      const  progressBarLabelColor= {
        color:widthPercentage()? 'white':'white',
      }

    return (
    <div>
        <p className={"progress-name"}>{name}</p>
        <div className={"progress-bar-main-div"}>
          <div className={"progress-bar-container-styles"}>
           <div style={fillerStyles}>
            <span className={'progress-bar-labelStyles'} style={progressBarLabelColor}>{isCash?(`$`+(totalNo-pendingNo)):totalNo-pendingNo}</span>
           </div>
          </div>
          <p className={"progress-bar-total-name"}>{(isCash?"$":"")+totalNo}</p>
         </div>
    </div>
    );
  };
  
  export default ProgressBar;
