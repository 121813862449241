import React, { useContext } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";
import "./AdminCreateRequester.scss";

const AdminCreateRequester = ( props ) => {

  const globalContext = useContext(GlobalContext);
  let history = useHistory();

  const callRegisterRequesterAPI = (params) => {
    let serviceParams = {
      api: API.REGISTER_REQUESTER,
      method: AXIOS_METHOD_TYPES.POST,
      params: params,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      globalContext.setLoading(false);
      if (result) {
        if (result.Status.StatusId === 1) {
          history.goBack();
        } else {
          globalContext.setNotifications({
            id: Math.random(),
            type: "error",
            text: result.Status.Message,
          });
        }
      }
    });
  };

  return (
    <div className="container">
      <Meta
        title={"Admin-Create-Requester"}
        content={"Admin create requester"}
      />
      {globalContext.user.User.Role === 2 ? (
        <div className="row">
          <div className="col-12">
            <div className="form-div">
              <div className="d-flex mb-5">
                <h3 className="dash-heading mb-0">Add New Recipient</h3>
              </div>
              <Formik
                initialValues={{
                  FirstName: "",
                  LastName: "",
                  Email: "",
                  Phone: "",
                  UserName: "",
                  IsAnonymous: true,
                }}
                validationSchema={Yup.object().shape({
                  FirstName: Yup.string().required("Required"),
                  LastName: Yup.string().required("Required"),
                  Email: Yup.string().required("Required"),
                  Phone: Yup.string().required("Required"),
                  UserName: Yup.string(),
                  IsAnonymous: Yup.bool(),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  values["Phone"] = values["Phone"].toString();
                  callRegisterRequesterAPI(values);
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur,setFieldValue }) => (
                  <Form>
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-5 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          placeholder="First Name"
                          id="FirstName"
                          name="FirstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FirstName}
                          required=""
                        />
                        {errors.FirstName &&
                          touched.FirstName &&
                          errors.FirstName}
                      </div>
                      <div className="col-12 col-md-6 col-xl-5 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          placeholder="Last Name"
                          id="LastName"
                          name="LastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.LastName}
                          required=""
                        />
                        {errors.LastName && touched.LastName && errors.LastName}
                      </div>
                      <div className="col-12 col-md-6 col-xl-5 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          placeholder="User Name"
                          id="User Name"
                          name="User Name"
                          onChange={(e) => {
                            // debugger
                            setFieldValue(
                              "UserName",
                              e.currentTarget.value
                            );
                          }}
                          onBlur={handleBlur}
                          value={values.UserName}
                        />
                        <div className="input-group mt-2">
                          <label className="check-style">
                            Anonymous
                            <input
                              type="checkbox"
                              checked={values.IsAnonymous}
                              onChange={(e) => {
                                setFieldValue(
                                  "IsAnonymous",
                                  e.currentTarget.checked
                                );
                              }}
                              id="IsAnonymous"
                              name="IsAnonymous"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-5 mb-20">
                        <input
                          className="from-control"
                          type="email"
                          placeholder="Email"
                          id="Email"
                          name="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Email}
                          required=""
                        />
                        {errors.Email && touched.Email && errors.Email}
                      </div>
                      <div className="col-12 col-md-6 col-xl-5 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          placeholder="Phone number"
                          id="Phone"
                          name="Phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Phone}
                          required=""
                        />
                        {errors.Phone && touched.Phone && errors.Phone}
                      </div>
                      <div className="col-12 col-lg-12 mt-4 mb-20">
                        <input
                          className="btn-send max-w-200"
                          type="submit"
                          value="Submit"
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            fontSize: 24,
            color: "red",
          }}
        >
          Access Deined
        </div>
      )}
    </div>
  );
}

export default withAPIRequest( AdminCreateRequester );
