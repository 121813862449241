import React, { useContext, useEffect, useState } from "react";
import { API, AXIOS_METHOD_TYPES } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";
import "./RequesterPaymentOptions.scss";

const RequesterPaymentOptions = (props) => {

  const globalContext = useContext(GlobalContext);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [userPaymentOptions, setUserPaymentOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState("");
  const [selectedPaymentOptions, setSelectedPaymentOptions] = useState({
    PaymentPlatformId: null,
    PaymentUrl: "",
  });
  const userRole = globalContext.user.User.Role


  useEffect(() => {
    if(userRole === 4){
    getUserPaymentOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, []);

  const savePaymentOption = (paymentList, deletePaymentMessage) => {
    const paymentOptionList = [];
    let { PaymentPlatformId, PaymentUrl } = selectedPaymentOptions;
    PaymentUrl = PaymentUrl !== "" ? PaymentUrl : paymentUrl
    if (!paymentList) {
      if (
        paymentUrl === "" ||
        PaymentPlatformId === null ||
        PaymentUrl === ""
      ) {
        globalContext.setNotifications({
          type: "warning",
          text:
            paymentUrl === ""
              ? "Please Enter Payment Url"
              : "Please Select a Payment Option" &&
              PaymentPlatformId === null &&
              "Please Select a Payment Option",
        });
        return;
      }
    }
    userPaymentOptions.forEach(({ PaymentPlatformId, PaymentUrl }) => {
      paymentOptionList.push({
        PaymentPlatformId: PaymentPlatformId,
        PaymentUrl: PaymentUrl,
      });
    });
    selectedPaymentOptions.PaymentUrl = paymentUrl;
    paymentOptionList.push(selectedPaymentOptions);
    let serviceParams = {
      api: `${API.PAYMENT_OPTION_SAVE}`,
      method: AXIOS_METHOD_TYPES.POST,
      params: paymentList ? paymentList : paymentOptionList,
    };

    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      const {
        Status: { StatusId, Message },
      } = result;
      globalContext.setLoading(false);
      if (result && StatusId === 1) {
        setPaymentUrl("");
        getUserPaymentOptions();
        globalContext.setNotifications({
          type: "success",
          text: result && deletePaymentMessage ? deletePaymentMessage : Message,
        });
      } else {
        globalContext.setNotifications({
          type: "error",
          text: result ? Message : "Data not found",
        });
      }
    });
  };
  const getPaymentOptions = async (ids) => {
    let serviceParams = {
      api: `${API.PAYMENT_OPTIONS_TYPES}`,
      method: AXIOS_METHOD_TYPES.GET,
    };
    globalContext.setLoading(true);
    await props.commonAPIRequest(serviceParams, async (result) => {
      const {
        Data,
        Status: { StatusId, Message },
      } = result;
      globalContext.setLoading(false);
      if (Data && StatusId === 1) {
        const paymentOptionList = [];
        Data.forEach((item) => {
          if (ids.includes(item.PaymentPlatformId)) {
            item["isSelected"] = true;
            paymentOptionList.push(item);
          } else {
            item["isSelected"] = false;
            paymentOptionList.push(item);
          }
        });
        setPaymentOptions(paymentOptionList);
      } else {
        globalContext.setNotifications({
          type: "error",
          text: Data ? Message : "Data not found",
        });
      }
    });
  };
  const getUserPaymentOptions = () => {
    let serviceParams = {
      api: `${API.USER_PAYMENT_OPTIONS + 21}`,
      method: AXIOS_METHOD_TYPES.GET,
    };
    globalContext.setLoading(true);
    props.commonAPIRequest(serviceParams, (result) => {
      const {
        Data,
        Status: { StatusId, Message },
      } = result;
      globalContext.setLoading(false);
      if (result && StatusId === 1) {
        const paymentOptionList = [];
        Data.forEach((item) => {
          item.urlNewValue = item.PaymentUrl;
          paymentOptionList.push(item.PaymentPlatformId);
        });
        getPaymentOptions(paymentOptionList);
        setUserPaymentOptions(Data);
      } else {
        globalContext.setNotifications({
          type: "error",
          text: Data ? Message : "Data not found",
        });
      }
    });
  };
  const deletePaymentOptions = (id, isSelected) => {
    userPaymentOptions.forEach((item, index) => {
      if (item.PaymentPlatformId === id) {
        userPaymentOptions.splice(index, 1);
        savePaymentOption(
          userPaymentOptions,
          "Payment option deleted successfully"
        );
        setPaymentUrl("");
        setSelectedPaymentOptions({
          PaymentPlatformId: null,
          PaymentUrl: "",
        });
        return;
      }
    })
  };

  const onPaymentOptionSelect = (payment, PaymentPlatformName) => {
    setPlaceholder(`https://www.${PaymentPlatformName.replace(/\s+/g, '')}.com`.toLowerCase());
    setSelectedPaymentOptions(payment);
    setPaymentUrl(selectedPaymentOptions.PaymentUrl);
  };

  const findPaymentUrl = (id) => {
    const url = userPaymentOptions.filter((item) => {
      return id === item.PaymentPlatformId;
    })
    return url.length > 0 ? url[0].PaymentUrl : "";
  }

  const handleRowEdit = (id) => {
    let _userPaymentOptions = JSON.parse(JSON.stringify(userPaymentOptions));
    _userPaymentOptions.map((item, index) => {
      if (item.PaymentPlatformId === id) {
        item.isEditable = true;
      } else {
        item.isEditable = false;
        item.urlNewValue = _userPaymentOptions[index].PaymentUrl;
      }
      return null;
    });
    setUserPaymentOptions(_userPaymentOptions);
  }

  const handleUrlInputChange = (id, newValue) => {
    let _userPaymentOptions = JSON.parse(JSON.stringify(userPaymentOptions));
    _userPaymentOptions.map((item) => {
      if (item.PaymentPlatformId === id) {
        item.urlNewValue = newValue;
      }

      return null;
    });
    setUserPaymentOptions(_userPaymentOptions);
  }

  const handleRowUrlSave = (index) => {
    let newUrl = userPaymentOptions[index].urlNewValue;

    if (newUrl && /\S+/.test(newUrl)) {
      let paymentOptionList = [];
      userPaymentOptions.forEach(({ PaymentPlatformId, PaymentUrl }) => {
        let _url = userPaymentOptions[index].PaymentPlatformId === PaymentPlatformId ? newUrl : PaymentUrl;
        paymentOptionList.push({
          PaymentPlatformId: PaymentPlatformId,
          PaymentUrl: _url,
        });
      });

      let serviceParams = {
        api: `${API.PAYMENT_OPTION_SAVE}`,
        method: AXIOS_METHOD_TYPES.POST,
        params: paymentOptionList,
      };

      globalContext.setLoading(true);
      props.commonAPIRequest(serviceParams, (result) => {
        globalContext.setLoading(false);
        if (result && result.Status.StatusId === 1) {
          setPaymentUrl("");
          getUserPaymentOptions();
          globalContext.setNotifications({
            type: "success",
            text: result.Status.Message,
          });
        }
      });
    }
    else {
      globalContext.setNotifications({
        type: "error",
        text: 'Please enter a URL'
      });
    }

  }

  const handleRowCloseEdit = (index) => {
    let _userPaymentOptions = JSON.parse(JSON.stringify(userPaymentOptions));
    _userPaymentOptions[index].isEditable = false;
    _userPaymentOptions[index].urlNewValue = _userPaymentOptions[index].PaymentUrl;
    setUserPaymentOptions(_userPaymentOptions);
  }

  return (
    globalContext.user.User.Role === 4 ? paymentOptions && (
      <div className="defult-home">
        <Meta title={"Requester-Payment-Options"} content={"Requester payment options"} />
        {userRole === 4 ?<div className="container requestor-payment-option">
          <div className="row">
            <div className="col-12">
              <div className="form-div">
                <div className="mb-5">
                  <h3 className="dash-heading mb-0">Payment Options </h3>
                  <p>Choose payment method below</p>
                </div>
                <div className="row">
                  <div className="col-12">
                    <ul className="payment-list">
                      {paymentOptions.map(
                        ({
                          id,
                          isSelected,
                          PaymentPlatformId,
                          PaymentPlatformLogo,
                          PaymentPlatformName,
                        }, index) => (
                          <li
                            key={index}
                            onClick={() =>
                              onPaymentOptionSelect({
                                PaymentPlatformId: PaymentPlatformId,
                                PaymentUrl: isSelected ? `${isSelected ? findPaymentUrl(PaymentPlatformId) : PaymentPlatformLogo}` : "",
                              }, PaymentPlatformName)
                            }
                          >
                            <label className="check-style">
                              <div>
                                <div>
                                  <img
                                    className="normal-logo"
                                    src={PaymentPlatformLogo}
                                    alt={PaymentPlatformName.trim()}
                                  />
                                  <input
                                    type={"radio"}
                                    name="pay-check"
                                  />
                                  <span id={"checkMarkId"} className="checkmark"></span>
                                </div>

                              </div>
                            </label>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="row mt-4 login-form mx-800 url-input">
                  <div className={"col"}>
                  <h6 className={"text-muted"}>{`Enter the URL, email or identifier for the selected platform.`}</h6>
                    <input
                      onChange={(e) => { setPaymentUrl(e.target.value) }}
                      className="from-control"
                      type="text"
                      value={paymentUrl}
                      id="Name"
                      name="Name"
                      placeholder="Enter here"
                      required=""
                      style={{borderWidth:1, borderColor:'#f18d1f'}}
                    />
                  </div>
                  <div className=" col-md-3 col-sm-3 col-lg-3 col-xl-3 mt-5 ml-15" style={{float:'right',marginBottom:30}}>
                  <div className="form-group">
                    <button
                      className={"btn btn-send save-btn"}
                      onClick={() => savePaymentOption()}
                    >
                      Save
                        </button>
                  </div>
                </div>
                  
                </div>
                <div className="ml-15">
                  {userPaymentOptions.length !== 0 && (
                    <div className="col-12 mb-4 payment-table">
                      <div className="d-flex justify-content-between mb-4 ">
                        <h4 className="mb-0">Payment</h4>
                      </div>
                      <div className="client-table">
                        <div className="table-responsive">
                          <table className="table custom-table">
                            <thead>
                              <tr>
                                <th>Platform Name</th>
                                <th>URL</th>
                                <th>Edit</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userPaymentOptions.map(
                                (item, index) => (
                                  <tr key={item.PaymentPlatformId}>
                                    <td>
                                      <p>{item.PaymentPlatformName}</p>
                                    </td>
                                    <td>
                                      {item.isEditable === true ? <div class='d-flex align-items-center'>
                                        <input
                                          onChange={(e) => { handleUrlInputChange(item.PaymentPlatformId, e.target.value) }}
                                          className="from-control rowEditUrlInput"
                                          type="text"
                                          value={item.urlNewValue}
                                          name="Name"
                                          placeholder="Enter here"
                                          required=""
                                          style={{borderWidth:1, borderColor:'#f18d1f'}}
                                        />
                                        <i onClick={() => { handleRowUrlSave(index) }} className="fa fa-check" />
                                        <i onClick={() => { handleRowCloseEdit(index) }} className="fa fa-close" />
                                      </div> : <p>{item.PaymentUrl}</p>}

                                    </td>
                                    <td >
                                      <i onClick={() => { handleRowEdit(item.PaymentPlatformId) }} className="fa fa-pencil pl-3 cursor" />
                                    </td>
                                    <td >
                                      <i onClick={() => { deletePaymentOptions(item.PaymentPlatformId, false) }} className="fa fa-trash pl-3 cursor" />
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>:<div style={{width:'100%',alignItems:'center',textAlign:'center',fontSize:24,color:'red'}}>Access Deined</div>}
      </div>
    ) : <div><div className="center"><h1>Access Denied</h1>
      <h2>You do not have required permission to see this page.</h2></div></div>
  );
}

export default withAPIRequest(RequesterPaymentOptions);