import React , { useContext , useEffect , useState } from "react";
import { Link , useHistory } from "react-router-dom";
import { API , AXIOS_METHOD_TYPES , USER_ROLE } from "../../../configs/constants";
import { withAPIRequest } from "../../../components/HOC/withAPIRequest";
import { GlobalContext } from "../../../context/GlobalContext";
import Meta from "../../common/meta/Meta";
import "./SuperadminUsers.scss";

const SuperadminUsers = ( props ) => {

    const globalContext = useContext( GlobalContext );
    let history = useHistory();
    const [ users , setUsers ] = useState( [] );
    const getUsers = ( userRole ) => {
        let serviceParams = {
            api : API.USERS_LIST + userRole ,
            method : AXIOS_METHOD_TYPES.GET ,
        };
        globalContext.setLoading( true );
        props.commonAPIRequest( serviceParams , ( result ) => {
            globalContext.setLoading( false );
            if ( result ) {
                if ( result.Status.StatusId === 1 ) {
                    setUsers( result.Data );
                } else {
                    globalContext.setNotifications( {
                        id : Math.random() ,
                        type : "error" ,
                        text : result.Status.Message ,
                    } );
                }
            }
        } );
    }

    useEffect( () => {
        ( async () => {
            await getUsers( 2 );
        } )();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [] );
    return (
        <div className="container">
            <Meta title={ "Super-Admin-Users" } content={ "Super admin users" }/>
            <div className="row">
                <div className="col-12">
                    <div className="form-div">
                        <div className="d-flex justify-content-between mb-5 ">
                            <h3 className="dash-heading my-4">List of Users</h3>
                            <div className="row d-flex align-items-center mr-3" style={ { alignSelf : 'center' } }>
                                <div className="col-sm-12 col-md-8 col-lg-8" style={ { fontSize : 12 } }>
                                    <select
                                        className="from-control max-w-150 mr-3"
                                        onChange={ ( e ) => {
                                            e.preventDefault();
                                            getUsers( e.target.value );
                                        } }
                                    >
                                        { USER_ROLE.map( ( item , index ) => (
                                            <option value={ item.id } key={ index }>
                                                { item.text }
                                            </option>
                                        ) ) }
                                    </select>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <Link to={ "/superadmin-create-user" }
                                          className="btn site-btn text-nowrap sm-mt-4 text-center"
                                    >
                                        Add New
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="client-table">
                                    <div className="table-responsive">
                                        <table className="table custom-table">
                                            <thead>
                                            <tr>
                                                <th>User Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            { users.map( ( {
                                                FirstName ,
                                                LastName ,
                                                Email ,
                                                RoleName ,
                                                StatusName ,
                                                Phone ,
                                                Id
                                            } , index ) => (
                                                <tr key={ index }>
                                                    <td>
                                                        <p>{ `${ FirstName } ${ LastName }` }</p>
                                                    </td>
                                                    <td>
                                                        <p>{ Email }</p>
                                                    </td>
                                                    <td>
                                                        <p>{ Phone || "N/A" }</p>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className="btn table-sitebtn btn-lg text-nowrap"
                                                            onClick={ () =>
                                                                history.push(
                                                                    `/superadmin-edit-user/${ Id }`
                                                                )
                                                            }
                                                        >
                                                            View Details
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) ) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withAPIRequest( SuperadminUsers );
